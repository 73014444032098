import React from 'react';
import styles from './subpage.module.scss';

const SubpageContents = ({ children, bgColor, width }) => {
  const bgStyle = {
    backgroundColor: bgColor,
  };
  const widthStyle = {
    maxWidth: width === 'narrow' ? '817px' : '1240px',
  };
  return (
    <div className={styles.subpage_contents} style={bgStyle}>
      <div className={styles.subpage_contents__inner} style={widthStyle}>{children}</div>
    </div>
  );
};

export default SubpageContents;
