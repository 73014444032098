import React, { useState } from 'react';
import styles from './contact.module.scss';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';

const Contact = () => {
  return (
    <>
      <SubpageMainvisual title="お問い合わせ" enTitle="Contact" />
      <SubpageContents bgColor={'#fdfaf1'}>
        <div className={styles.contact}>
          <p className={styles.contact__lead}>
            ご訪問いただき、誠にありがとうございます。
            <br />
            何かご不明点、ご質問、または提案がございましたら、下記のフォームにてお知らせください。
          </p>
          <form className={styles.contact__form}>
            <div className={styles.contact__contents}>
              <p className={styles.contact__note}>※は必須項目です</p>
              <div className={styles.contact__item}>
                <label className={styles.contact__label} htmlFor="organization">
                  組織・団体
                </label>
                <input className={styles.contact__input} type="text" id="organization" name="organization" placeholder="〇〇県　〇〇市" />
              </div>
              <div className={styles.contact__item}>
                <label className={styles.contact__label} htmlFor="department">
                  部署名
                </label>
                <input className={styles.contact__input} type="text" id="department" name="department" placeholder="〇〇部" />
              </div>
              <div className={`${styles.contact__item} ${styles['is-error']}`}>
                <label className={styles.contact__label} htmlFor="full_name">
                  氏名<span>※</span>
                </label>
                <input className={styles.contact__input} type="text" id="full_name" name="full_name" placeholder="山田 太郎" />
              </div>
              <div className={styles.contact__item}>
                <label className={styles.contact__label} htmlFor="phone_number">
                  電話番号<span>※</span>
                </label>
                <input className={styles.contact__input} type="text" id="phone_number" name="phone_number" placeholder="000-1234-5678" />
              </div>
              <div className={styles.contact__item}>
                <label className={styles.contact__label} htmlFor="email">
                  メールアドレス<span>※</span>
                </label>
                <input className={styles.contact__input} type="text" id="email" name="email" placeholder="abc@sample.com" />
              </div>
              <div className={styles.contact__item}>
                <label className={styles.contact__label} htmlFor="email_confirm">
                  メールアドレス確認<span>※</span>
                </label>
                <input className={styles.contact__input} type="text" id="email_confirm" name="email_confirm" placeholder="abc@sample.com" />
              </div>
              <div className={styles.contact__item}>
                <label className={styles.contact__label} htmlFor="inquiry_category">
                  問合せカテゴリー<span>※</span>
                </label>
                <div className={`${styles.contact__input} ${styles['contact__input--select']}`}>
                  <select name="inquiry_category" autocomplete="shipping address-level1">
                    <option value="" disabled selected>
                      選択してください
                    </option>
                    <option value="北海道">北海道</option>
                    <option value="東京">東京</option>
                    <option value="京都">京都</option>
                    <option value="沖縄">沖縄</option>
                  </select>
                </div>
              </div>
              <div className={styles.contact__item}>
                <label className={styles.contact__label} htmlFor="subject">
                  件名<span>※</span>
                </label>
                <input className={styles.contact__input} placeholder="市区町村" type="text" name="subject" autocomplete="選択してください" />
              </div>

              <div className={styles.contact__item}>
                <label className={styles.contact__label} htmlFor="inquiry_details">
                  お問い合わせ内容<span>※</span>
                </label>
                <textarea className={`${styles.contact__input} ${styles['contact__input--textarea']}`} placeholder="入力してください" type="text" name="inquiry_details" />
              </div>
            </div>
            <input className={styles.contact__submit} type="submit" value="確認画面へ" />
          </form>
        </div>
      </SubpageContents>
    </>
  );
};

export default Contact;
