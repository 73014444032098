import React from 'react';
import styles from './footer.module.scss';
import AppContext from '../../context/AppContext';

const Footer = () => {
  const { isLogin } = React.useContext(AppContext);
  return (
    <div className={styles.footer}>
      <div className={`${styles.footer__inner} ${styles['is-pc']}`}>
        <div className={styles.footer_left}>
          <div className={styles.footer_left__logo}>
            <a href="/">
              <img src="/assets/img/common/logo02.png" alt="ロゴ" />
            </a>
          </div>
          <div className={styles.footer_left__sns}>
            <a className={styles.footer_left__item} href="#" target="_blank" rel="noopener noreferrer">
              <img src="/assets/img/common/ico_line.svg" alt="Lineアイコン" />
            </a>
            <a className={styles.footer_left__item} href="#" target="_blank" rel="noopener noreferrer">
              <img src="/assets/img/common/ico_threads.svg" alt="Threadsアイコン" />
            </a>
            <a className={styles.footer_left__item} href="#" target="_blank" rel="noopener noreferrer">
              <img src="/assets/img/common/ico_instagram.svg" alt="Instagramアイコン" />
            </a>
            <a className={styles.footer_left__item} href="#" target="_blank" rel="noopener noreferrer">
              <img src="/assets/img/common/ico_facebook.svg" alt="Facebookアイコン" />
            </a>
            <a className={styles.footer_left__item} href="#" target="_blank" rel="noopener noreferrer">
              <img src="/assets/img/common/ico_x.png" alt="Twitterアイコン" />
            </a>
          </div>
          <div className={styles.footer_left__copyright}>&copy;2023 ODK Solutions Company, Ltd.</div>
        </div>
        <div className={styles.footer_right}>
          <ul className={styles.footer_right__menu}>
            <li className={styles.footer_right__item}>
              <a href="#">ホーム</a>
            </li>
            <li className={styles.footer_right__item}>
              <a href="#">はじめての方へ</a>
            </li>
            <li className={styles.footer_right__item}>
              <a href="#">イベント情報</a>
            </li>
            <li className={styles.footer_right__item}>
              <a href="#">体験記事を探す</a>
            </li>
            <li className={styles.footer_right__item}>
              <a href="#">まちの魅力発見</a>
            </li>
          </ul>
          {isLogin ? null : (
            <div className={styles.footer_right__buttons}>
              <a href="#" className={`${styles.footer_right__button} ${styles['footer_right__button--login']}`}>
                ログイン
              </a>
              <a href="#" className={`${styles.footer_right__button} ${styles['footer_right__button--register']}`}>
                新規登録
              </a>
            </div>
          )}
          <ul className={styles.footer_right__links}>
            <li className={styles.footer_right__link}>
              <a href="#">利用規約</a>
            </li>
            <li className={styles.footer_right__link}>
              <a href="#">プライバシーポリシー</a>
            </li>
            <li className={styles.footer_right__link}>
              <a href="#">Cookieポリシー</a>
            </li>
            <li className={styles.footer_right__link}>
              <a href="#">運営会社</a>
            </li>
            <li className={styles.footer_right__link}>
              <a href="#">お問い合わせ</a>
            </li>
          </ul>
        </div>
      </div>
      <div className={`${styles.footer__inner} ${styles['is-sp']}`}>
        <div className={styles.footer_left__logo}>
          <a href="/">
            <img src="/assets/img/common/logo02.png" alt="ロゴ" />
          </a>
        </div>
        {isLogin ? null : (
          <div className={styles.footer_right__buttons}>
            <a href="#" className={`${styles.footer_right__button} ${styles['footer_right__button--login']}`}>
              ログイン
            </a>
            <a href="#" className={`${styles.footer_right__button} ${styles['footer_right__button--register']}`}>
              新規登録
            </a>
          </div>
        )}

        <ul className={styles.footer_right__menu}>
          <li className={styles.footer_right__item}>
            <a href="#">ホーム</a>
          </li>
          <li className={styles.footer_right__item}>
            <a href="#">はじめての方へ</a>
          </li>
          <li className={styles.footer_right__item}>
            <a href="#">イベント情報</a>
          </li>
          <li className={styles.footer_right__item}>
            <a href="#">体験記事を探す</a>
          </li>
          <li className={styles.footer_right__item}>
            <a href="#">まちの魅力発見</a>
          </li>
        </ul>
        <div className={styles.footer_left__sns}>
          <a className={styles.footer_left__item} href="#" target="_blank" rel="noopener noreferrer">
            <img src="/assets/img/common/ico_line.svg" alt="Lineアイコン" />
          </a>
          <a className={styles.footer_left__item} href="#" target="_blank" rel="noopener noreferrer">
            <img src="/assets/img/common/ico_threads.svg" alt="Threadsアイコン" />
          </a>
          <a className={styles.footer_left__item} href="#" target="_blank" rel="noopener noreferrer">
            <img src="/assets/img/common/ico_instagram.svg" alt="Instagramアイコン" />
          </a>
          <a className={styles.footer_left__item} href="#" target="_blank" rel="noopener noreferrer">
            <img src="/assets/img/common/ico_facebook.svg" alt="Facebookアイコン" />
          </a>
          <a className={styles.footer_left__item} href="#" target="_blank" rel="noopener noreferrer">
            <img src="/assets/img/common/ico_x.png" alt="Twitterアイコン" />
          </a>
        </div>
        <ul className={styles.footer_right__links}>
          <li className={styles.footer_right__link}>
            <a href="#">利用規約</a>
          </li>
          <li className={styles.footer_right__link}>
            <a href="#">プライバシーポリシー</a>
          </li>
          <li className={styles.footer_right__link}>
            <a href="#">Cookieポリシー</a>
          </li>
          <li className={styles.footer_right__link}>
            <a href="#">運営会社</a>
          </li>
          <li className={styles.footer_right__link}>
            <a href="#">お問い合わせ</a>
          </li>
        </ul>
        <div className={styles.footer_left__copyright}>&copy;2023 ODK Solutions Company, Ltd.</div>
      </div>
    </div>
  );
};

export default Footer;
