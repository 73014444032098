import styles from './sidemenu.module.scss';

export function Sidemenu() {
  return (
    <div className={styles.sidemenu_block}>
      <a href="/admin/" className={styles.sidemenu_block__logo}>
        <picture>
          <source media="(max-width: 769px)" srcset="/assets/img/common/logo_subpage.png" />
          <img src="/assets/img/common/logo_subpage.png" alt="" />
        </picture>
      </a>
      <ul className={styles.sidemenu_block__list}>
        <li className={styles.sidemenu_block__item}>
          <a href="/admin/organization">
            <i class="bi bi-building"></i>組織・団体管理
          </a>
        </li>

        <li className={styles.sidemenu_block__item}>
          <a href="/admin/user">
            <i class="bi bi-person-fill"></i>ユーザー管理
          </a>
        </li>

        <li className={styles.sidemenu_block__item}>
          <a href="/admin/passport">
            <i class="bi bi-card-heading"></i>ふるさとパスポート
          </a>
        </li>

        <li className={styles.sidemenu_block__item}>
          <a href="/admin/event">
            <i class="bi bi-flag"></i>イベント管理
          </a>
        </li>

        <li className={styles.sidemenu_block__item}>
          <a href="/admin/experience">
            <i class="bi bi-geo-alt"></i>イベント体験記事管理
          </a>
        </li>

        <li className={styles.sidemenu_block__item}>
          <a href="/admin/attractive/01">
            <i class="bi bi-bookmark-star"></i>魅力発見記事管理
          </a>
        </li>

        <li className={styles.sidemenu_block__item}>
          <a href="/admin/news">
            <i class="bi bi-bell"></i>お知らせ管理
          </a>
        </li>

        <li className={styles.sidemenu_block__item}>
          <a href="/admin/banner/1">
            <i class="bi bi-images"></i>広告バナー管理
          </a>
        </li>
        <li className={styles.sidemenu_block__item}>
          <a href="/admin/">
            <i class="bi bi-box-arrow-left"></i>ログアウト
          </a>
        </li>
      </ul>
    </div>
  );
}
