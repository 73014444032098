import React, { useState } from 'react';
import styles from './sign_up.module.scss';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';

const ApplicationSignUp = () => {
  return (
    <>
      <SubpageMainvisual title="イベント申し込み" enTitle="Application" />
      <SubpageContents bgColor={'#fdfaf1'}>
        <div className={styles.sign_up}>
          <form className={styles.sign_up__form} action="" method="">
            <div className={styles.sign_up_contents}>
              <div className={styles.sign_up_contents__inner}>
                <p className={styles.sign_up_contents__note}>※は必須項目です</p>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-sm']} ${styles['is-error']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="name">
                    名前<span>※</span>
                  </label>
                  <input className={styles.sign_up_contents__input} type="text" id="name" name="name" placeholder="山田　太郎" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-sm']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="furigana">
                    ふりがな<span>※</span>
                  </label>
                  <input className={styles.sign_up_contents__input} type="text" id="furigana" name="furigana" placeholder="やまだ　たろう" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-sm']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="nickname">
                    ニックネーム<span>※</span>
                  </label>
                  <input className={styles.sign_up_contents__input} type="text" id="nickname" name="nickname" placeholder="ローカルブリッジ" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-sm']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="email">
                    メールアドレス<span>※</span>
                  </label>
                  <input className={styles.sign_up_contents__input} type="text" id="email" name="email" placeholder="abc@sample.com" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-sm']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="email_confirm">
                    メールアドレス確認<span>※</span>
                  </label>
                  <input className={styles.sign_up_contents__input} type="text" id="email_confirm" name="email_confirm" placeholder="abc@sample.com" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-sm']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="phone">
                    電話番号<span>※</span>
                  </label>
                  <input className={styles.sign_up_contents__input} type="text" id="phone" name="phone" placeholder="000-1234-5678" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-xs']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="postal_code">
                    郵便番号
                  </label>
                  <input className={styles.sign_up_contents__input} type="text" name="postal_code" minlength="7" maxlength="8" pattern="\d*" autocomplete="shipping postal-code" placeholder="123-4567" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-xs']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="prefecture">
                    都道府県
                  </label>
                  <div className={`${styles.sign_up_contents__input} ${styles['sign_up_contents__input--select']}`}>
                    <select name="prefecture" autocomplete="shipping address-level1">
                      <option value="" disabled selected>
                        選択してください
                      </option>
                      <option value="北海道">北海道</option>
                      <option value="東京">東京</option>
                      <option value="京都">京都</option>
                      <option value="沖縄">沖縄</option>
                    </select>
                  </div>
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-md']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="city">
                    市区町村
                  </label>
                  <input className={styles.sign_up_contents__input} placeholder="市区町村" type="text" name="city" autocomplete="shipping address-level2" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-md']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="address">
                    番地
                  </label>
                  <input className={styles.sign_up_contents__input} placeholder="1-2-3" type="text" name="address" autocomplete="shipping address-line1" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-md']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="building">
                    建物名・部屋番号
                  </label>
                  <input className={styles.sign_up_contents__input} placeholder="JMFビル北浜 01" type="text" name="building" autocomplete="shipping address-line2" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-sm']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="birthdate">
                    生年月日
                  </label>
                  <input className={styles.sign_up_contents__input} placeholder="0000/00/00" type="text" name="birthdate" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-sm']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="gender">
                    性別<span>※</span>
                  </label>
                  <div className={styles.sign_up_radio}>
                    <div className={styles.sign_up_radio__inner}>
                      <input className={styles.sign_up_radio__btn} type="radio" id="huey" name="drone" value="huey" />
                      <label className={styles.sign_up_radio__text} for="huey">
                        男性
                      </label>
                    </div>
                    <div className={styles.sign_up_radio__inner}>
                      <input className={styles.sign_up_radio__btn} type="radio" id="dewey" name="drone" value="dewey" />
                      <label className={styles.sign_up_radio__text} for="dewey">
                        女性
                      </label>
                    </div>
                    <div className={styles.sign_up_radio__inner}>
                      <input className={styles.sign_up_radio__btn} type="radio" id="louie" name="drone" value="louie" />
                      <label className={styles.sign_up_radio__text} for="louie">
                        どちらでもない
                      </label>
                    </div>
                  </div>
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-md']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="occupation">
                    職業<span>※</span>
                  </label>
                  <div className={`${styles.sign_up_contents__input} ${styles['sign_up_contents__input--select']}`}>
                    <select name="occupation" autocomplete="shipping address-level1">
                      <option value="" disabled selected>
                        選択してください
                      </option>
                      <option value="test01">test01</option>
                      <option value="test02">test02</option>
                      <option value="test03">test03</option>
                      <option value="test04">test04</option>
                    </select>
                  </div>
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-sm']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="school_name">
                    学校名
                  </label>
                  <input className={styles.sign_up_contents__input} placeholder="〇〇大学" type="text" name="school_name" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-sm']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="department">
                    学部名
                  </label>
                  <input className={styles.sign_up_contents__input} placeholder="〇〇学部" type="text" name="department" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-sm']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="grade">
                    学年
                  </label>
                  <input className={styles.sign_up_contents__input} placeholder="1学年" type="text" name="grade" />
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-md']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="additional_info">
                    その他、事務局への連絡事項
                  </label>
                  <textarea className={`${styles.sign_up_contents__input} ${styles['sign_up__input--textarea']}`} placeholder="入力してください" type="text" name="additional_info" />
                </div>
                <p className={styles.sign_up_contents__title}>アンケート</p>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-lg']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="how_did_you_know">
                    このイベントを何で知りましたか？<span>※</span>
                  </label>
                  <div className={`${styles.sign_up_contents__input} ${styles['sign_up_contents__input--select']}`}>
                    <select name="how_did_you_know" autocomplete="shipping address-level1">
                      <option value="" disabled selected>
                        選択してください
                      </option>
                      <option value="test01">test01</option>
                      <option value="test02">test02</option>
                      <option value="test03">test03</option>
                      <option value="test04">test04</option>
                    </select>
                  </div>
                </div>
                <div className={`${styles.sign_up_contents__item} ${styles['sign_up__item--width-lg']}`}>
                  <label className={styles.sign_up_contents__label} htmlFor="grade">
                    このイベントに参加しようと思った動機を教えてください。（複数選択可）<span>※</span>
                  </label>
                  <div className={styles.sign_up_checkbox}>
                    <div className={styles.sign_up_checkbox__item}>
                      <input className={styles.sign_up_checkbox__btn} id="choice01" type="checkbox" />
                      <label className={styles.sign_up_checkbox__text} for="choice01">
                        イベント内容に興味があった
                      </label>
                    </div>
                    <div className={styles.sign_up_checkbox__item}>
                      <input className={styles.sign_up_checkbox__btn} id="choice02" type="checkbox" />
                      <label className={styles.sign_up_checkbox__text} for="choice02">
                        既にイベント開催地にゆかりがあるため
                      </label>
                    </div>
                    <div className={styles.sign_up_checkbox__item}>
                      <input className={styles.sign_up_checkbox__btn} id="choice03" type="checkbox" />
                      <label className={styles.sign_up_checkbox__text} for="choice03">
                        イベント開催地の地域活性化に興味があった
                      </label>
                    </div>
                    <div className={styles.sign_up_checkbox__item}>
                      <input className={styles.sign_up_checkbox__btn} id="choice04" type="checkbox" />
                      <label className={styles.sign_up_checkbox__text} for="choice04">
                        地方創生・地域活性化の活動に参加したいと思った
                      </label>
                    </div>
                    <div className={styles.sign_up_checkbox__item}>
                      <input className={styles.sign_up_checkbox__btn} id="choice05" type="checkbox" />
                      <label className={styles.sign_up_checkbox__text} for="choice05">
                        イベント体験記事を読んで自分も参加したいと思った
                      </label>
                    </div>
                    <div className={styles.sign_up_checkbox__item}>
                      <input className={styles.sign_up_checkbox__btn} id="choice06" type="checkbox" />
                      <label className={styles.sign_up_checkbox__text} for="choice06">
                        魅力発見記事を読んでイベント開催地に興味を持った
                      </label>
                    </div>
                    <div className={styles.sign_up_checkbox__item}>
                      <input className={styles.sign_up_checkbox__btn} id="choice07" type="checkbox" />
                      <label className={styles.sign_up_checkbox__text} for="choice07">
                        友人・仲間を作りたいと思った
                      </label>
                    </div>
                    <div className={styles.sign_up_checkbox__item}>
                      <input className={styles.sign_up_checkbox__btn} id="choice08" type="checkbox" />
                      <label className={styles.sign_up_checkbox__text} for="choice08">
                        知人・知り合いの勧め
                      </label>
                    </div>
                    <div className={styles.sign_up_checkbox__item}>
                      <input className={styles.sign_up_checkbox__btn} id="choice09" type="checkbox" />
                      <label className={styles.sign_up_checkbox__text} for="choice09">
                        就職・転職に活かせると思った
                      </label>
                    </div>
                    <div className={styles.sign_up_checkbox__item}>
                      <input className={styles.sign_up_checkbox__btn} id="choice10" type="checkbox" />
                      <label className={styles.sign_up_checkbox__text} for="choice10">
                        将来、移住・定住を考えているため
                      </label>
                    </div>
                    <div className={`${styles.sign_up_checkbox__item} ${styles['sign_up_checkbox__item--wrap']}`}>
                      <input className={styles.sign_up_checkbox__btn} id="choice11" type="checkbox" />
                      <label className={styles.sign_up_checkbox__text} for="choice11">
                        その他
                      </label>
                      <input className={`${styles.sign_up_contents__input} ${styles['sign_up__input--checkbox']}`} id="text" type="text" name="other" value="" placeholder="入力してください"></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.sign_up_contents__btns} ${styles['sign_up_contents__btns--is_pc']}`}>
                <input className={`${styles.sign_up_contents__btn} ${styles['sign_up_contents__btn--submit']}`} type="submit" value="この内容で確定する" />
                <a href="/" className={`${styles.sign_up_contents__btn} ${styles[`sign_up_contents__btn--back`]}`}>
                  戻る
                </a>
              </div>
            </div>
            <div className={styles.sign_up_side}>
              <div className={styles.sign_up_side__head}>
                <img className={styles.sign_up_side__img} src="/assets/img/application/pic_dummy.jpg" />
                <div className={styles.sign_up_side__texts}>
                  <p className={styles.sign_up_side__title}>見出しのダミーテキストイメージです。見出しのダミーテキストイメージです。</p>
                  <p className={styles.sign_up_side__date}>2023/7/16（日）14:00</p>
                </div>
              </div>
              <p className={styles.sign_up_side__label}>料金</p>
              <div className={styles.sign_up_side_price}>
                <p className={styles.sign_up_side_price__text}>参加費</p>
                <p className={styles.sign_up_side_price__number}>¥2,000</p>
              </div>
              <a className={styles.sign_up_side__btn}>入力が完了していません</a>
              <div className={styles.sign_up_side_description}>
                <p className={styles.sign_up_side_description__title}>キャンセルについて</p>
                <p className={styles.sign_up_side_description__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
              </div>
            </div>
            <div className={`${styles.sign_up_contents__btns} ${styles['sign_up_contents__btns--is_sp']}`}>
              <input className={`${styles.sign_up_contents__btn} ${styles['sign_up_contents__btn--submit']}`} type="submit" value="この内容で確定する" />
              <a href="/" className={`${styles.sign_up_contents__btn} ${styles[`sign_up_contents__btn--back`]}`}>
                戻る
              </a>
            </div>
          </form>
        </div>
      </SubpageContents>
    </>
  );
};

export default ApplicationSignUp;
