import styles from './header.module.scss';

export default function Header() {
  return (
    <div className={styles.header_block}>
      <h1 className={styles.header_block__logo}>
        <a href="/">
          <picture>
            <source media="(max-width: 769px)" srcset="/assets/img/common/logo.png" />
            <img src="/assets/img/common/logo.png" alt="" />
          </picture>
        </a>
      </h1>
    </div>
  );
}
