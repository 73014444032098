import React from 'react';
import styles from './complete.module.scss';
import SubpageContents from '../../../components/layout/subpage_contents';

const StudentComplete = () => {
  return (
    <>
      <SubpageContents bgColor={'#fdfaf1'}>
        <div className={styles.complete}>
          <h1 className={styles.complete__title}>新規会員登録</h1>
          <p className={styles.complete__title_en}>Sign up</p>
          <section className={styles.complete_contents}>
            <h2 className={styles.complete_contents__title}>新規会員登録が完了しました</h2>
            <p className={styles.complete_contents__text}>ご登録いただきありがとうございます。</p>

            <div className={styles.complete_contents__btn}>
              <a href="/application">TOPページに戻る</a>
            </div>
          </section>
        </div>
      </SubpageContents>
    </>
  );
};

export default StudentComplete;
