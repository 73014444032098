import React from 'react';
import { useParams } from 'react-router-dom'; // React RouterからuseParamsをインポート
import styles from './detail.module.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const AdminEventDetail = () => {
  const { id } = useParams(); // React RouterのuseParamsを使用してidを取得

  // 通常はこのIDを使ってAPIから詳細データを取得します。
  // 今回はmockListdataから詳細データを取得しています。
  const mockListdata = [
    {
      id: 1,
      name: 'ABCDE組織・団体',
      date: '2023.11.12',
    },
    {
      id: 2,
      name: 'ABCDE組織・団体',
      date: '2023.11.13',
    },
    {
      id: 3,
      name: 'ABCDE組織・団体',
      date: '2023.11.14',
    },
    {
      id: 4,
      name: 'ABCDE組織・団体',
      date: '2023.11.15',
    },
    {
      id: 5,
      name: 'ABCDE組織・団体',
      date: '2023.11.12',
    },
    {
      id: 6,
      name: 'ABCDE組織・団体',
      date: '2023.11.13',
    },
    {
      id: 7,
      name: 'ABCDE組織・団体',
      date: '2023.11.14',
    },
    {
      id: 8,
      name: 'ABCDE組織・団体',
      date: '2023.11.15',
    },
    {
      id: 9,
      name: 'ABCDE組織・団体',
      date: '2023.11.12',
    },
    {
      id: 10,
      name: 'ABCDE組織・団体',
      date: '2023.11.13',
    },
    {
      id: 11,
      name: 'ABCDE組織・団体',
      date: '2023.11.14',
    },
    {
      id: 12,
      name: 'ABCDE組織・団体',
      date: '2023.11.15',
    },
  ];

  const SwitchExample = () => {
    return (
      <Form>
        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          className={styles.detail_switch__input}
        />
      </Form>
    );
  };

  // const organization = mockListdata.find((org) => org.id === parseInt(id, 10));

  const saveAlert = () => {};

  return (
    <div className={styles.organization_detail}>
      <Form className={styles.organization_detail__form}>
        <div className={styles.organization_detail__head}>
          <h1 className={styles.organization_detail__title}>イベント編集</h1>
          <a href="/event" className={styles.organization_detail__link}>
            イベント一覧へ戻る
          </a>
        </div>
        <div className={styles.organization_detail__contents}>
          <div className={styles.organization_detail__input}>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>イベント名</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <div className={styles.detail_section__head}>
                  <p className={styles.detail_section__title}>開催日</p>
                  <p className={styles.detail_section__title}>時刻</p>
                </div>
                <div className={styles.detail_section__inputs}>
                  <div className={styles.detail_section__input}>
                    <Form.Control required type="date" placeholder="placeholder" />
                    <p>〜</p>
                    <Form.Control required type="date" placeholder="placeholder" />
                  </div>
                  <div className={styles.detail_section__input}>
                    <Form.Control required type="time" placeholder="placeholder" />
                    <p>〜</p>
                    <Form.Control required type="time" placeholder="placeholder" />
                  </div>
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>定員</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <div className={styles.detail_section__head}>
                  <p className={styles.detail_section__title}>申込期間</p>
                  <p className={styles.detail_section__title}>時刻</p>
                </div>
                <div className={styles.detail_section__inputs}>
                  <div className={styles.detail_section__input}>
                    <Form.Control required type="date" placeholder="placeholder" />
                    <p>〜</p>
                    <Form.Control required type="date" placeholder="placeholder" />
                  </div>
                  <div className={styles.detail_section__input}>
                    <Form.Control required type="time" placeholder="placeholder" />
                    <p>〜</p>
                    <Form.Control required type="time" placeholder="placeholder" />
                  </div>
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>参加費</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control as="textarea" rows={8} placeholder="Write a message" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>イベント概要</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control as="textarea" rows={8} placeholder="Write a message" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>イベント詳細</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control as="textarea" rows={8} placeholder="Write a message" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>注意事項</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control as="textarea" rows={8} placeholder="Write a message" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>イベント・ワークショプ表記</h2>
                <div className={styles.detail_section__input}>
                  <div className={styles.detail_radio}>
                    <Form>
                      {['radio'].map((type) => (
                        <div className={styles.detail_radio__contents}>
                          <Form.Check inline label="イベント表記で表示する" name="group1" type={type} id={`inline-${type}-1`} className={styles.detail_radio__item} />
                          <Form.Check inline label="イベント表記で表示しない" name="group1" type={type} id={`inline-${type}-2`} className={styles.detail_radio__item} />
                        </div>
                      ))}
                    </Form>
                  </div>
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>応募する・申込むボタン 切替え</h2>
                <div className={styles.detail_section__input}>
                  <div className={styles.detail_radio}>
                    <Form>
                      {['radio'].map((type) => (
                        <div className={styles.detail_radio__contents}>
                          <Form.Check inline label="ローカルブリッジで応募する" name="group2" type={type} id={`inline-${type}-3`} className={styles.detail_radio__item} />
                          <Form.Check inline label="外部サービスで応募する" name="group2" type={type} id={`inline-${type}-4`} className={styles.detail_radio__item} />
                        </div>
                      ))}
                    </Form>
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <p className={styles.detail_section__sub_title}>URL設定</p>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="https://wwww" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>画像アップロード</h2>
                <div className={styles.detail_section__upload}>
                  <i class="bi bi-upload"></i>
                  <label className={styles.detail_section__label}>
                    <Form.Control type="file" />
                    Select Images to Upload
                  </label>
                </div>
                <div className={styles.detail_thumbnail}>
                  <div className={styles.detail_thumbnail__item}>
                    <img src="/assets/img/admin/pic_thumbnail01.jpg" className={styles.detail_thumbnail__img} />
                  </div>
                  <div className={styles.detail_thumbnail__item}>
                    <img src="/assets/img/admin/pic_thumbnail01.jpg" className={styles.detail_thumbnail__img} />
                  </div>
                  <div className={styles.detail_thumbnail__item}>
                    <img src="/assets/img/admin/pic_thumbnail01.jpg" className={styles.detail_thumbnail__img} />
                    <Form.Check className={styles.detail_thumbnail__text} type="checkbox" id="disabledFieldsetCheck" label="サムネイル画像に利用する" />
                  </div>
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <h2 className={styles.detail_section__title}>イベント申し込みしたユーザー</h2>
              <div className={styles.detail_user}>
                <div className={styles.detail_user__item}>
                  <p className={styles.detail_user__name}>佐野 貴久</p>
                  <p className={styles.detail_user__date}>登録 : 2023.12.11</p>
                </div>
                <div className={styles.detail_user__item}>
                  <p className={styles.detail_user__name}>山田 太郎</p>
                  <p className={styles.detail_user__date}>登録 : 2023.12.11</p>
                </div>
                <div className={styles.detail_user__item}>
                  <p className={styles.detail_user__name}>鈴木 花子</p>
                  <p className={styles.detail_user__date}>登録 : 2023.12.11</p>
                </div>
                <div className={styles.detail_user__item}>
                  <p className={styles.detail_user__name}>佐野 貴久</p>
                  <p className={styles.detail_user__date}>登録 : 2023.12.11</p>
                </div>
                <div className={styles.detail_user__item}>
                  <p className={styles.detail_user__name}>山田 太郎</p>
                  <p className={styles.detail_user__date}>登録 : 2023.12.11</p>
                </div>
              </div>
            </section>
          </div>
          <div className={styles.detail_side}>
            <section className={styles.detail_section}>
              <h2 className={styles.detail_section__title}>公開</h2>
              <div className={styles.detail_side__inputs}>
                <Button onClick={saveAlert} className={`${styles.detail_side__input} ${styles['detail_side__input--submit']}`} type="submit">
                  保存
                </Button>
                <Button className={`${styles.detail_side__input} ${styles['detail_side__input--delete']}`} type="submit">
                  削除
                </Button>
              </div>
            </section>
            <section className={styles.detail_section}>
              <h2 className={styles.detail_section__title}>公開</h2>
              <div className={styles.detail_side__inputs}>
                <Button onClick={saveAlert} className={`${styles.detail_side__input} ${styles['detail_side__input--submit']}`} type="submit">
                  承認する
                </Button>
                <Button className={`${styles.detail_side__input} ${styles['detail_side__input--delete']}`} type="submit">
                  非承認
                </Button>
              </div>
              <div className={styles.detail_side__inputs}>
                <Button onClick={saveAlert} className={`${styles.detail_side__input} ${styles['detail_side__input--done']}`} type="submit">
                  承認済み
                </Button>
              </div>
            </section>
            <section className={styles.detail_section}>
              <h2 className={styles.detail_section__title}>イベントを公開にする</h2>
              <div className={styles.detail_switch}>
                <p className={styles.detail_switch__text}>イベントを公開にする</p>
                <div className={styles.detail_switch__switch}>{SwitchExample()}</div>
              </div>
            </section>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AdminEventDetail;
