import React, { useState, useEffect } from 'react';
import styles from './detail.module.scss';
import AppContext from '../../../../context/AppContext';
import MypageContents from '../../../../components/layout/mypage';
import { useLocation } from 'react-router-dom';

const StudentExperienceDetail = () => {
  const [logoPreview, setLogoPreview] = useState(null);
  const [topImagePreview, setTopImagePreview] = useState(null);
  const [prImagePreview, setPrImagePreview] = useState(null);
  const { setIsMypage, setIsLogin } = React.useContext(AppContext); // 未使用の変数を削除
  //検証用にパラメーターを取得
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const paramBoolean = query.get('alert');

  useEffect(() => {
    setIsMypage(true);
    setIsLogin(true);
  }, []);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleTopImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setTopImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handlePrImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPrImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  return (
    <MypageContents title="イベント体験記事編集 - 編集" title_en="Event experience articles" currentPage="experience">
      <div className={styles.experience}>
        <form className={styles.experience__form}>
          <div className={styles.experience__contents}>
            <div className={styles.experience_item}>
              <label className={styles.experience_item__label} htmlFor="organization_type">
                イベントタイトル
              </label>
              <input className={styles.experience_item__input} type="text" id="organization_name" name="organization_name" placeholder="タイトルを入れてください" />
            </div>
            <div className={`${styles.experience_item} ${styles['experience_item--width-xs']}`}>
              <label className={styles.experience_item__label} htmlFor="organization_name">
                日付
              </label>
              <div className={styles.experience_item__date}>
                <input className={styles.experience_item__input} type="date" id="organization_name" name="organization_name" placeholder="日付を選択してください" />
              </div>
            </div>
            <div className={styles.experience_item}>
              <label className={styles.experience_item__label} htmlFor="additional_info">
                本文
              </label>
              <textarea className={`${styles.experience_item__input} ${styles['experience_item__input--textarea']}`} placeholder="Write a message" type="text" name="additional_info" />
            </div>
            <div className={styles.experience_item}>
              <label className={styles.experience_item__label} htmlFor="interest">
                タグ
              </label>
              <div className={styles.experience_item_choice}>
                <p className={styles.experience_item_choice__text}>
                  ＃ ダミーテキスト<span></span>
                </p>
                <p className={styles.experience_item_choice__text}>
                  ＃ ダミーテキスト<span></span>
                </p>
              </div>
              <input className={styles.experience_item__input} placeholder="入力してください" type="text" name="interest" />
            </div>
            <p className={styles.experience__text}>
              執筆者<span>執筆者の名前が入ります</span>
            </p>
          </div>
          <div className={styles.experience_submit}>
            <div className={styles.experience_submit__inner}>
              <p className={styles.experience_submit__title}>保存</p>
              <div className={styles.experience_submit__btns}>
                <button className={styles.experience_submit__btn} type="submit">
                  保存
                </button>
                <button className={`${styles.experience_submit__btn} ${styles['experience_submit__btn--delete']}`} type="submit">
                  削除
                </button>
              </div>
              <button className={`${styles.experience_submit__btn} ${styles['experience_submit__btn--proceed']}`} type="submit">
                入稿審査へ進む
              </button>
              <button className={`${styles.experience_submit__btn} ${styles['experience_submit__btn--wait']}`} type="submit">
                審査中
              </button>
            </div>
          </div>
        </form>
      </div>
    </MypageContents>
  );
};

export default StudentExperienceDetail;
