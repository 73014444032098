import React, { useState, useEffect, useRef } from 'react';
import styles from './header.module.scss';
import AppContext from '../../context/AppContext';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const { isMypage, isLogin } = React.useContext(AppContext);

  const toggleHamburger = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      menuRef.current.style.display = 'block';
      setTimeout(() => {
        document.body.style.overflow = 'hidden';
        menuRef.current.style.opacity = '1';
      }, 50); // 50ms delay for the browser to recognize the display change
    } else {
      document.body.style.overflow = 'auto';
      menuRef.current.style.opacity = '0';
      setTimeout(() => {
        menuRef.current.style.display = 'none';
      }, 300); // 300ms is the duration of the transition
    }
  }, [isOpen]);

  return (
    <div className={styles.header}>
      <div className={styles.header__logo}>
        <a href="/">
          <img src="/assets/img/common/logo.png" alt="" />
        </a>
      </div>
      <ul className={styles.header__menu}>
        <li className={styles.header__item}>
          <img className={styles.header__ico} src="/assets/img/common/ico_header01.svg" alt="" />
          <a href="#">ホーム</a>
        </li>
        <li className={styles.header__item}>
          <img className={styles.header__ico} src="/assets/img/common/ico_header02.svg" alt="" />
          <a href="#">はじめての方へ</a>
        </li>
        <li className={styles.header__item}>
          <img className={styles.header__ico} src="/assets/img/common/ico_header03.svg" alt="" />
          <a href="#">コミュニティ</a>
        </li>
        <li className={styles.header__item}>
          <img className={styles.header__ico} src="/assets/img/common/ico_header04.svg" alt="" />
          <a href="#">イベント情報</a>
        </li>
        <li className={styles.header__item}>
          <img className={styles.header__ico} src="/assets/img/common/ico_header05.svg" alt="" />
          <a href="#">体験記事を探す</a>
        </li>
        <li className={styles.header__item}>
          <img className={styles.header__ico} src="/assets/img/common/ico_header06.svg" alt="" />
          <a href="#">まちの魅力発見</a>
        </li>
      </ul>
      {isLogin ? (
        <div className={styles.header__user}>
          <img src="/assets/img/common/ico_login.jpg" />
          <p className={styles.header__num}>1</p>
        </div>
      ) : (
        <div className={styles.header__buttons}>
          <a href="#" className={`${styles.header__button} ${styles['header__button--login']}`}>
            ログイン
          </a>
          <a href="#" className={`${styles.header__button} ${styles['header__button--register']}`}>
            新規登録
          </a>
        </div>
      )}

      <div className={`${styles.header__hamburger} ${styles['is-sp']} ${isOpen ? styles['is-open'] : ''}`} onClick={toggleHamburger}>
        <span></span>
        <span></span>
      </div>
      {isLogin ? (
        <div ref={menuRef} className={`${styles.header_menu}`} style={{ opacity: '0', transition: 'opacity 0.3s ease-in-out' }}>
          <ul className={styles.header_menu__list}>
            <div className={styles.header_profile}>
              <div className={styles.header_profile__user}>
                <img className={styles.header_profile__icon} src="/assets/img/common/ico_login.jpg" />
                <p className={styles.header_profile__num}>1</p>
              </div>
              <p className={styles.header_profile__name}>ニックネーム</p>
            </div>
            <li className={styles.header_menu__item}>
              <a href="#">
                <img className={styles.header_menu__ico} src="/assets/img/common/ico_header01.svg" alt="" />
                ホーム
              </a>
            </li>
            <li className={styles.header_menu__item}>
              <a href="#">
                <img className={styles.header_menu__ico} src="/assets/img/common/ico_header02.svg" alt="" />
                はじめての方へ
              </a>
            </li>
            <li className={styles.header_menu__item}>
              <a href="#">
                <img className={styles.header_menu__ico} src="/assets/img/common/ico_header03.svg" alt="" />
                コミュニティ
              </a>
            </li>
            <li className={styles.header_menu__item}>
              <a href="#">
                <img className={styles.header_menu__ico} src="/assets/img/common/ico_header04.svg" alt="" />
                イベント情報
              </a>
            </li>
            <li className={styles.header_menu__item}>
              <a href="#">
                <img className={styles.header_menu__ico} src="/assets/img/common/ico_header05.svg" alt="" />
                体験記事を探す
              </a>
            </li>
            <li className={styles.header_menu__item}>
              <a href="#">
                <img className={styles.header_menu__ico} src="/assets/img/common/ico_header06.svg" alt="" />
                まちの魅力発見
              </a>
            </li>
            {isMypage ? (
              <>
                <li className={styles.header_menu__item}>
                  <a href="#">
                    <i class="bi bi-person-fill"></i>
                    プロフィール
                  </a>
                </li>
                <li className={styles.header_menu__item}>
                  <a href="#">
                    <i class="bi bi-calendar-check"></i>
                    会員情報設定
                  </a>
                </li>
                <li className={styles.header_menu__item}>
                  <a href="#">
                    <i class="bi bi-gear"></i>
                    イベント一覧
                  </a>
                </li>
              </>
            ) : null}
          </ul>
        </div>
      ) : (
        <div ref={menuRef} className={`${styles.header_menu}`} style={{ opacity: '0', transition: 'opacity 0.3s ease-in-out' }}>
          <ul className={styles.header_menu__list}>
            <li className={styles.header_menu__item}>
              <a href="#">
                <img className={styles.header_menu__ico} src="/assets/img/common/ico_header01.svg" alt="" />
                ホーム
              </a>
            </li>
            <li className={styles.header_menu__item}>
              <a href="#">
                <img className={styles.header_menu__ico} src="/assets/img/common/ico_header02.svg" alt="" />
                はじめての方へ
              </a>
            </li>
            <li className={styles.header_menu__item}>
              <a href="#">
                <img className={styles.header_menu__ico} src="/assets/img/common/ico_header03.svg" alt="" />
                コミュニティ
              </a>
            </li>
            <li className={styles.header_menu__item}>
              <a href="#">
                <img className={styles.header_menu__ico} src="/assets/img/common/ico_header04.svg" alt="" />
                イベント情報
              </a>
            </li>
            <li className={styles.header_menu__item}>
              <a href="#">
                <img className={styles.header_menu__ico} src="/assets/img/common/ico_header05.svg" alt="" />
                体験記事を探す
              </a>
            </li>
            <li className={styles.header_menu__item}>
              <a href="#">
                <img className={styles.header_menu__ico} src="/assets/img/common/ico_header06.svg" alt="" />
                まちの魅力発見
              </a>
            </li>
            {isMypage ? (
              <>
                <li className={styles.header_menu__item}>
                  <a href="#">
                    <i class="bi bi-person-fill"></i>
                    プロフィール
                  </a>
                </li>
                <li className={styles.header_menu__item}>
                  <a href="#">
                    <i class="bi bi-calendar-check"></i>
                    会員情報設定
                  </a>
                </li>
                <li className={styles.header_menu__item}>
                  <a href="#">
                    <i class="bi bi-gear"></i>
                    イベント一覧
                  </a>
                </li>
              </>
            ) : null}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;
