import React, { useState } from 'react';
import styles from './index.module.scss';
import Header from '../header';
import Footer from '../footer';
import AppContext from '../../context/AppContext';

export default function TopLayout({ children }) {
  const [isMypage, setIsMypage] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  return (
    <AppContext.Provider value={{ isMypage, setIsMypage, isLogin, setIsLogin }}>
      <div className={styles.main_contents}>
        <Header />
        {children}
        <Footer />
      </div>
    </AppContext.Provider>
  );
}
