import React from 'react';
import styles from './event_card.module.scss';
import { useLocation } from 'react-router-dom';

const EventCard = ({ cardData, pathname }) => {
  const origin = window.location.origin; // スキームとドメイン名を取得
  const fullUrl = `${origin}${pathname}/${cardData.id}`; // 完全なURLを生成

  const { thumbnail, date, label, text, capacity, period } = cardData;
  return (
    <div className={styles.event_card}>
      <a href={fullUrl}>
        <img src={thumbnail} className={styles.event_card__img} alt="" />
        <div className={styles.event_card__contents}>
          <p className={styles.event_card__date}>開催日 : {date}</p>
          <p className={styles.event_card__label}>{label}</p>
          <p className={styles.event_card__text}>{text}</p>
          <div className={styles.event_card__foot}>
            {capacity ? (
              <p className={styles.event_card__capacity}>
                定員：
                <br />
                {capacity}名
              </p>
            ) : (
              ''
            )}
            {period ? <p className={styles.event_card__period}>申込期間：{period}</p> : ''}
          </div>
        </div>
      </a>
    </div>
  );
};

export default EventCard;
