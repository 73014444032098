import React, { useState } from 'react';
import styles from './event.module.scss';
import AdminList from '../../../components/admin/list';
import Pagination from '../../../components/admin/pagination';

const AdminEvent = () => {
  const page = window.location.href;
  const mockListdata = [
    {
      id: 1,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      group: '組織・団体名',
      status: '投稿済み',
      held: '2023.11.12～2023.11.12',
      period: '2023.11.12～2023.11.12',
      date: '2023.11.12',
    },
    {
      id: 2,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      group: '組織・団体名',
      status: '投稿済み',
      held: '2023.11.12～2023.11.12',
      period: '2023.11.12～2023.11.12',
      date: '2023.11.12',
    },
    {
      id: 3,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      group: '組織・団体名',
      status: '投稿済み',
      held: '2023.11.12～2023.11.12',
      period: '2023.11.12～2023.11.12',
      date: '2023.11.12',
    },
    {
      id: 4,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      group: '組織・団体名',
      status: '投稿済み',
      held: '2023.11.12～2023.11.12',
      period: '2023.11.12～2023.11.12',
      date: '2023.11.12',
    },
    {
      id: 5,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      group: '組織・団体名',
      status: '投稿済み',
      held: '2023.11.12～2023.11.12',
      period: '2023.11.12～2023.11.12',
      date: '2023.11.12',
    },
    {
      id: 6,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      group: '組織・団体名',
      status: '投稿済み',
      held: '2023.11.12～2023.11.12',
      period: '2023.11.12～2023.11.12',
      date: '2023.11.12',
    },
    {
      id: 7,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      group: '組織・団体名',
      status: '投稿済み',
      held: '2023.11.12～2023.11.12',
      period: '2023.11.12～2023.11.12',
      date: '2023.11.12',
    },
    {
      id: 8,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      group: '組織・団体名',
      status: '投稿済み',
      held: '2023.11.12～2023.11.12',
      period: '2023.11.12～2023.11.12',
      date: '2023.11.12',
    },
    {
      id: 9,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      group: '組織・団体名',
      status: '投稿済み',
      held: '2023.11.12～2023.11.12',
      period: '2023.11.12～2023.11.12',
      date: '2023.11.12',
    },
    {
      id: 10,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      group: '組織・団体名',
      status: '投稿済み',
      held: '2023.11.12～2023.11.12',
      period: '2023.11.12～2023.11.12',
      date: '2023.11.12',
    },
    {
      id: 11,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      group: '組織・団体名',
      status: '投稿済み',
      held: '2023.11.12～2023.11.12',
      period: '2023.11.12～2023.11.12',
      date: '2023.11.12',
    },
    {
      id: 12,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      group: '組織・団体名',
      status: '投稿済み',
      held: '2023.11.12～2023.11.12',
      period: '2023.11.12～2023.11.12',
      date: '2023.11.12',
    },
  ];
  const listHeader = ['組織・団体名', 'Date', '承認状況', '開催日', '申込期間', '登録日'];

  const [currentPage, setCurrentPage] = useState(1);
  //表示件数を設定
  const itemsPerPage = 8;
  const totalPages = Math.ceil(mockListdata.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div className={styles.organization}>
      <div className={styles.organization__head}>
        <h1 className={styles.organization__title}>イベント管理一覧</h1>
      </div>

      <div className={styles.organization__list}>
        <AdminList page={page} mockListdata={mockListdata} listHeader={listHeader} startIndex={startIndex} endIndex={endIndex} garbage={true} />
      </div>
      <div className={styles.organization__pagination}>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={(page) => setCurrentPage(page)} />
      </div>
    </div>
  );
};

export default AdminEvent;
