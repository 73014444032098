import React from 'react';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';
import styles from './community.module.scss';

const AboutCommunity = () => {
  const AboutNav = () => {
    return (
      <ul className={styles.nav}>
        <li className={styles.nav__item}>
          <a href="/about/first">はじめての方へ</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/passport">ふるさとパスポートとは？</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/contribute">貢献度バッジとは？</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/article">イベント体験記事・魅力発見記事について</a>
        </li>
        <li className={`${styles.nav__item} ${styles['active']}`}>
          <a href="/about/community">コミュニティについて</a>
        </li>
      </ul>
    );
  };
  return (
    <>
      <SubpageMainvisual title="コミュニティについて" enTitle="About Local Bridge Community" />
      <SubpageContents bgColor={'#fdfaf1'}>
        <AboutNav />
        <div className={styles.community}>
          <div className={styles.community__inner}>
            <div className={styles.community__contents}>
              <section className={styles.community__content}>
                <h2 className={styles.community__title}>まずは情報収集から</h2>
                <p className={styles.community__text}>
                  Local Bridgeコミュニティは、「地方創生」に興味のある方なら誰でも参加できるオンラインコミュニティです。Local Bridge内の記事をみて、興味が湧いた地域、自身と接点のある地域での活動に参加する第1歩として、まずはコミュニティを覗いてみませんか。
                  <br />
                  気になるイベントやワークショップの情報を見つけたら、まずは参加して「ふるさとパスポート」を取得しましょう！
                </p>
                <img className={styles.community__img} src="/assets/img/about/community/pic_img01.jpg" alt="" />
              </section>
              <section className={styles.community__content}>
                <h2 className={styles.community__title}>会員限定のコミュニティ</h2>
                <p className={styles.community__text}>
                  Local Bridgeコミュニティには、「ふるさとパスポート」を持った人限定のコミュニティがあります。コミュニティに参加するにはイベントに参加するなどしてその地域のふるさとパスポートを得る必要があります。
                  <br />
                  同じ地域の、「ふるさとパスポート」を持つ仲間とより深く関われるだけでなく、そこで発信される情報もあなたにピッタリのものばかりです。
                </p>
              </section>
              <section className={styles.community__content}>
                <h2 className={styles.community__title}>仲間と情報を共有してより良い活動を</h2>
                <p className={styles.community__text}>
                  コミュニティでは、その地域の最新のイベント情報が共有されるだけでなく、あなたが執筆した記事を紹介して情報交換をしたり、あなたのその地域に関する活動を発信し新たな仲間を募ることもできます。
                  <br />
                  さらに、コミュニティ内での意見交換を通じて、あなたの活動に対する気付きを得たり、新たな活動アイデアを一緒に探るなど積極的に活動してもらえます。
                  <br />
                  学生、社会人、住民の垣根を超えて、同じ地域を応援する仲間と情報を共有しあえるだけでなく、お互いに刺激し合い、より良い活動を目指していけるコミュニティです。
                </p>
              </section>
              <section className={styles.community__content}>
                <h2 className={styles.community__title}>活動を評価されると貢献度バッジが貰える</h2>
                <p className={styles.community__text}>
                  Local Bridgeでは、オンラインでの情報発信・アイデア出しなども地方創生活動の関わり方の一つと考え、貢献バッジを付与します。
                  <br />
                  方法として、毎月MVP表彰制度を設けます。
                  <br />
                  MVPは、8つの貢献度バッジの種類ごとに毎月1人ずつ、コミュニティメンバーの投票によって選ばれます。
                  <br />
                  見事MVPに輝くと、その種類の貢献度バッジを3枚獲得することができます。
                </p>
              </section>
              <p className={styles.community__lead}>
                居住地などの問題で頻繁にその地域に足を運ぶことが難しくても、 <br className="is-pc" />
                あなたが持っている様々な考え・アイデアを価値に変える活動に参加してみませんか？
              </p>
            </div>
          </div>
          <section className={styles.community_group}>
            <h2 className={styles.community_group__title}>Local Bridgeコミュニティ</h2>
            <p className={styles.community_group__title_en}>Local Bridge Community</p>
            <div className={styles.community_group__btn}>
              <a href="#">コミュニティサイトへ行く！</a>
              <img className={styles.community_group__catch} src="/assets/img/top/pic_community05.png" alt="Community" />
            </div>
          </section>
        </div>
      </SubpageContents>
    </>
  );
};

export default AboutCommunity;
