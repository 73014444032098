import React from 'react';
import styles from './login.module.scss';
import SubpageMainvisual from '../../../components/subpage_mainvisual';
import SubpageContents from '../../../components/layout/subpage_contents';

const Studentlogin = () => {
  return (
    <>
      <SubpageMainvisual title="ログイン" enTitle="Login" />
      <SubpageContents bgColor={'#fdfaf1'}>
        <form>
          <div className={styles.login}>
            <div className={styles.login_contents}>
              <div className={styles.login_contents__inner}>
                <input className={styles.login_contents__input} type="text" placeholder="メールアドレス" />
                <input className={styles.login_contents__input} type="password" placeholder="パスワード" />
                <button className={styles.login_contents__submit} type="submit">
                  ログイン
                </button>
                <a className={styles.login_contents__forgot} href="#">
                  パスワードを忘れた方はこちらから
                </a>
                <div className={styles.login_contents__btns}>
                  <a className={styles.login_contents__btn} href="#">
                    組織・団体様はこちらから
                  </a>
                  <a className={styles.login_contents__btn} href="#">
                    未登録の方はこちらから
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.login__link}>
              <a href="#">お困りの方は問合せフォームへ</a>
            </div>
          </div>
        </form>
      </SubpageContents>
    </>
  );
};

export default Studentlogin;
