import React, { useEffect } from 'react';
import styles from './detail.module.scss';
import AppContext from '../../../../context/AppContext';
import MypageContents from '../../../../components/layout/mypage';

const StudentAttractiveDetail = () => {
  const { setIsMypage, setIsLogin } = React.useContext(AppContext); // 未使用の変数を削除

  useEffect(() => {
    setIsMypage(true);
    setIsLogin(true);
  }, []);
  return (
    <MypageContents title="魅力発見記事編集 - 編集" title_en="Attractive discovery articles" currentPage="attractive">
      <div className={styles.attractive}>
        <form className={styles.attractive__form}>
          <div className={styles.attractive__contents}>
            <div className={styles.attractive_item}>
              <label className={styles.attractive_item__label} htmlFor="organization_type">
                タイトル
              </label>
              <input className={styles.attractive_item__input} type="text" id="organization_name" name="organization_name" placeholder="タイトルを入れてください" />
            </div>
            <div className={`${styles.attractive_item} ${styles['attractive_item--width-xs']}`}>
              <label className={styles.attractive_item__label} htmlFor="organization_name">
                日付
              </label>
              <div className={styles.attractive_item__date}>
                <input className={styles.attractive_item__input} type="date" id="organization_name" name="organization_name" placeholder="日付を選択してください" />
              </div>
            </div>
            <div className={styles.attractive_item}>
              <label className={styles.attractive_item__label} htmlFor="additional_info">
                本文
              </label>
              <textarea className={`${styles.attractive_item__input} ${styles['attractive_item__input--textarea']}`} placeholder="Write a message" type="text" name="additional_info" />
            </div>
            <div className={styles.attractive_item}>
              <label className={styles.attractive_item__label} htmlFor="interest">
                タグ
              </label>
              <div className={styles.attractive_item_choice}>
                <p className={styles.attractive_item_choice__text}>
                  ＃ ダミーテキスト<span></span>
                </p>
                <p className={styles.attractive_item_choice__text}>
                  ＃ ダミーテキスト<span></span>
                </p>
              </div>
              <input className={styles.attractive_item__input} placeholder="入力してください" type="text" name="interest" />
            </div>
            <p className={styles.attractive__text}>
              執筆者<span>執筆者の名前が入ります</span>
            </p>
          </div>
          <div className={styles.attractive_submit}>
            <div className={styles.attractive_submit__inner}>
              <p className={styles.attractive_submit__title}>保存</p>
              <div className={styles.attractive_submit__btns}>
                <button className={styles.attractive_submit__btn} type="submit">
                  保存
                </button>
                <button className={`${styles.attractive_submit__btn} ${styles['attractive_submit__btn--delete']}`} type="submit">
                  削除
                </button>
              </div>
              <button className={`${styles.attractive_submit__btn} ${styles['attractive_submit__btn--proceed']}`} type="submit">
                入稿審査へ進む
              </button>
              <button className={`${styles.attractive_submit__btn} ${styles['attractive_submit__btn--wait']}`} type="submit">
                審査中
              </button>
            </div>
          </div>
        </form>
      </div>
    </MypageContents>
  );
};

export default StudentAttractiveDetail;
