import React, { useState } from 'react';
import styles from './admin.module.scss';
import Header from '../../components/admin/header';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

const Admin = () => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <div className={styles.index_page}>
      <Header />
      <section className={styles.index_block}>
        <div className={`${styles.index_block__inner} shadow-sm rounded`}>
          <h2 className={`${styles.index_block__title}`}>菅理者ログイン</h2>
          <Form className={styles.index_block__form} noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group expand="lg" className="mt-5" controlId="formBasicEmail">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control required size="lg" type="email" placeholder="abc@sample.com" />
            </Form.Group>

            <Form.Group className="mt-5" controlId="formBasicPassword">
              <Form.Label>パスワード</Form.Label>
              <Form.Control required size="lg" type="password" placeholder="Placeholder" />
              <Form.Control.Feedback type="invalid">パスワードが違います</Form.Control.Feedback>
            </Form.Group>
            <div className="d-grid gap-2 mt-5">
              <Button variant="primary" type="submit" size="lg" className={styles.index_block__btn}>
                ログイン
              </Button>
            </div>
          </Form>
        </div>
      </section>
    </div>
  );
};

export default Admin;
