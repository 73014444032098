import React, { useEffect } from 'react';
import styles from './detail.module.scss';
import SubpageContents from '../../components/layout/subpage_contents';
import ArticleCard from '../../components/card/article_card';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.min.css';



const EventDetail = () => {
  const mockCardData = [
    {
      id: 1,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '01記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 2,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '02記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 3,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '03記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 4,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '04記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 5,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '05記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 6,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '06記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 7,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '07記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 8,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '08記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 9,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '09記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
  ];
  useEffect(() => {
    const swiper = new Swiper('.swiper-container', {
      spaceBetween: 12,
      slidesPerView: 1,
      spaceBetween: 50,
      loop: true,
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination', // ページネーション要素のセレクタ
        clickable: true, // クリック可能にする
      },
      navigation: {
        nextEl: '.swiper-button-next', // 次のボタン要素のセレクタ
        prevEl: '.swiper-button-prev', // 前のボタン要素のセレクタ
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 20,
        },
      },
    });
  }, []);
  return (
    <>
      <SubpageContents bgColor="#dbf1db">
        <div className={styles.detail}>
          <p className={styles.detail__label}><img src='/assets/img/event/pic_icon01.jpg' />ODK県ODK市</p>
          <h1 className={styles.detail__title}>タイトルが入ります。この文章はダミーです。文字の大きさや書体のサンプルです。この文章はダミーです。</h1>
          <div className={styles.detail__contents}>
            <div className={styles.detail__main}>
              <div class={styles.detail_swiper}>
                <div className='swiper-container'>
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <img src="/assets/img/event/pic_slide01.jpg" alt="" />
                    </div>
                    <div className="swiper-slide">
                      <img src="/assets/img/event/pic_slide01.jpg" alt="" />
                    </div>
                    <div className="swiper-slide">
                      <img src="/assets/img/event/pic_slide01.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
                <div className={`swiper-pagination ${styles.detail__pagination}`}></div>
              </div>
              <p className={styles.detail__city}><img src='/assets/img/event/pic_icon01.jpg' />ODK県ODK市</p>
              <section className={styles.detail_section}>
                <h2 className={styles.detail_section__title}><img src='/assets/img/event/ico_flag01.svg' />イベント概要</h2>
                <p className={styles.detail_section__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです</p>
              </section>
              <section className={styles.detail_section}>
                <h2 className={styles.detail_section__title}><img src='/assets/img/event/ico_flag02.svg' />イベント詳細</h2>
                <p className={styles.detail_section__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                <section className={styles.detail_topic}>
                  <h3 className={styles.detail_topic__title}>【プログラム①】地域おこしに関する講座を受講</h3>
                  <picture>
                    <source media="(max-width: 769px)" srcset="/assets/img/event/pic_img01.jpg" />
                    <img className={styles.detail_topic__img} src="/assets/img/event/pic_img01.jpg" alt="" />
                  </picture>
                  <p className={styles.detail_topic__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                  <p className={styles.detail_topic__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                </section>
                <section className={styles.detail_topic}>
                  <h3 className={styles.detail_topic__title}>【プログラム①】地域おこしに関する講座を受講</h3>
                  <picture>
                    <source media="(max-width: 769px)" srcset="/assets/img/event/pic_img02.jpg" />
                    <img className={styles.detail_topic__img} src="/assets/img/event/pic_img02.jpg" alt="" />
                  </picture>
                  <p className={styles.detail_topic__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                  <p className={styles.detail_topic__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                </section>
              </section>
              <div className={styles.detail__btn}>
                <a href="#">イベント・ワークショップ一覧へ</a>
              </div>
            </div>
            <section className={styles.detail_side}>
              <h2 className={styles.detail_side__title}>イベント情報</h2>
              <div className={styles.detail_side__info}>
                <p className={styles.detail_side__sub_title}>開催日</p>
                <p className={styles.detail_side__text}>2023/07/16（日）～<br />
                  2023/07/16（日）14:00~15:00</p>
              </div>
              <div className={styles.detail_side__info}>
                <p className={styles.detail_side__sub_title}>定員</p>
                <p className={styles.detail_side__text}>10人</p>
              </div>
              <div className={styles.detail_side__info}>
                <p className={styles.detail_side__sub_title}>申込期間</p>
                <p className={styles.detail_side__text}>2023/6/01（木）☓☓:00 ~<br />
                  2023/7/15（土）☓☓:00</p>
              </div>
              <div className={styles.detail_side__info}>
                <p className={styles.detail_side__sub_title}>参加費</p>
                <p className={styles.detail_side__text}>¥4,000-（税込）</p>
              </div>
              <form className={styles.detail_form}>
                <p className={styles.detail_form__text}><a href="#">イベント規約</a></p>
                <div className={styles.detail_form__check}>
                  <span>
                    <input type="checkbox" id="check" />
                    <label htmlFor="check">イベントに同意する</label>
                  </span>
                </div>
                <input className={styles.detail_form__btn} type="submit" value="申し込む" />
              </form>
              <div className={styles.detail_notes}>
                <p className={styles.detail_notes__title}>注意事項</p>
                <p className={styles.detail_notes__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。の文章はダミーです文字の大きさや書体のサンプルです。の文章はダミーです文字の大きさや書体のサンプルです。</p>
              </div>

            </section>
          </div>
          <section className={styles.detail_other}>
            <h2 className={styles.detail_other__title}><img src='/assets/img/event/ico_message.svg' />イベント体験記事リンク</h2>
            <div className={styles.detail_other__contents}>
              {mockCardData.slice(0, 3).map((card, index) => (
                <ArticleCard
                  key={index}
                  cardData={card}
                  pathname={'/experience'}
                />
              ))}
            </div>
          </section>
        </div>
      </SubpageContents>
    </>
  );
};

export default EventDetail;
