import React from 'react';
import { useParams } from 'react-router-dom'; // React RouterからuseParamsをインポート
import styles from './detail.module.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const AdminNewsDetail = () => {
  const { id } = useParams(); // React RouterのuseParamsを使用してidを取得

  // 通常はこのIDを使ってAPIから詳細データを取得します。
  // 今回はmockListdataから詳細データを取得しています。
  const mockListdata = [
    {
      id: 1,
      name: 'ABCDE組織・団体',
      date: '2023.11.12',
    },
    {
      id: 2,
      name: 'ABCDE組織・団体',
      date: '2023.11.13',
    },
    {
      id: 3,
      name: 'ABCDE組織・団体',
      date: '2023.11.14',
    },
    {
      id: 4,
      name: 'ABCDE組織・団体',
      date: '2023.11.15',
    },
    {
      id: 5,
      name: 'ABCDE組織・団体',
      date: '2023.11.12',
    },
    {
      id: 6,
      name: 'ABCDE組織・団体',
      date: '2023.11.13',
    },
    {
      id: 7,
      name: 'ABCDE組織・団体',
      date: '2023.11.14',
    },
    {
      id: 8,
      name: 'ABCDE組織・団体',
      date: '2023.11.15',
    },
    {
      id: 9,
      name: 'ABCDE組織・団体',
      date: '2023.11.12',
    },
    {
      id: 10,
      name: 'ABCDE組織・団体',
      date: '2023.11.13',
    },
    {
      id: 11,
      name: 'ABCDE組織・団体',
      date: '2023.11.14',
    },
    {
      id: 12,
      name: 'ABCDE組織・団体',
      date: '2023.11.15',
    },
  ];

  const SwitchExample = () => {
    return (
      <Form>
        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          className={styles.detail_switch__input}
        />
      </Form>
    );
  };

  // const organization = mockListdata.find((org) => org.id === parseInt(id, 10));

  const saveAlert = () => {};

  return (
    <div className={styles.news_detail}>
      <Form className={styles.news_detail__form}>
        <div className={styles.news_detail__head}>
          <h1 className={styles.news_detail__title}>お知らせ入力</h1>
          <a href="/event" className={styles.news_detail__link}>
            お知らせ記事一覧
          </a>
        </div>
        <div className={styles.news_detail__contents}>
          <div className={styles.news_detail__input}>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>タイトル</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>日付</h2>
                <div className={`${styles.detail_section__input} ${styles['detail_section__input--narrow']}`}>
                  <Form.Control required type="date" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>イベント詳細</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control as="textarea" rows={8} placeholder="Write a message" />
                </div>
              </Form.Group>
            </section>{' '}
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>タグ</h2>
                <div className={`${styles.detail_section__input} ${styles['detail_section__input--narrow']}`}>
                  <Form.Control required type="text" />
                </div>
                <div className={styles.detail_tag}>
                  <p className={styles.detail_tag__item}>
                    <i class="bi bi-x-octagon"></i>
                    タグ
                  </p>
                  <p className={styles.detail_tag__item}>
                    <i class="bi bi-x-octagon"></i>
                    タグ
                  </p>
                  <p className={styles.detail_tag__item}>
                    <i class="bi bi-x-octagon"></i>
                    タグ
                  </p>
                </div>
              </Form.Group>
            </section>
          </div>
          <div className={styles.detail_side}>
            <section className={styles.detail_section}>
              <h2 className={styles.detail_section__title}>公開</h2>
              <div className={styles.detail_side__inputs}>
                <Button onClick={saveAlert} className={`${styles.detail_side__input} ${styles['detail_side__input--submit']}`} type="submit">
                  保存
                </Button>
                <Button className={`${styles.detail_side__input} ${styles['detail_side__input--delete']}`} type="submit">
                  削除
                </Button>
              </div>
            </section>
            <section className={styles.detail_section}>
              <div className={styles.detail_switch}>
                <h2 className={styles.detail_switch__title}>お知らせを非表示にする</h2>
                <div className={styles.detail_switch__contents}>
                  <p className={styles.detail_switch__text}>OFF</p>
                  <div className={styles.detail_switch__btn}>{SwitchExample()}</div>
                  <p className={styles.detail_switch__text}>ON</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AdminNewsDetail;
