import React, { useState, useEffect } from 'react';
import styles from './detail.module.scss';
import AppContext from '../../../../context/AppContext';
import MypageContents from '../../../../components/layout/mypage';
import { useLocation } from 'react-router-dom';

const OrganizationList = () => {
  const [logoPreview, setLogoPreview] = useState(null);
  const [topImagePreview, setTopImagePreview] = useState(null);
  const [prImagePreview, setPrImagePreview] = useState(null);
  const { setIsMypage, setIsLogin } = React.useContext(AppContext);
  //検証用にパラメーターを取得
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const paramBoolean = query.get('alert');

  useEffect(() => {
    setIsMypage(true);
    setIsLogin(true);
  }, []);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleTopImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setTopImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handlePrImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPrImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  return (
    <MypageContents title="イベント入稿・編集" title_en="Post/Edit" currentPage="event">
      <div className={styles.setting}>
        <form className={styles.setting__form}>
          <div className={styles.setting__contents}>
            <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="organization_type">
                イベント名
              </label>
              <input className={styles.setting_item__input} type="text" id="organization_name" name="organization_name" placeholder="タイトルを入れてください" />
            </div>
            <div className={`${styles.setting_item}`}>
              <label className={styles.setting_item__label} htmlFor="organization_name">
                開催日
              </label>
              <div className={styles.setting_item__date}>
                <input className={styles.setting_item__input} type="date" id="organization_name" name="organization_name" placeholder="タイトルを入れてください" />
                <p className={styles.setting_item__date_text}>〜</p>
                <input className={styles.setting_item__input} type="date" id="organization_name" name="organization_name" placeholder="タイトルを入れてください" />
              </div>
            </div>
            <div className={`${styles.setting_item}`}>
              <label className={styles.setting_item__label} htmlFor="organization_name">
                時刻
              </label>
              <div className={styles.setting_item__date}>
                <input className={styles.setting_item__input} type="time" id="organization_name" name="organization_name" placeholder="タイトルを入れてください" />
                <p className={styles.setting_item__date_text}>〜</p>
                <input className={styles.setting_item__input} type="time" id="organization_name" name="organization_name" placeholder="タイトルを入れてください" />
              </div>
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-xs']}`}>
              <label className={styles.setting_item__label} htmlFor="prefecture">
                定員
              </label>
              <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']}`}>
                <select name="prefecture" autocomplete="shipping address-level1">
                  <option value="" disabled selected>
                    選択してください
                  </option>
                  <option value="test01">test01</option>
                  <option value="test02">test02</option>
                  <option value="test03">test03</option>
                  <option value="test04">test04</option>
                </select>
              </div>
            </div>
            <div className={`${styles.setting_item}`}>
              <label className={styles.setting_item__label} htmlFor="organization_name">
                申込期間
              </label>
              <div className={styles.setting_item__date}>
                <input className={styles.setting_item__input} type="date" id="organization_name" name="organization_name" placeholder="タイトルを入れてください" />
                <p className={styles.setting_item__date_text}>〜</p>
                <input className={styles.setting_item__input} type="date" id="organization_name" name="organization_name" placeholder="タイトルを入れてください" />
              </div>
            </div>
            <div className={`${styles.setting_item}`}>
              <label className={styles.setting_item__label} htmlFor="organization_name">
                時刻
              </label>
              <div className={styles.setting_item__date}>
                <input className={styles.setting_item__input} type="time" id="organization_name" name="organization_name" placeholder="タイトルを入れてください" />
                <p className={styles.setting_item__date_text}>〜</p>
                <input className={styles.setting_item__input} type="time" id="organization_name" name="organization_name" placeholder="タイトルを入れてください" />
              </div>
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-md']}`}>
              <label className={styles.setting_item__label} htmlFor="additional_info">
                参加費
              </label>
              <textarea className={`${styles.setting_item__input} ${styles['setting_item__input--textarea']}`} placeholder="入力してください" type="text" name="additional_info" />
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-md']}`}>
              <label className={styles.setting_item__label} htmlFor="additional_info">
                イベント概要
              </label>
              <textarea className={`${styles.setting_item__input} ${styles['setting_item__input--textarea']}`} placeholder="入力してください" type="text" name="additional_info" />
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-md']}`}>
              <label className={styles.setting_item__label} htmlFor="additional_info">
                イベント詳細
              </label>
              <textarea className={`${styles.setting_item__input} ${styles['setting_item__input--textarea']}`} placeholder="入力してください" type="text" name="additional_info" />
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-md']}`}>
              <label className={styles.setting_item__label} htmlFor="additional_info">
                注意事項
              </label>
              <textarea className={`${styles.setting_item__input} ${styles['setting_item__input--textarea']}`} placeholder="入力してください" type="text" name="additional_info" />
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-md']}`}>
              <label className={styles.setting_item__label} htmlFor="event">
                イベント・ワークショプ表記
              </label>
              <div className={styles.setting_item_radio}>
                <div className={styles.setting_item_radio__inner}>
                  <input className={styles.setting_item_radio__btn} type="radio" id="huey01" name="drone01" value="huey01" />
                  <label className={styles.setting_item_radio__text} for="huey01">
                    イベント表記で表示する
                  </label>
                </div>
                <div className={styles.setting_item_radio__inner}>
                  <input className={styles.setting_item_radio__btn} type="radio" id="dewey01" name="drone01" value="dewey01" />
                  <label className={styles.setting_item_radio__text} for="dewey01">
                    イベント表記で表示しない
                  </label>
                </div>
              </div>
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-md']}`}>
              <label className={styles.setting_item__label} htmlFor="apply">
                応募する・申込むボタン切替え
              </label>
              <div className={styles.setting_item_radio}>
                <div className={styles.setting_item_radio__inner}>
                  <input className={styles.setting_item_radio__btn} type="radio" id="huey02" name="drone02" value="huey02" />
                  <label className={styles.setting_item_radio__text} for="huey02">
                    ローカルブリッジで応募する
                  </label>
                </div>
                <div className={styles.setting_item_radio__inner}>
                  <input className={styles.setting_item_radio__btn} type="radio" id="dewey02" name="drone02" value="dewey02" />
                  <label className={styles.setting_item_radio__text} for="dewey02">
                    外部サービスで応募する
                  </label>
                </div>
              </div>
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-md']}`}>
              <label className={styles.setting_item__label} htmlFor="address">
                URL設定
              </label>
              <input className={styles.setting_item__input} placeholder="https://wwww" type="text" name="address" autocomplete="shipping address-line1" />
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--upload']}`}>
              <p className={styles.setting_item__label} htmlFor="organization_logo">
                画像アップロード
              </p>
              <label className={`${styles.setting_upload01} ${topImagePreview ? styles['is-preview'] : ''}`}>
                <input type="file" name="top_image" onChange={handleTopImageChange} />
                {topImagePreview ? (
                  <img className={styles.setting_upload01__preview} src={topImagePreview} alt="Preview" />
                ) : (
                  <>
                    <img className={styles.setting_upload01__img} src="/assets/img/common/ico_camera.svg" alt="" />
                    <p className={styles.setting_upload01__text}>
                      ファイルをアップロード
                      <img src="/assets/img/common/ico_upload_white.svg" />
                    </p>
                  </>
                )}
              </label>
              <p className={styles.setting_upload01__note}>サムネイルに利用する画像を選択してください</p>
              <div className={styles.setting_upload01__thumbnails}>
                <img className={styles.setting_upload01__thumbnail} src="/assets/img/group/mypage/event/pic_thumbnail01.jpg" />
                <img className={styles.setting_upload01__thumbnail} src="/assets/img/group/mypage/event/pic_thumbnail02.jpg" />
                <img className={styles.setting_upload01__thumbnail} src="/assets/img/group/mypage/event/pic_thumbnail03.jpg" />
                <img className={styles.setting_upload01__thumbnail} src="/assets/img/group/mypage/event/pic_thumbnail04.jpg" />
                <img className={styles.setting_upload01__thumbnail} src="/assets/img/group/mypage/event/pic_thumbnail05.jpg" />
                <img className={styles.setting_upload01__thumbnail} src="/assets/img/group/mypage/event/pic_thumbnail06.jpg" />
              </div>
            </div>
            <div className={styles.setting_user}>
              <p className={styles.setting_user__title}>イベント申し込みしたユーザー</p>
              <ul className={styles.setting_user__list}>
                <li className={styles.setting_user__item}>
                  <p className={styles.setting_user__name}>佐野貴久</p>
                  <p className={styles.setting_user__date}>登録日：2023.12.11</p>
                </li>
                <li className={styles.setting_user__item}>
                  <p className={styles.setting_user__name}>山田太郎</p>
                  <p className={styles.setting_user__date}>登録日：2023.12.11</p>
                </li>
                <li className={styles.setting_user__item}>
                  <p className={styles.setting_user__name}>鈴木花孑</p>
                  <p className={styles.setting_user__date}>登録日：2023.12.11</p>
                </li>
                <li className={styles.setting_user__item}>
                  <p className={styles.setting_user__name}>佐野貴久</p>
                  <p className={styles.setting_user__date}>登録日：2023.12.11</p>
                </li>
                <li className={styles.setting_user__item}>
                  <p className={styles.setting_user__name}>山田太郎</p>
                  <p className={styles.setting_user__date}>登録日：2023.12.11</p>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.setting_submit}>
            <div className={styles.setting_submit__inner}>
              <p className={styles.setting_submit__title}>保存</p>
              <div className={styles.setting_submit__btns}>
                <button className={styles.setting_submit__btn} type="submit">
                  保存
                </button>
                <button className={`${styles.setting_submit__btn} ${styles['setting_submit__btn--delete']}`} type="submit">
                  削除
                </button>
              </div>
              <button className={`${styles.setting_submit__btn} ${styles['setting_submit__btn--proceed']}`} type="submit">
                入稿審査へ進む
              </button>
              <button className={`${styles.setting_submit__btn} ${styles['setting_submit__btn--wait']}`} type="submit">
                審査中
              </button>
            </div>
            <p className={styles.setting_submit__note}>※外部フォームでの受付は、当サイトでの申込フォームでは受付が困難である場合に限りご利用ください。外部フォームでの受付を行った場合、ユーザのイベント参加実績をローカルブリッジ上で残せません（マイページへ反映されません）</p>
          </div>
        </form>
      </div>
    </MypageContents>
  );
};

export default OrganizationList;
