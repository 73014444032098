import React from 'react';
import { useParams } from 'react-router-dom'; // React RouterからuseParamsをインポート
import styles from './detail.module.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const AdminOrganizationDetail = () => {
  const { id } = useParams(); // React RouterのuseParamsを使用してidを取得

  // 通常はこのIDを使ってAPIから詳細データを取得します。
  // 今回はmockListdataから詳細データを取得しています。
  const mockListdata = [
    {
      id: 1,
      name: 'ABCDE組織・団体',
      date: '2023.11.12',
    },
    {
      id: 2,
      name: 'ABCDE組織・団体',
      date: '2023.11.13',
    },
    {
      id: 3,
      name: 'ABCDE組織・団体',
      date: '2023.11.14',
    },
    {
      id: 4,
      name: 'ABCDE組織・団体',
      date: '2023.11.15',
    },
    {
      id: 5,
      name: 'ABCDE組織・団体',
      date: '2023.11.12',
    },
    {
      id: 6,
      name: 'ABCDE組織・団体',
      date: '2023.11.13',
    },
    {
      id: 7,
      name: 'ABCDE組織・団体',
      date: '2023.11.14',
    },
    {
      id: 8,
      name: 'ABCDE組織・団体',
      date: '2023.11.15',
    },
    {
      id: 9,
      name: 'ABCDE組織・団体',
      date: '2023.11.12',
    },
    {
      id: 10,
      name: 'ABCDE組織・団体',
      date: '2023.11.13',
    },
    {
      id: 11,
      name: 'ABCDE組織・団体',
      date: '2023.11.14',
    },
    {
      id: 12,
      name: 'ABCDE組織・団体',
      date: '2023.11.15',
    },
  ];

  // const organization = mockListdata.find((org) => org.id === parseInt(id, 10));

  const saveAlert = () => {};

  return (
    <div className={styles.organization_detail}>
      <Form className={styles.organization_detail__form}>
        <div className={styles.organization_detail__head}>
          <h1 className={styles.organization_detail__title}>組織・団体詳細</h1>
          <a href="/organization" className={styles.organization_detail__link}>
            組織・団体一覧へ戻る
          </a>
        </div>
        <div className={styles.organization_detail__contents}>
          <div className={styles.organization_detail__input}>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>組織・団体区分</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>組織・団体区分</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>組織・団体アイコンロゴ登録</h2>
                <div className={styles.detail_section__upload}>
                  <i class="bi bi-upload"></i>
                  <label className={styles.detail_section__label}>
                    <Form.Control type="file" />
                    Select Images to Upload
                  </label>
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>組織・団体HPリンク</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>組織・団体メールアドレス　※</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>組織・団体電話番号</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            {/* <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>パスワード　※</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="password" placeholder="placeholder" />
                </div>
                <Form.Text className={styles.detail_section__note} muted>
                  8～16文字で、半角数字、半角大文字の英字、半角小文字の英字をすべて含めてください。
                </Form.Text>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>確認用パスワード　※</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section> */}
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>代表者名　※</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>ふりがな　※</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>ふるさとパスポート画像登録</h2>
                <div className={styles.detail_section__upload}>
                  <i class="bi bi-upload"></i>
                  <label className={styles.detail_section__label}>
                    <Form.Control type="file" />
                    Select Images to Upload
                  </label>
                </div>
                <Form.Text className={styles.detail_section__note} muted>
                  ※自治体ごとにアップロードしてください
                </Form.Text>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>TOP画像</h2>
                <div className={styles.detail_section__upload}>
                  <i class="bi bi-upload"></i>
                  <label className={styles.detail_section__label}>
                    <Form.Control type="file" />
                    Select Images to Upload
                  </label>
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>PR記事</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control as="textarea" placeholder="Write a message" rows={8} />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>PR記事アップロード</h2>
                <div className={styles.detail_section__upload}>
                  <i class="bi bi-upload"></i>
                  <label className={styles.detail_section__label}>
                    <Form.Control type="file" />
                    Select Images to Upload
                  </label>
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>担当者名</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>担当者メールアドレス</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>担当者電話番号</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>郵便番号</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>都道府県</h2>
                <div className={`${styles.detail_section__input} ${styles['detail_section__input--narrow']}`}>
                  <Form.Select aria-label="Default select example">
                    <option>選択してください</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>番地</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>建物名・部屋番号</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
          </div>
          <div className={styles.detail_side}>
            <section className={styles.detail_section}>
              <h2 className={styles.detail_section__title}>公開</h2>
              <div className={styles.detail_side__inputs}>
                <Button onClick={saveAlert} className={`${styles.detail_side__input} ${styles['detail_side__input--submit']}`} type="submit">
                  公開
                </Button>
                <Button className={`${styles.detail_side__input} ${styles['detail_side__input--delete']}`} type="submit">
                  削除
                </Button>
              </div>
            </section>
            <section className={styles.detail_section}>
              <h2 className={styles.detail_section__title}>パスワード変更</h2>
              <div className={styles.detail_side__inputs}>
                <Button className={`${styles.detail_side__input} ${styles['detail_side__input--submit']}`} type="submit">
                  変更
                </Button>
              </div>
            </section>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AdminOrganizationDetail;
