import AppContext from '../../../context/AppContext';
import React, { useState } from 'react';
import { Sidemenu } from './sidemenu';
import { Save } from './save';
import styles from './index.module.scss';

export default function AdminLayout({ children }) {
  const [background, setBackground] = useState('#fff');
  return (
    <AppContext.Provider value={{ background, setBackground }}>
      <div className={styles.main_contents} style={{ background: background }}>
        <Sidemenu />
        <div className={styles.main_contents__inner}>
          <Save />
          <div className={styles.main_contents__content}>{children}</div>
        </div>
      </div>
    </AppContext.Provider>
  );
}
