import React, { useEffect } from 'react';
import AppContext from '../../../context/AppContext';
import { useParams } from 'react-router-dom'; // React RouterからuseParamsをインポート
import styles from './detail.module.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const AdminBannerDetail = () => {
  const { id } = useParams(); // React RouterのuseParamsを使用してidを取得
  const { setBackground } = React.useContext(AppContext);

  useEffect(() => {
    setBackground('#f8f9fa');
  }, []);
  // 通常はこのIDを使ってAPIから詳細データを取得します。
  // 今回はmockListdataから詳細データを取得しています。
  const mockListdata = [
    {
      id: 1,
      name: 'ABCDE組織・団体',
      date: '2023.11.12',
    },
    {
      id: 2,
      name: 'ABCDE組織・団体',
      date: '2023.11.13',
    },
    {
      id: 3,
      name: 'ABCDE組織・団体',
      date: '2023.11.14',
    },
    {
      id: 4,
      name: 'ABCDE組織・団体',
      date: '2023.11.15',
    },
    {
      id: 5,
      name: 'ABCDE組織・団体',
      date: '2023.11.12',
    },
    {
      id: 6,
      name: 'ABCDE組織・団体',
      date: '2023.11.13',
    },
    {
      id: 7,
      name: 'ABCDE組織・団体',
      date: '2023.11.14',
    },
    {
      id: 8,
      name: 'ABCDE組織・団体',
      date: '2023.11.15',
    },
    {
      id: 9,
      name: 'ABCDE組織・団体',
      date: '2023.11.12',
    },
    {
      id: 10,
      name: 'ABCDE組織・団体',
      date: '2023.11.13',
    },
    {
      id: 11,
      name: 'ABCDE組織・団体',
      date: '2023.11.14',
    },
    {
      id: 12,
      name: 'ABCDE組織・団体',
      date: '2023.11.15',
    },
  ];

  const saveAlert = () => {};

  return (
    <div className={styles.banner_detail}>
      <Form className={styles.banner_detail__form}>
        <div className={styles.banner_detail__head}>
          <h1 className={styles.banner_detail__title}>広告バナー管理</h1>
        </div>
        <div className={styles.banner_detail__contents}>
          <div className={styles.banner_detail__input}>
            <div className={styles.banner_detail__contents_inner}>
              <section className={styles.detail_section}>
                <h2 className={styles.detail_section__title}>
                  <p>バナー１</p>
                  <Form>
                    <Form.Check className={styles.detail_section__checkbox} />
                  </Form>
                </h2>
                <div className={styles.detail_section__input}>
                  <Form.Group>
                    <p className={styles.detail_section__label}>広告主名</p>
                    <Form.Control required type="text" placeholder="placeholder" />
                  </Form.Group>
                </div>
                <div className={`${styles.detail_section__input} ${styles['detail_section__input--start']}`}>
                  <Form.Group>
                    <p className={`${styles.detail_section__label} ${styles['detail_section__label--fixed_width']}`}>バナーアップロード１</p>
                    <div className={styles.detail_section_upload}>
                      <div className={styles.detail_section_upload__inner}>
                        <i class="bi bi-upload"></i>
                        <label className={styles.detail_section_upload__btn}>
                          <Form.Control type="file" />
                          Select Images to Upload
                        </label>
                      </div>
                      <div className={styles.detail_section_upload__img}>
                        <img src="/assets/img/admin/pic_preview_img.jpg" />
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className={`${styles.detail_section__input} ${styles['detail_section__input--mt_wide']}`}>
                  <Form.Group>
                    <p className={`${styles.detail_section__label} ${styles['detail_section__label--fixed_width']}`}>バナー１リンク先</p>
                    <Form.Control required type="text" placeholder="placeholder" />
                  </Form.Group>
                </div>
                <div className={styles.detail_section__input}>
                  <Form.Group>
                    <p className={styles.detail_section__label}>更新日</p>
                    <p className={styles.detail_section__text}>2023.11.12</p>
                  </Form.Group>
                </div>
              </section>
              <section className={styles.detail_section}>
                <h2 className={styles.detail_section__title}>
                  <p>バナー２</p>
                  <Form>
                    <Form.Check className={styles.detail_section__checkbox} />
                  </Form>
                </h2>
                <div className={styles.detail_section__input}>
                  <Form.Group>
                    <p className={styles.detail_section__label}>広告主名</p>
                    <Form.Control required type="text" placeholder="placeholder" />
                  </Form.Group>
                </div>
                <div className={`${styles.detail_section__input} ${styles['detail_section__input--start']}`}>
                  <Form.Group>
                    <p className={`${styles.detail_section__label} ${styles['detail_section__label--fixed_width']}`}>バナーアップロード１</p>
                    <div className={styles.detail_section_upload}>
                      <div className={styles.detail_section_upload__inner}>
                        <i class="bi bi-upload"></i>
                        <label className={styles.detail_section_upload__btn}>
                          <Form.Control type="file" />
                          Select Images to Upload
                        </label>
                      </div>
                      <div className={styles.detail_section_upload__img}>
                        <img src="/assets/img/admin/pic_preview_img.jpg" />
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className={`${styles.detail_section__input} ${styles['detail_section__input--mt_wide']}`}>
                  <Form.Group>
                    <p className={`${styles.detail_section__label} ${styles['detail_section__label--fixed_width']}`}>バナー２リンク先</p>
                    <Form.Control required type="text" placeholder="placeholder" />
                  </Form.Group>
                </div>
                <div className={styles.detail_section__input}>
                  <Form.Group>
                    <p className={styles.detail_section__label}>更新日</p>
                    <p className={styles.detail_section__text}>2023.11.12</p>
                  </Form.Group>
                </div>
              </section>
              <section className={styles.detail_section}>
                <h2 className={styles.detail_section__title}>
                  <p>バナー３</p>
                  <Form>
                    <Form.Check className={styles.detail_section__checkbox} />
                  </Form>
                </h2>
                <div className={styles.detail_section__input}>
                  <Form.Group>
                    <p className={styles.detail_section__label}>広告主名</p>
                    <Form.Control required type="text" placeholder="placeholder" />
                  </Form.Group>
                </div>
                <div className={`${styles.detail_section__input} ${styles['detail_section__input--start']}`}>
                  <Form.Group>
                    <p className={`${styles.detail_section__label} ${styles['detail_section__label--fixed_width']}`}>バナーアップロード１</p>
                    <div className={styles.detail_section_upload}>
                      <div className={styles.detail_section_upload__inner}>
                        <i class="bi bi-upload"></i>
                        <label className={styles.detail_section_upload__btn}>
                          <Form.Control type="file" />
                          Select Images to Upload
                        </label>
                      </div>
                      <div className={styles.detail_section_upload__img}>
                        <img src="/assets/img/admin/pic_preview_img.jpg" />
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className={`${styles.detail_section__input} ${styles['detail_section__input--mt_wide']}`}>
                  <Form.Group>
                    <p className={`${styles.detail_section__label} ${styles['detail_section__label--fixed_width']}`}>バナー３リンク先</p>
                    <Form.Control required type="text" placeholder="placeholder" />
                  </Form.Group>
                </div>
                <div className={styles.detail_section__input}>
                  <Form.Group>
                    <p className={styles.detail_section__label}>更新日</p>
                    <p className={styles.detail_section__text}>2023.11.12</p>
                  </Form.Group>
                </div>
              </section>
              <section className={styles.detail_section}>
                <h2 className={styles.detail_section__title}>
                  <p>バナー４</p>
                  <Form>
                    <Form.Check className={styles.detail_section__checkbox} />
                  </Form>
                </h2>
                <div className={styles.detail_section__input}>
                  <Form.Group>
                    <p className={styles.detail_section__label}>広告主名</p>
                    <Form.Control required type="text" placeholder="placeholder" />
                  </Form.Group>
                </div>
                <div className={`${styles.detail_section__input} ${styles['detail_section__input--start']}`}>
                  <Form.Group>
                    <p className={`${styles.detail_section__label} ${styles['detail_section__label--fixed_width']}`}>バナーアップロード１</p>
                    <div className={styles.detail_section_upload}>
                      <div className={styles.detail_section_upload__inner}>
                        <i class="bi bi-upload"></i>
                        <label className={styles.detail_section_upload__btn}>
                          <Form.Control type="file" />
                          Select Images to Upload
                        </label>
                      </div>
                      <div className={styles.detail_section_upload__img}>
                        <img src="/assets/img/admin/pic_preview_img.jpg" />
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className={`${styles.detail_section__input} ${styles['detail_section__input--mt_wide']}`}>
                  <Form.Group>
                    <p className={`${styles.detail_section__label} ${styles['detail_section__label--fixed_width']}`}>バナー４リンク先</p>
                    <Form.Control required type="text" placeholder="placeholder" />
                  </Form.Group>
                </div>
                <div className={styles.detail_section__input}>
                  <Form.Group>
                    <p className={styles.detail_section__label}>更新日</p>
                    <p className={styles.detail_section__text}>2023.11.12</p>
                  </Form.Group>
                </div>
              </section>
            </div>
          </div>
          <section className={styles.detail_side}>
            <h2 className={styles.detail_side__title}>バナー保存</h2>
            <div className={styles.detail_side__inputs}>
              <Button onClick={saveAlert} className={`${styles.detail_side__input} ${styles['detail_side__input--submit']}`} type="submit">
                公開
              </Button>
              <Button className={`${styles.detail_side__input} ${styles['detail_side__input--delete']}`} type="submit">
                削除
              </Button>
            </div>
          </section>
        </div>
      </Form>
    </div>
  );
};

export default AdminBannerDetail;
