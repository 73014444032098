import React, { useState, useEffect } from 'react';
import styles from './setting.module.scss';
import AppContext from '../../../../context/AppContext';
import MypageContents from '../../../../components/layout/mypage';
import { useLocation } from 'react-router-dom';

const StudentSetting = () => {
  const [logoPreview, setLogoPreview] = useState(null);
  const { setIsMypage, setIsLogin } = React.useContext(AppContext); // 未使用の変数を削除
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const paramBoolean = query.get('alert');

  useEffect(() => {
    setIsMypage(true);
    setIsLogin(true);
  }, [setIsMypage, setIsLogin]);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <MypageContents title="会員情報設定" title_en="Member information settings" currentPage="setting">
      <div className={styles.setting}>
        {paramBoolean && <p className={styles.setting__alert}>記事を保存しました</p>}
        <form className={styles.setting__form}>
          <div className={styles.setting__contents}>
            <p className={styles.setting__note}>※は必須項目です</p>
            <div className={`${styles.setting_item} ${styles['setting_item--logo']}`}>
              <img src={logoPreview || '/assets/img/sign_up/pic_image01.png'} alt="" />
              <label className={styles.setting_logo}>
                <input type="file" name="organization_logo" onChange={handleLogoChange} />
                <p className={styles.setting_logo__text}>画像を変更する</p>
              </label>
            </div>
            <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="organization_name">
                名前<span className={styles['required']}>※</span>
              </label>
              <input className={styles.setting_item__input} type="text" id="organization_name" name="organization_name" placeholder="山田　太郎" />
            </div>
            <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="organization_name">
                ふりがな<span className={styles['required']}>※</span>
              </label>
              <input className={styles.setting_item__input} type="text" id="organization_name" name="organization_name" placeholder="やまだ　たろう" />
            </div>
            <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="organization_website">
                メールアドレス
              </label>
              <input className={styles.setting_item__input} type="text" id="organization_website" name="organization_website" placeholder="abc@sample.com" />
            </div>
            {/* <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="organization_email">
                パスワード
              </label>
              <input className={styles.setting_item__input} type="password" id="organization_email" name="organization_email" placeholder="" />
            </div>
            <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="organization_phone">
                確認用パスワード
              </label>
              <input className={styles.setting_item__input} type="text" id="organization_phone" name="organization_phone" placeholder="" />
            </div> */}
            <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="password">
                ニックネーム<span className={styles['required']}>※</span>
                <span className={styles['note']}>この情報は公開されます</span>
              </label>
              <input className={styles.setting_item__input} type="text" id="password" name="password" placeholder="ローカルブリッジ" />
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-sm']}`}>
              <label className={styles.setting_item__label} htmlFor="gender">
                性別<span className={styles['required']}>※</span>
              </label>
              <div className={styles.setting_item_radio}>
                <div className={styles.setting_item_radio__inner}>
                  <input className={styles.setting_item_radio__btn} type="radio" id="huey" name="drone" value="huey" />
                  <label className={styles.setting_item_radio__text} for="huey">
                    男性
                  </label>
                </div>
                <div className={styles.setting_item_radio__inner}>
                  <input className={styles.setting_item_radio__btn} type="radio" id="dewey" name="drone" value="dewey" />
                  <label className={styles.setting_item_radio__text} for="dewey">
                    女性
                  </label>
                </div>
                <div className={styles.setting_item_radio__inner}>
                  <input className={styles.setting_item_radio__btn} type="radio" id="louie" name="drone" value="louie" />
                  <label className={styles.setting_item_radio__text} for="louie">
                    どちらでもない
                  </label>
                </div>
              </div>
            </div>
            <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="birthdate">
                生年月日
              </label>
              <div className={styles.setting_item__multi_select}>
                <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']} ${styles['setting_item__input--width-sm']}`}>
                  <select name="inquiry_category">
                    <option value="" disabled selected>
                      年
                    </option>
                    <option value="1980年">1980年</option>
                    <option value="1981年">1981年</option>
                    <option value="1982年">1982年</option>
                  </select>
                </div>
                <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']} ${styles['setting_item__input--width-sm']}`}>
                  <select name="inquiry_category">
                    <option value="" disabled selected>
                      月
                    </option>
                    <option value="1月">1月</option>
                    <option value="2月">2月</option>
                    <option value="3月">3月</option>
                  </select>
                </div>
                <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']} ${styles['setting_item__input--width-sm']}`}>
                  <select name="inquiry_category">
                    <option value="" disabled selected>
                      日
                    </option>
                    <option value="1日">1日</option>
                    <option value="2日">2日</option>
                    <option value="3日">3日</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-xs']}`}>
              <label className={styles.setting_item__label} htmlFor="postal_code">
                郵便番号
              </label>
              <input className={styles.setting_item__input} type="text" name="postal_code" minlength="7" maxlength="8" pattern="\d*" autocomplete="shipping postal-code" placeholder="123-4567" />
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-xs']}`}>
              <label className={styles.setting_item__label} htmlFor="prefecture">
                都道府県
              </label>
              <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']}`}>
                <select name="prefecture" autocomplete="shipping address-level1">
                  <option value="" disabled selected>
                    選択してください
                  </option>
                  <option value="北海道">北海道</option>
                  <option value="東京">東京</option>
                  <option value="京都">京都</option>
                  <option value="沖縄">沖縄</option>
                </select>
              </div>
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-md']}`}>
              <label className={styles.setting_item__label} htmlFor="city">
                市区町村
              </label>
              <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']}`}>
                <select name="prefecture" autocomplete="shipping address-level1">
                  <option value="" disabled selected>
                    選択してください
                  </option>
                  <option value="北海道">北海道</option>
                  <option value="東京">東京</option>
                  <option value="京都">京都</option>
                  <option value="沖縄">沖縄</option>
                </select>
              </div>
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-md']}`}>
              <label className={styles.setting_item__label} htmlFor="address">
                番地
              </label>
              <input className={styles.setting_item__input} placeholder="1-2-3" type="text" name="address" autocomplete="shipping address-line1" />
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-md']}`}>
              <label className={styles.setting_item__label} htmlFor="building">
                建物名・部屋番号
              </label>
              <input className={styles.setting_item__input} placeholder="JMFビル北浜 01" type="text" name="building" autocomplete="shipping address-line2" />
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-md']}`}>
              <label className={styles.setting_item__label} htmlFor="occupation">
                職業<span className={styles['required']}>※</span>
              </label>
              <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']}`}>
                <select name="occupation" autocomplete="shipping address-level1">
                  <option value="" disabled selected>
                    選択してください
                  </option>
                  <option value="test01">test01</option>
                  <option value="test02">test02</option>
                  <option value="test03">test03</option>
                  <option value="test04">test04</option>
                </select>
              </div>
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-sm']}`}>
              <label className={styles.setting_item__label} htmlFor="school_name">
                学校名
              </label>
              <input className={styles.setting_item__input} placeholder="〇〇大学" type="text" name="school_name" />
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-sm']}`}>
              <label className={styles.setting_item__label} htmlFor="department">
                学部名
              </label>
              <input className={styles.setting_item__input} placeholder="〇〇学部" type="text" name="department" />
            </div>
            <div className={`${styles.setting_item} ${styles['setting_item--width-sm']}`}>
              <label className={styles.setting_item__label} htmlFor="grade">
                学年<span className={styles['note']}>この情報は公開されます</span>
              </label>
              <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']}`}>
                <select name="occupation" autocomplete="shipping address-level1">
                  <option value="" disabled selected>
                    選択してください
                  </option>
                  <option value="test01">test01</option>
                  <option value="test02">test02</option>
                  <option value="test03">test03</option>
                  <option value="test04">test04</option>
                </select>
              </div>
            </div>
            <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="admission">
                入学年月
              </label>
              <div className={styles.setting_item__multi_select}>
                <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']} ${styles['setting_item__input--width-sm']}`}>
                  <select name="admission">
                    <option value="" disabled selected>
                      年
                    </option>
                    <option value="1980年">1980年</option>
                    <option value="1981年">1981年</option>
                    <option value="1982年">1982年</option>
                  </select>
                </div>
                <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']} ${styles['setting_item__input--width-sm']}`}>
                  <select name="admission">
                    <option value="" disabled selected>
                      年
                    </option>
                    <option value="1980年">1980年</option>
                    <option value="1981年">1981年</option>
                    <option value="1982年">1982年</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="interest">
                興味・関心<span className={styles['required']}>※</span>
                <span className={styles['note']}>この情報は公開されます</span>
              </label>
              <div className={styles.setting_item_choice}>
                <p className={styles.setting_item_choice__text}>
                  ＃ ダミーテキスト<span></span>
                </p>
                <p className={styles.setting_item_choice__text}>
                  ＃ ダミーテキスト<span></span>
                </p>
              </div>
              <input className={styles.setting_item__input} placeholder="入力してください" type="text" name="interest" />
            </div>
            <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="hometown">
                私の気になる第2のふるさと<span className={styles['note']}>この情報は公開されます</span>
              </label>
              <div className={styles.setting_item__multi_select}>
                <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']} ${styles['setting_item__input--width-md']}`}>
                  <select name="hometown01" autocomplete="shipping address-level1">
                    <option value="" disabled selected>
                      都道府県
                    </option>
                    <option value="北海道">北海道</option>
                    <option value="東京">東京</option>
                    <option value="京都">京都</option>
                    <option value="沖縄">沖縄</option>
                  </select>
                </div>
                <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']} ${styles['setting_item__input--width-md']}`}>
                  <select name="hometown02" autocomplete="shipping address-level1">
                    <option value="" disabled selected>
                      市区町村
                    </option>
                    <option value="北海道">北海道</option>
                    <option value="東京">東京</option>
                    <option value="京都">京都</option>
                    <option value="沖縄">沖縄</option>
                  </select>
                </div>
                <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']} ${styles['setting_item__input--width-md']}`}>
                  <select name="hometown03" autocomplete="shipping address-level1">
                    <option value="" disabled selected>
                      都道府県
                    </option>
                    <option value="北海道">北海道</option>
                    <option value="東京">東京</option>
                    <option value="京都">京都</option>
                    <option value="沖縄">沖縄</option>
                  </select>
                </div>
                <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']} ${styles['setting_item__input--width-md']}`}>
                  <select name="hometown04" autocomplete="shipping address-level1">
                    <option value="" disabled selected>
                      市区町村
                    </option>
                    <option value="北海道">北海道</option>
                    <option value="東京">東京</option>
                    <option value="京都">京都</option>
                    <option value="沖縄">沖縄</option>
                  </select>
                </div>
                <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']} ${styles['setting_item__input--width-md']}`}>
                  <select name="hometown05" autocomplete="shipping address-level1">
                    <option value="" disabled selected>
                      都道府県
                    </option>
                    <option value="北海道">北海道</option>
                    <option value="東京">東京</option>
                    <option value="京都">京都</option>
                    <option value="沖縄">沖縄</option>
                  </select>
                </div>
                <div className={`${styles.setting_item__input} ${styles['setting_item__input--select']} ${styles['setting_item__input--width-md']}`}>
                  <select name="hometown06" autocomplete="shipping address-level1">
                    <option value="" disabled selected>
                      市区町村
                    </option>
                    <option value="北海道">北海道</option>
                    <option value="東京">東京</option>
                    <option value="京都">京都</option>
                    <option value="沖縄">沖縄</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="grade">
                気になる地方創生カテゴリ<span className={styles['note']}>この情報は公開されます</span>
              </label>
              <div className={styles.setting_item_checkbox}>
                <div className={styles.setting_item_checkbox__item}>
                  <input className={styles.setting_item_checkbox__btn} id="choice01" type="checkbox" />
                  <label className={styles.setting_item_checkbox__text} for="choice01">
                    観光・伝統文化
                  </label>
                </div>
                <div className={styles.setting_item_checkbox__item}>
                  <input className={styles.setting_item_checkbox__btn} id="choice02" type="checkbox" />
                  <label className={styles.setting_item_checkbox__text} for="choice02">
                    関係人口づくり
                  </label>
                </div>
                <div className={styles.setting_item_checkbox__item}>
                  <input className={styles.setting_item_checkbox__btn} id="choice03" type="checkbox" />
                  <label className={styles.setting_item_checkbox__text} for="choice03">
                    地域の魅力発信
                  </label>
                </div>
                <div className={styles.setting_item_checkbox__item}>
                  <input className={styles.setting_item_checkbox__btn} id="choice04" type="checkbox" />
                  <label className={styles.setting_item_checkbox__text} for="choice04">
                    サスティナブル
                  </label>
                </div>
                <div className={styles.setting_item_checkbox__item}>
                  <input className={styles.setting_item_checkbox__btn} id="choice05" type="checkbox" />
                  <label className={styles.setting_item_checkbox__text} for="choice05">
                    子供の教育
                  </label>
                </div>
                <div className={styles.setting_item_checkbox__item}>
                  <input className={styles.setting_item_checkbox__btn} id="choice06" type="checkbox" />
                  <label className={styles.setting_item_checkbox__text} for="choice06">
                    家族・家庭
                  </label>
                </div>
                <div className={styles.setting_item_checkbox__item}>
                  <input className={styles.setting_item_checkbox__btn} id="choice07" type="checkbox" />
                  <label className={styles.setting_item_checkbox__text} for="choice07">
                    まちづくり
                  </label>
                </div>
                <div className={styles.setting_item_checkbox__item}>
                  <input className={styles.setting_item_checkbox__btn} id="choice08" type="checkbox" />
                  <label className={styles.setting_item_checkbox__text} for="choice08">
                    文化・芸術
                  </label>
                </div>
                <div className={`${styles.setting_item_checkbox__item} ${styles['setting_item_checkbox__item--wrap']}`}>
                  <input className={styles.setting_item_checkbox__btn} id="choice11" type="checkbox" />
                  <label className={styles.setting_item_checkbox__text} for="choice11">
                    その他
                  </label>
                  <input className={`${styles.setting_item__input} ${styles['setting_item__input--checkbox']}`} id="text" type="text" name="other" value="" placeholder="入力してください"></input>
                </div>
              </div>
            </div>
            <div className={styles.setting_item}>
              <label className={styles.setting_item__label} htmlFor="introduction">
                自己紹介文<span className={styles['note']}>この情報は公開されます</span>
              </label>
              <textarea className={`${styles.setting_item__input} ${styles['setting_item__input--textarea']}`} placeholder="入力してください" type="text" name="introduction" />
            </div>
          </div>
          <div className={styles.setting_side}>
            <div className={styles.setting_side__submit}>
              <p className={styles.setting_side__title}>編集・保存</p>
              <button className={styles.setting_side__btn} type="submit">
                保存
              </button>
            </div>
            <div className={styles.setting_side__submit}>
              <p className={styles.setting_side__title}>パスワード変更</p>
              <button className={styles.setting_side__btn} type="submit">
                変更
              </button>
            </div>
          </div>
        </form>
      </div>
    </MypageContents>
  );
};

export default StudentSetting;
