import React from 'react';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';
import styles from './passport.module.scss';

const AboutPassport = () => {
  const AboutNav = () => {
    return (
      <ul className={styles.nav}>
        <li className={styles.nav__item}>
          <a href="/about/first">はじめての方へ</a>
        </li>
        <li className={`${styles.nav__item} ${styles['active']}`}>
          <a href="/about/passport">ふるさとパスポートとは？</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/contribute">貢献度バッジとは？</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/article">イベント体験記事・魅力発見記事について</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/community">コミュニティについて</a>
        </li>
      </ul>
    );
  };
  return (
    <>
      <SubpageMainvisual title="ふるさとパスポートとは？" enTitle="About Furusato Passport" />
      <SubpageContents bgColor={'#fdfaf1'}>
        <AboutNav />
        <section className={styles.passport}>
          <div className={styles.passport__inner}>
            <h2 className={styles.passport__title}>
              ふるさとパスポートは、あなたがその地域に繋がりを持ち、
              <br className="is-pc" />
              「地方創生」に関わる活動へ参加したことを証明するデジタルパスポートです
            </h2>
            <p className={styles.passport__lead}>
              Local Bridge内のイベントへの参加や体験した内容や街の魅力を記事として執筆することで、その地域の「ふるさとパスポート」を取得することができます。
              <br />
              また、ふるさとパスポートに紐づく貢献度バッジを集めることで、ふるさとパスポートが進化し、「交流人口」から「関係人口」へと自治体からも関係性の深い人であると認識され、特別なイベントに参加できるようになったり、あなたの力を必要とする人からオファーが届くなど、地域とのつながりを深めていくことができます。 あなたが日本各地で取り組んだ地域活性化の活動への貢献度がひと目でわかる勲章として、生涯に渡って証明し続けられる。これが「ふるさとパスポート」です。
            </p>
            <div className={styles.passport__img}>
              <picture>
                <source media="(max-width: 769px)" srcset="/assets/img/about/passport/pic_img01_sp.jpg" />
                <img src="/assets/img/about/passport/pic_img01.png" alt="" />
              </picture>
            </div>
            <section className={styles.passport_badge}>
              <div className={styles.passport_badge__contents}>
                <div className={styles.passport_badge__texts}>
                  <h3 className={styles.passport_badge__title}>ふるさとパスポートの特徴</h3>
                  <p className={styles.passport_badge__text}>
                    ふるさとパスポートは、Local Bridgeに参画する日本全国の都道府県、市区町村が発行することができるNFTという技術を活用したデジタルパスポートです。
                    <br />
                    ふるさとパスポートは、体験したことを生涯に渡り証明するための売買を目的としない新しいNFTの活用法を取り入れており、自分自身が持ち続ける事で価値を発揮します。
                  </p>
                </div>
                <div className={styles.passport_badge__img}>
                  <img src="/assets/img/about/passport/pic_img03.jpg" alt="" />
                </div>
              </div>
            </section>
            <section className={styles.passport_badge}>
              <div className={`${styles.passport_badge__contents} ${styles['passport_badge__contents--reverse']}`}>
                <div className={styles.passport_badge__texts}>
                  <h3 className={styles.passport_badge__title}>取得方法</h3>
                  <p className={styles.passport_badge__text}>
                    ふるさとパスポートは、Local Bridge内のイベント・ワークショップに参加することで取得できます。
                    <br />
                    ふるさとパスポートが取得できるのは初回の1回のみで、以降は貢献度バッジがイベント・ワークショップごとに発行され溜まっていきます。
                    <br />
                    自分の出身地や居住地だけでなく、興味・関心のある地域、関わり合いのある地域のパスポートをぜひ積極的に手に入れましょう。
                  </p>
                </div>
                <div className={styles.passport_badge__img}>
                  <img src="/assets/img/about/passport/pic_img04.jpg" alt="" />
                </div>
              </div>
            </section>
            <section className={styles.passport_badge}>
              <div className={styles.passport_badge__contents}>
                <div className={styles.passport_badge__texts}>
                  <h3 className={styles.passport_badge__title}>サイトでの表示</h3>
                  <p className={styles.passport_badge__text}>
                    手に入れたふるさとパスポートは、あなたのLocal Bridgeサイトでのアイコンとなります。このアイコンは、他の人があなたのマイページを訪れた時に表示されたり、あなたが執筆した記事にも表示されます。
                    <br />
                    ふるさとパスポートは複数の地域のものを持つことができます。もし複数の地域のふるさとパスポートを持っている場合、どの地域のものをアイコンにするか、自由に選ぶ事ができます。ふるさとパスポートのデザインは地域によって異なるので、各地域の特徴を楽しむことができます。
                    <br />
                    自分のお気に入りの地域のふるさとパスポートを手に入れ進化させて、仲間に自慢しましょう！
                  </p>
                </div>
                <div className={styles.passport_badge__img}>
                  <img src="/assets/img/about/passport/pic_img02.png" alt="" />
                </div>
              </div>
            </section>
            <div className={styles.passport_group}>
              <p className={styles.passport_group__text}>自分のお気に入りの地域のふるさとパスポートを手に入れ進化させて、仲間に自慢しましょう！</p>
              <div className={styles.passport_group__btn}>
                <a href="/event">ふるさとパスポートを取得する</a>
              </div>
            </div>
          </div>
        </section>
      </SubpageContents>
    </>
  );
};

export default AboutPassport;
