import React, { useEffect } from 'react';
import styles from './confirm.module.scss';
import AppContext from '../../../../context/AppContext';
import MypageContents from '../../../../components/layout/mypage';
import { useLocation } from 'react-router-dom';

const StudentSettingConfirm = () => {
  const { setIsMypage, setIsLogin } = React.useContext(AppContext); // 未使用の変数を削除
  //検証用にパラメーターを取得
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const paramBoolean = query.get('alert');

  useEffect(() => {
    setIsMypage(true);
    setIsLogin(true);
  }, []);

  return (
    <MypageContents title="会員情報設定" title_en="Member information settings" currentPage="setting">
      <div className={styles.confirm}>
        {paramBoolean && <p className={styles.confirm__alert}>記事を保存しました</p>}
        <form className={styles.confirm__form}>
          <div className={styles.confirm__contents}>
            <img className={styles.confirm_item__icon} src="/assets/img/sign_up/pic_image02.png" />
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>名前</p>
              <p className={styles.confirm_item__value}>山田　太郎</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>ふりがな</p>
              <p className={styles.confirm_item__value}>やまだ たろう</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>メールアドレス</p>
              <p className={styles.confirm_item__value}>abc@sample.com</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                ニックネーム<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <p className={styles.confirm_item__value}>タロー</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>性別</p>
              <p className={styles.confirm_item__value}>男性</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>生年月日</p>
              <p className={styles.confirm_item__value}>1990年1月1日</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>郵便番号</p>
              <p className={styles.confirm_item__value}>〒 123-4567</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>都道府県市区町村</p>
              <p className={styles.confirm_item__value}>◯◯県 ✕✕市</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>番地</p>
              <p className={styles.confirm_item__value}>0−0−0</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>建物名・部屋番号</p>
              <p className={styles.confirm_item__value}>000</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>職業</p>
              <p className={styles.confirm_item__value}>会社員</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>学校名</p>
              <p className={styles.confirm_item__value}>〇〇大学</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>学部名</p>
              <p className={styles.confirm_item__value}>〇〇学部</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                学年<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <p className={styles.confirm_item__value}>1学年</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>入学年月</p>
              <p className={styles.confirm_item__value}>1990年1月</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                興味・関心<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <div className={styles.confirm_item__choices}>
                <p className={styles.confirm_item__choice}>＃ダミーテキスト</p>
                <p className={styles.confirm_item__choice}>＃ダミーテキスト</p>
              </div>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                私の気になる第2のふるさと<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <p className={styles.confirm_item__value}>見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                気になる地方創生カテゴリ<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <p className={styles.confirm_item__value}>地域の応援になる</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>自己紹介文</p>
              <p className={styles.confirm_item__value}>見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです</p>
            </div>
          </div>
          <div className={styles.confirm_submit}>
            <p className={styles.confirm_submit__title}>編集・保存</p>
            <button className={styles.confirm_submit__btn} type="submit">
              編集
            </button>
          </div>
        </form>
      </div>
    </MypageContents>
  );
};

export default StudentSettingConfirm;
