import React from 'react';
import styles from './confirm.module.scss';
import SubpageContents from '../../../components/layout/subpage_contents';

const StudentConfirm = () => {
  return (
    <>
      <SubpageContents bgColor={'#fdfaf1'}>
        <div className={styles.confirm}>
          <h1 className={styles.confirm__title}>新規会員登録</h1>
          <p className={styles.confirm__title_en}>Sign up</p>
          <p className={styles.confirm__lead}>
            ご入力内容にお間違いがないことを確認の上、
            <br className={styles['is-sp']} />
            「送信」してください
          </p>
          <div className={styles.confirm_contents}>
            <div className={styles.confirm_contents__inner}>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>名前</p>
                <p className={styles.confirm_contents__value}>山田　太郎</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>ふりがな</p>
                <p className={styles.confirm_contents__value}>やまだ　たろう</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>
                  ニックネーム<span>この情報は公開されます</span>
                </p>
                <p className={styles.confirm_contents__value}>タロー</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>性別</p>
                <p className={styles.confirm_contents__value}>男性</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>生年月日</p>
                <p className={styles.confirm_contents__value}>1990年1月1日</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>郵便番号</p>
                <p className={styles.confirm_contents__value}>〒 123-4567</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>都道府県市区町村</p>
                <p className={styles.confirm_contents__value}>◯◯県 ✕✕市</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>番地</p>
                <p className={styles.confirm_contents__value}>0-0-0</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>建物名・部屋番号</p>
                <p className={styles.confirm_contents__value}>000</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>職業</p>
                <p className={styles.confirm_contents__value}>会社員</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>学校名</p>
                <p className={styles.confirm_contents__value}>〇〇大学</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>学部名</p>
                <p className={styles.confirm_contents__value}>〇〇学部</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>
                  学年<span>この情報は公開されます</span>
                </p>
                <p className={styles.confirm_contents__value}>1学年</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>入学年月</p>
                <p className={styles.confirm_contents__value}>1990年1月</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>
                  興味・関心<span>この情報は公開されます</span>
                </p>
                <div className={styles.confirm_contents__choices}>
                  <p className={styles.confirm_contents__choice}>＃ダミーテキスト</p>
                  <p className={styles.confirm_contents__choice}>＃ダミーテキスト</p>
                </div>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>
                  私の気になる第2のふるさと<span>この情報は公開されます</span>
                </p>
                <p className={styles.confirm_contents__value}>見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>
                  気になる地方創生カテゴリ<span>この情報は公開されます</span>
                </p>
                <p className={styles.confirm_contents__value}>地域の応援になる</p>
              </div>
              <div className={styles.confirm_contents__item}>
                <p className={styles.confirm_contents__label}>自己紹介文</p>
                <p className={styles.confirm_contents__value}>見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです</p>
              </div>
            </div>
            <div className={styles.confirm_contents__btns}>
              <input className={`${styles.confirm_contents__btn} ${styles['confirm_contents__btn--submit']}`} type="submit" value="登録" />
              <a href="/application" className={`${styles.confirm_contents__btn} ${styles[`confirm_contents__btn--back`]}`}>
                戻る
              </a>
            </div>
          </div>
        </div>
      </SubpageContents>
    </>
  );
};

export default StudentConfirm;
