import React, { useState } from 'react';
import styles from './detail.module.scss';
import SubpageContents from '../../components/layout/subpage_contents';
import { useParams } from 'react-router-dom';

const NewsDetail = () => {
  const mockNewsData = [
    {
      id: 1,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A'],
    },
    {
      id: 2,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A', 'イベント体験記事B', 'イベント体験記事C'],
    },
    {
      id: 3,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A', 'イベント体験記事B', 'イベント体験記事C'],
    },
    {
      id: 4,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A', 'イベント体験記事B'],
    },
    {
      id: 5,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A'],
    },
    {
      id: 6,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A', 'イベント体験記事B', 'イベント体験記事C'],
    },
    {
      id: 7,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A', 'イベント体験記事B', 'イベント体験記事C'],
    },
    {
      id: 8,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A', 'イベント体験記事B'],
    },
  ];
  const { id } = useParams();
  const newsData = mockNewsData.find((news) => news.id === parseInt(id, 10));
  const uniqueTags = Array.from(new Set(mockNewsData.flatMap((newsData) => newsData.tag)));
  const NewsListTag = ({ tags }) => {
    const [activeTag, setActiveTag] = useState(null);
    const handleClick = (index) => {
      if (activeTag === index) {
        setActiveTag(null); // すでにアクティブなタグをクリックした場合は、アクティブ状態を解除
      } else {
        setActiveTag(index); // 新しくアクティブにするタグのインデックスを設定
      }
    };
    return (
      <>
        {tags.map((tag, index) => {
          const isActive = activeTag === index;
          const textColor = isActive ? '#13824E' : '#afafaf'; // 例として、アクティブなら赤色、それ以外は黒色
          const iconSrc = isActive ? '/assets/img/common/ico_tag.svg' : '/assets/img/common/ico_tag_gray.svg'; // アクティブなタグの画像を変更
          return (
            <li key={index} className={styles.news_tag__item} onClick={() => handleClick(index)}>
              <a href="#">
                <img className={styles.news_tag__ico} src={iconSrc} alt="" />
                <p style={{ color: textColor }} className={styles.news_tag__text}>
                  {tag}
                </p>
              </a>
            </li>
          );
        })}
      </>
    );
  };

  if (!newsData) {
    return <div>ニュースが見つかりません。</div>;
  }

  return (
    <>
      <SubpageContents bgColor={'#fdfaf1'}>
        <div className={styles.news_detail}>
          <div className={styles.news_detail__container}>
            <div className={styles.news_detail__contents}>
              <p className={styles.news_detail__date}>{newsData.date}</p>
              <h1 className={styles.news_detail__title}>{newsData.title}</h1>
              <div className={styles.news_detail__texts}>
                <p className={styles.news_detail__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                <p className={styles.news_detail__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                <p className={styles.news_detail__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
              </div>
              <div className={styles.news_detail_tag}>
                {newsData.tag.map((tag, index) => {
                  return (
                    <div className={styles.news_detail_tag__item}>
                      <img className={styles.news_detail_tag__ico} src="/assets/img/common/ico_tag.svg" alt="" />
                      <p key={index} className={styles.news_detail_tag__text}>
                        {tag}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <a href="/news" className={`${styles.news_detail__back} ${styles.news_detail__back__is_pc}`}>
              おしらせ一覧へ戻る
            </a>
          </div>
          <div className={styles.news_tag}>
            <p className={styles.news_tag__title}>タグ一覧</p>
            <ul className={styles.news_tag__list}>
              {uniqueTags.map((tag, index) => {
                return <NewsListTag key={index} tags={[tag]} />;
              })}
            </ul>
          </div>
          <a href="/news" className={`${styles.news_detail__back} ${styles.news_detail__back__is_sp}`}>
            おしらせ一覧へ戻る
          </a>
        </div>
      </SubpageContents>
    </>
  );
};

export default NewsDetail;
