import React, { useEffect } from 'react';
import styles from './confirm.module.scss';
import AppContext from '../../../../context/AppContext';
import MypageContents from '../../../../components/layout/mypage';
import { useLocation } from 'react-router-dom';

const GroupProfileConfirm = () => {
  const { setIsMypage, setIsLogin } = React.useContext(AppContext); // 未使用の変数を削除

  useEffect(() => {
    setIsMypage(true);
    setIsLogin(true);
  }, []);

  return (
    <MypageContents title="組織・団体会員情報設定" title_en="Membership informations" currentPage="setting">
      <div className={styles.confirm}>
        <form className={styles.confirm__form}>
          <div className={styles.confirm__contents}>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>組織・団体区分</p>
              <p className={styles.confirm_item__value}>山田 太郎</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                組織・団体名<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <p className={styles.confirm_item__value}>やまだ たろう</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                組織・団体アイコンロゴ登録<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <img className={styles.confirm_item__icon} src="/assets/img/sign_up/pic_image02.png" />
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                組織・団体HPリンク<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <p className={styles.confirm_item__value}>https://wwww</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>組織・団体メールアドレス</p>
              <p className={styles.confirm_item__value}>abc@sample.com</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>組織・団体電話番号</p>
              <p className={styles.confirm_item__value}>000-0000-0000</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>代表者名</p>
              <p className={styles.confirm_item__value}>やまだ たろう</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>ふりがな</p>
              <p className={styles.confirm_item__value}>やまだ たろう</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                TOP画像<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <img className={styles.confirm_item__image} src="/assets/img/sign_up/pic_image03.jpg" />
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                PR記事<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <p className={styles.confirm_item__value}>この文寧はダミーですこの文章はダミーですこの文章はダミーですこの 文章はダミーですこの文章はダミーですこの文章はダミーですこの文章 はダミーですこの文京はダミーですこの文意はダミーですこの文章はダ ミーです</p>
              <img className={styles.confirm_item__image} src="/assets/img/sign_up/pic_image03.jpg" />
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>担当者名</p>
              <p className={styles.confirm_item__value}>たなか じろう</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>担当者メールアドレス</p>
              <p className={styles.confirm_item__value}>abc@sample.com</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>担当者電話番号</p>
              <p className={styles.confirm_item__value}>000-0000-0000</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>郵便番号</p>
              <p className={styles.confirm_item__value}>〒 123-4567</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>都道府県市区町村</p>
              <p className={styles.confirm_item__value}>◯◯県 ✕✕市</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>番地</p>
              <p className={styles.confirm_item__value}>0−0−0</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>建物名・部屋番号</p>
              <p className={styles.confirm_item__value}>000</p>
            </div>
          </div>
          <div className={styles.confirm_submit}>
            <p className={styles.confirm_submit__title}>編集・保存</p>
            <button className={styles.confirm_submit__btn} type="submit">
              編集
            </button>
          </div>
        </form>
      </div>
    </MypageContents>
  );
};

export default GroupProfileConfirm;
