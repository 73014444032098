import React from 'react';
import styles from './complete.module.scss';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';

const ContactComplete = () => {
  return (
    <>
      <SubpageMainvisual title="お問い合わせ" enTitle="Contact" />
      <SubpageContents bgColor={'#fdfaf1'}>
        <section className={styles.complete}>
          <div className={styles.complete__inner}>
            <h2 className={styles.complete__title}>お問い合わせが完了しました</h2>
            <p className={styles.complete__text}>
              お問い合わせいただきありがとうございます。
              <br />
              3営業日以内に担当者よりご連絡いたしますので、今しばらくお待ちいただけますと幸いです。
            </p>
            <div className={styles.complete__back}>
              <a href="/">TOPページに戻る</a>
            </div>
          </div>
        </section>
      </SubpageContents>
    </>
  );
};

export default ContactComplete;
