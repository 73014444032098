import React, { useState } from 'react';
import styles from './news.module.scss';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';
import Pagination from '../../components/pagination';

const NewsList = () => {
  const mockNewsData = [
    {
      id: 1,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A'],
    },
    {
      id: 2,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A', 'イベント体験記事B', 'イベント体験記事C'],
    },
    {
      id: 3,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A', 'イベント体験記事B', 'イベント体験記事C'],
    },
    {
      id: 4,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A', 'イベント体験記事B'],
    },
    {
      id: 5,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A'],
    },
    {
      id: 6,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A', 'イベント体験記事B', 'イベント体験記事C'],
    },
    {
      id: 7,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A', 'イベント体験記事B', 'イベント体験記事C'],
    },
    {
      id: 8,
      date: '2023.07.27',
      title: 'この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。',
      tag: ['イベント体験記事A', 'イベント体験記事B'],
    },
  ];

  const childStyle = {
    backgroundColor: 'transparent',
  };

  const uniqueTags = Array.from(new Set(mockNewsData.flatMap((newsData) => newsData.tag)));

  const NewsItem = ({ newsData }) => {
    return (
      <div className={styles.news_item}>
        <a href={`/news/${newsData.id}`} className={styles.news_item__link}>
          <div className={styles.news_item__head}>
            <p className={styles.news_item__date}>{newsData.date}</p>
            <div className={`${styles.news_item_tag} ${styles.news_item_tag__is_sp}`}>
              <img className={styles.news_item_tag__ico} src="/assets/img/common/ico_tag.svg" alt="" />
              {newsData.tag.map((tag, index) => {
                return (
                  <p key={index} className={styles.news_item_tag__text}>
                    {tag}
                  </p>
                );
              })}
            </div>
          </div>
          <div className={styles.news_item__contents}>
            <div className={`${styles.news_item_tag} ${styles.news_item_tag__is_pc}`}>
              <img className={styles.news_item_tag__ico} src="/assets/img/common/ico_tag.svg" alt="" />
              {newsData.tag.map((tag, index) => {
                return (
                  <p key={index} className={styles.news_item_tag__text}>
                    {tag}
                  </p>
                );
              })}
            </div>
            <div className={styles.news_item__title}>{newsData.title}</div>
          </div>
        </a>
      </div>
    );
  };
  const NewsListTag = ({ tags }) => {
    const [activeTag, setActiveTag] = useState(null);
    const handleClick = (index) => {
      if (activeTag === index) {
        setActiveTag(null); // すでにアクティブなタグをクリックした場合は、アクティブ状態を解除
      } else {
        setActiveTag(index); // 新しくアクティブにするタグのインデックスを設定
      }
    };
    return (
      <>
        {tags.map((tag, index) => {
          const isActive = activeTag === index;
          const textColor = isActive ? '#13824E' : '#afafaf'; // 例として、アクティブなら赤色、それ以外は黒色
          const iconSrc = isActive ? '/assets/img/common/ico_tag.svg' : '/assets/img/common/ico_tag_gray.svg'; // アクティブなタグの画像を変更
          return (
            <li key={index} className={styles.news_tag__item} onClick={() => handleClick(index)}>
              <a href="#">
                <img className={styles.news_tag__ico} src={iconSrc} alt="" />
                <p style={{ color: textColor }} className={styles.news_tag__text}>
                  {tag}
                </p>
              </a>
            </li>
          );
        })}
      </>
    );
  };
  return (
    <>
      <SubpageMainvisual title="お知らせ一覧" enTitle="News" />
      <SubpageContents bgColor={'#fdfaf1'}>
        <div className={styles.news_list}>
          <div className={styles.news_list__inner}>
            <div className={styles.news_list__contents}>
              {mockNewsData.map((newsData) => {
                return <NewsItem newsData={newsData} />;
              })}
            </div>
            <div className={styles.news_tag}>
              <p className={styles.news_tag__title}>タグ一覧</p>
              <ul className={styles.news_tag__list}>
                {uniqueTags.map((tag, index) => {
                  return <NewsListTag key={index} tags={[tag]} />;
                })}
              </ul>
            </div>
          </div>
          <div className={styles.news_list__pagination}>
            <Pagination style={childStyle} />
          </div>
        </div>
      </SubpageContents>
    </>
  );
};

export default NewsList;
