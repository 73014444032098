import React, { useState } from 'react';
import styles from './confirm.module.scss';
import SubpageContents from '../../../components/layout/subpage_contents';

const GroupConfirm = () => {
  return (
    <SubpageContents bgColor={'#fdfaf1'} width={'narrow'}>
      <div className={styles.confirm}>
        <h1 className={styles.confirm__title}>組織・団体会員登録</h1>
        <p className={styles.confirm__title_en}>Sign up</p>
        <form className={styles.confirm__form}>
          <div className={styles.confirm__contents}>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>組織・団体区分</p>
              <p className={styles.confirm_item__value}>山田 太郎</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                組織・団体名<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <p className={styles.confirm_item__value}>やまだ たろう</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                組織・団体アイコンロゴ登録<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <img className={styles.confirm_item__icon} src="/assets/img/sign_up/pic_image02.png" />
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                組織・団体HPリンク<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <p className={styles.confirm_item__value}>https://wwww</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>組織・団体メールアドレス</p>
              <p className={styles.confirm_item__value}>abc@sample.com</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>組織・団体電話番号</p>
              <p className={styles.confirm_item__value}>000-0000-0000</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>代表者名</p>
              <p className={styles.confirm_item__value}>やまだ たろう</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>ふりがな</p>
              <p className={styles.confirm_item__value}>やまだ たろう</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                TOP画像<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <img className={styles.confirm_item__image} src="/assets/img/sign_up/pic_image03.jpg" />
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>
                PR記事<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <img className={styles.confirm_item__image} src="/assets/img/sign_up/pic_image03.jpg" />
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>担当者名</p>
              <p className={styles.confirm_item__value}>たなか じろう</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>担当者メールアドレス</p>
              <p className={styles.confirm_item__value}>abc@sample.com</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>担当者電話番号</p>
              <p className={styles.confirm_item__value}>000-0000-0000</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>郵便番号</p>
              <p className={styles.confirm_item__value}>〒 123-4567</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>都道府県市区町村</p>
              <p className={styles.confirm_item__value}>◯◯県 ✕✕市</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>番地</p>
              <p className={styles.confirm_item__value}>0−0−0</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>建物名・部屋番号</p>
              <p className={styles.confirm_item__value}>000</p>
            </div>
            <div className={styles.confirm_item__btns}>
              <input className={`${styles.confirm_item__btn} ${styles['confirm_item__btn--submit']}`} type="submit" value="登録" />
              <a href="/application" className={`${styles.confirm_item__btn} ${styles[`confirm_item__btn--back`]}`}>
                戻る
              </a>
            </div>
          </div>
        </form>
      </div>
    </SubpageContents>
  );
};

export default GroupConfirm;
