import React, { useState } from 'react';
import styles from './passport.module.scss';
import AdminList from '../../../components/admin/list';
import Pagination from '../../../components/admin/pagination';

const AdminPassport = () => {
  const page = window.location.href;
  const mockListdata = [
    {
      id: 1,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.12',
    },
    {
      id: 2,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.13',
    },
    {
      id: 3,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.14',
    },
    {
      id: 4,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.15',
    },
    {
      id: 5,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.12',
    },
    {
      id: 6,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.13',
    },
    {
      id: 7,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.14',
    },
    {
      id: 8,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.15',
    },
    {
      id: 9,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.12',
    },
    {
      id: 10,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.13',
    },
    {
      id: 11,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.14',
    },
    {
      id: 12,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.15',
    },
  ];
  const listHeader = ['組織・団体名', 'ふるさとパスポートID', 'Date'];

  const [currentPage, setCurrentPage] = useState(1);
  //表示件数を設定
  const itemsPerPage = 8;
  const totalPages = Math.ceil(mockListdata.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div className={styles.passport_block}>
      <div className={styles.passport_block__head}>
        <h1 className={styles.passport_block__title}>ふるさとパスポート一覧</h1>
      </div>

      <div className={styles.passport__list}>
        <AdminList page={page} mockListdata={mockListdata} listHeader={listHeader} startIndex={startIndex} endIndex={endIndex} />
      </div>
      <div className={styles.passport__pagination}>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={(page) => setCurrentPage(page)} />
      </div>
    </div>
  );
};

export default AdminPassport;
