import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './search.module.scss';

const Search = ({ onTempSearch, onSearchSubmit, resultCount, showResultCount }) => {
  const location = useLocation();
  const path = location.pathname.split('/')[1]; // 'attractive' or 'event'
  const [isEvent, setIsEvent] = React.useState(false);
  const [title, setTitle] = React.useState('');

  React.useEffect(() => {
    let newTitle;
    if (path === 'event') {
      newTitle = 'イベント記事検索';
      setIsEvent(true);
    } else if (path === 'attractive') {
      newTitle = '魅力発見記事検索';
      setIsEvent(false);
    } else if (path === 'experience') {
      newTitle = '体験記事検索';
      setIsEvent(false);
    }
    setTitle(newTitle);
  }, [path]);

  const handleInputChange = (e) => {
    onTempSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearchSubmit();
  };

  return (
    <div className={styles.search}>
      <div className={styles.search__main}>
        <p className={styles.search__title}>{title}</p>
        <form className={styles.search__contents} onSubmit={handleSubmit}>
          <div className={styles.search__content}>
            <div className={styles.search__input}>
              <div className={styles.search__item}>
                <input type="text" placeholder="フリーキーワード" name="prefecture" onChange={handleInputChange} />
                <img src="/assets/img/common/ico_search.svg" alt="" />
              </div>
            </div>
          </div>
          <div className={styles.search__content}>
            <div className={styles.search__input}>
              <div className={styles.search__item}>
                <input type="text" placeholder="都道府県" name="organization" />
                <img src="/assets/img/common/ico_arrow.svg" alt="" />
              </div>
              <div className={styles.search__item}>
                <select name="select">
                  <option value="">組織・団体</option>
                  <option value="1">選択肢1</option>
                  <option value="2">選択肢2</option>
                  <option value="3">選択肢3</option>
                </select>
                <img src="/assets/img/common/ico_arrow.svg" alt="" />
              </div>
              {isEvent && (
                <div className={styles.search__item}>
                  <input type="date" placeholder="日程" name="date" />
                  <img src="/assets/img/common/ico_calendar.svg" alt="" />
                </div>
              )}
              <div className={styles.search__submit}>
                <button type="submit">検索</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {showResultCount && <p className={styles.search__result}>検索結果が{resultCount}件見つかりました</p>}
    </div>
  );
};

export default Search;
