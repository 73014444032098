import React, { useState } from 'react';
import styles from './list.module.scss';
import Pagination from '../../../../components/pagination';
import MypageContents from '../../../../components/layout/mypage';

const StudentWritingList = () => {
  const page = window.location.href;
  const mockListdata = [
    {
      id: 1,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      held: '2023.11.12',
      date: '2023.07.27',
    },
    {
      id: 2,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      held: '2023.11.12',
      date: '2023.07.27',
    },
    {
      id: 3,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      held: '2023.11.12',
      date: '2023.07.27',
    },
    {
      id: 4,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      held: '2023.11.12',
      date: '2023.07.27',
    },
    {
      id: 5,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      held: '2023.11.12',
      date: '2023.07.27',
    },
    {
      id: 6,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      held: '2023.11.12',
      date: '2023.07.27',
    },
    {
      id: 7,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      held: '2023.11.12',
      date: '2023.07.27',
    },
    {
      id: 8,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      held: '2023.11.12',
      date: '2023.07.27',
    },
    {
      id: 9,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      held: '2023.11.12',
      date: '2023.07.27',
    },
    {
      id: 10,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      held: '2023.11.12',
      date: '2023.07.27',
    },
    {
      id: 11,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      held: '2023.11.12',
      date: '2023.07.27',
    },
    {
      id: 12,
      name: 'イベントタイトルが入ります。イベントタイトルが入ります。',
      held: '2023.11.12',
      date: '2023.07.27',
    },
  ];

  const childStyle = {
    backgroundColor: 'transparent',
  };

  const [currentPage, setCurrentPage] = useState(1);
  //表示件数を設定
  const itemsPerPage = 8;
  const totalPages = Math.ceil(mockListdata.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <MypageContents
      title={
        <>
          新規記事執筆-執筆可能な
          <br className="is-sp" />
          イベント一覧
        </>
      }
      title_en="Writing"
      currentPage="writing"
    >
      <div className={styles.writing_list}>
        <p className={styles.writing_list__title}>執筆可能なイベント一覧</p>
        <div className={styles.writing_list__inner}>
          <ul className={styles.writing_list__contents}>
            <li className={styles.writing_list__head}>
              <p className={styles.writing_list__text}>＃</p>
              <p className={styles.writing_list__text}>イベント名</p>
              <p className={styles.writing_list__text}>イベント開催日</p>
              <p className={styles.writing_list__text}>登録日</p>
            </li>
            {mockListdata.map((org, index) => (
              <li className={styles.writing_list__item}>
                <a href={`/student/mypage/writing/event/${index + 1}`}>
                  <p className={`${styles.writing_list__text} ${styles['writing_list__text--gray']}`}>{index + 1}</p>
                  <p className={`${styles.writing_list__text} ${styles['writing_list__text--large']}`}>{org.name}</p>
                  <p className={styles.writing_list__text}>{org.held}</p>
                  <p className={styles.writing_list__text}>{org.date}</p>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.writing_list__pagination}>
          <Pagination style={childStyle} />
        </div>
      </div>
    </MypageContents>
  );
};

export default StudentWritingList;
