import React from 'react';
import styles from './mypage.module.scss';

const MypageContents = ({ children, title, title_en, currentPage }) => {
  const page = window.location.href;
  return (
    <div className={styles.mypage}>
      <div className={styles.mypage_sidenavi}>
        <p className={styles.mypage_sidenavi__title}>マイページ</p>
        {page.includes('group') ? (
          <ul className={styles.mypage_sidenavi__list}>
            <li className={`${styles.mypage_sidenavi__item} ${currentPage === 'profile' ? styles['active'] : ''}`}>
              <img src="" />
              <a href="/group/mypage/profile/local">
                <i class="bi bi-person-fill"></i>プロフィール
              </a>
            </li>
            <li className={`${styles.mypage_sidenavi__item} ${currentPage === 'setting' ? styles['active'] : ''}`}>
              <a href="/group/mypage/setting">
                <i class="bi bi-calendar-check"></i>会員情報設定
              </a>
            </li>
            <li className={`${styles.mypage_sidenavi__item} ${currentPage === 'event' ? styles['active'] : ''}`}>
              <a href="/group/mypage/event">
                <i class="bi bi-gear"></i>イベント一覧
              </a>
            </li>
            <li className={styles.mypage_sidenavi__item}>
              <a href="#">
                <i class="bi bi-box-arrow-left"></i>ログアウト
              </a>
            </li>
          </ul>
        ) : page.includes('student') ? (
          <ul className={styles.mypage_sidenavi__list}>
            <li className={`${styles.mypage_sidenavi__item} ${currentPage === 'profile' ? styles['active'] : ''}`}>
              <img src="" />
              <a href="/student/mypage/profile/local">
                <i class="bi bi-person-fill"></i>プロフィール
              </a>
            </li>
            <li className={`${styles.mypage_sidenavi__item} ${currentPage === 'setting' ? styles['active'] : ''}`}>
              <a href="/student/mypage/setting">
                <i class="bi bi-calendar-check"></i>会員情報設定
              </a>
            </li>
            <li className={`${styles.mypage_sidenavi__item} ${currentPage === 'event' ? styles['active'] : ''}`}>
              <a href="/student/mypage/event">
                <i class="bi bi-gear"></i>予約済イベント一覧
              </a>
            </li>
            <li className={`${styles.mypage_sidenavi__item} ${currentPage === 'experience' ? styles['active'] : ''}`}>
              <a href="/student/mypage/experience">
                <i class="bi bi-flag"></i>イベント体験記事執筆一覧
              </a>
            </li>
            <li className={`${styles.mypage_sidenavi__item} ${currentPage === 'attractive' ? styles['active'] : ''}`}>
              <a href="/student/mypage/attractive">
                <i class="bi bi-emoji-smile"></i>魅力発見記事執筆一覧
              </a>
            </li>
            <li className={`${styles.mypage_sidenavi__item} ${currentPage === 'writing' ? styles['active'] : ''}`}>
              <a href="/student/mypage/writing">
                <i class="bi bi-pen"></i>新規記事執筆
              </a>
            </li>
            <li className={styles.mypage_sidenavi__item}>
              <a href="#">
                <i class="bi bi-box-arrow-left"></i>ログアウト
              </a>
            </li>
          </ul>
        ) : (
          ''
        )}
      </div>
      <div className={styles.mypage_contents}>
        <h1 className={styles.mypage_contents__title}>{title}</h1>
        <p className={styles.mypage_contents__title_en}>{title_en}</p>
        {children}
      </div>
    </div>
  );
};

export default MypageContents;
