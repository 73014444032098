import React, { useState, useEffect } from 'react';
import styles from './local.module.scss';
import AppContext from '../../../../context/AppContext';
import MypageContents from '../../../../components/layout/mypage';

const GroupLocalProfile = () => {
  const { setIsMypage, setIsLogin } = React.useContext(AppContext); // 未使用の変数を削除
  useEffect(() => {
    setIsMypage(true);
    setIsLogin(true);
  }, []);

  return (
    <MypageContents title="プロフィール" title_en="Profile" currentPage="profile">
      <div className={styles.profile}>
        <img className={styles.profile__mv} src="/assets/img/group/mypage/profile/pic_mv.jpg" />
        <div className={styles.profile__contents}>
          <div className={styles.profile_sidebar}>
            <img className={styles.profile_sidebar__icon} src="/assets/img/group/mypage/profile/pic_icon.jpg" />
            <div className={styles['is-sp']}>
              <p className={styles.profile_main__company}>組織・団体名が入ります</p>
              <a className={styles.profile_main__link} href="#">
                https://------
              </a>
            </div>
            <div className={styles.profile_sidebar__passport}>
              <p className={styles.profile_sidebar__title}>ふるさとパスポート</p>
              <img className={styles.profile_sidebar__img} src="/assets/img/group/mypage/profile/pic_passport.jpg" />
              <p className={styles.profile_sidebar__text}>
                取得者数: <span>30</span>
              </p>
            </div>
          </div>
          <div className={styles.profile_main}>
            <div className={styles['is-pc']}>
              <p className={styles.profile_main__company}>組織・団体名が入ります</p>
              <a className={styles.profile_main__link} href="#">
                https://------
              </a>
            </div>
            <section className={styles.profile_main__section}>
              <h2 className={styles.profile_main__title}>PR記事</h2>
              <p className={styles.profile_main__text}>この文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーです</p>
              <img className={styles.profile_main__img} src="/assets/img/group/mypage/profile/pic_contents01.jpg" />
            </section>
            <section className={styles.profile_main__section}>
              <h2 className={styles.profile_main__title}>イベント体験記事</h2>
              <ul className={styles.profile_list}>
                <li className={styles.profile_list__item}>
                  <a href="#">
                    <p className={styles.profile_list__date}>2023.07.27</p>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={styles.profile_list__item}>
                  <a href="#">
                    <p className={styles.profile_list__date}>2023.07.27</p>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={styles.profile_list__item}>
                  <a href="#">
                    <p className={styles.profile_list__date}>2023.07.27</p>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={styles.profile_list__item}>
                  <a href="#">
                    <p className={styles.profile_list__date}>2023.07.27</p>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={styles.profile_list__item}>
                  <a href="#">
                    <p className={styles.profile_list__date}>2023.07.27</p>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
              </ul>
              <div className={styles.profile_main__btn}>
                <a href="#">さらにみる</a>
              </div>
            </section>
            <section className={styles.profile_main__section}>
              <h2 className={styles.profile_main__title}>開催中・開催済イベント一覧</h2>
              <ul className={styles.profile_list}>
                <li className={styles.profile_list__item}>
                  <a href="#">
                    <p className={styles.profile_list__date}>2023.07.27</p>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={styles.profile_list__item}>
                  <a href="#">
                    <p className={styles.profile_list__date}>2023.07.27</p>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={styles.profile_list__item}>
                  <a href="#">
                    <p className={styles.profile_list__date}>2023.07.27</p>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={styles.profile_list__item}>
                  <a href="#">
                    <p className={styles.profile_list__date}>2023.07.27</p>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={styles.profile_list__item}>
                  <a href="#">
                    <p className={styles.profile_list__date}>2023.07.27</p>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
              </ul>
              <div className={styles.profile_main__btn}>
                <a href="#">さらにみる</a>
              </div>
            </section>
            <section className={styles.profile_main__section}>
              <h2 className={styles.profile_main__title}>魅力発見記事（ユーザーによる投稿）</h2>
              <ul className={styles.profile_list}>
                <li className={`${styles.profile_list__item} ${styles['profile_list__item--row']}`}>
                  <a href="#">
                    <div className={styles.profile_list__head}>
                      <img src="/assets/img/group/mypage/profile/ico_list_icon01.jpg" className={styles.profile_list__img} />
                      <p className={styles.profile_list__name}>ODK-SAN</p>
                    </div>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={`${styles.profile_list__item} ${styles['profile_list__item--row']}`}>
                  <a href="#">
                    <div className={styles.profile_list__head}>
                      <img src="/assets/img/group/mypage/profile/ico_list_icon01.jpg" className={styles.profile_list__img} />
                      <p className={styles.profile_list__name}>ODK-SAN</p>
                    </div>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
                <li className={`${styles.profile_list__item} ${styles['profile_list__item--row']}`}>
                  <a href="#">
                    <div className={styles.profile_list__head}>
                      <img src="/assets/img/group/mypage/profile/ico_list_icon01.jpg" className={styles.profile_list__img} />
                      <p className={styles.profile_list__name}>ODK-SAN</p>
                    </div>
                    <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                  </a>
                </li>
              </ul>
              <div className={styles.profile_main__btn}>
                <a href="#">さらにみる</a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </MypageContents>
  );
};

export default GroupLocalProfile;
