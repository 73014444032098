import React, { useState } from 'react';
import styles from './confirm.module.scss';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';

const ContactConfirm = () => {
    return (
        <>
            <SubpageMainvisual title="お問い合わせ" enTitle="Contact" />
            <SubpageContents bgColor={'#fdfaf1'}>
                <div className={styles.confirm}>
                    <p className={styles.confirm__lead}>
                        この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。
                        この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。
                    </p>
                    <div className={styles.confirm_contents}>
                        <div className={styles.confirm_contents__inner}>
                            <div className={styles.confirm_contents__item}>
                                <p className={styles.confirm_contents__label}>組織・団体</p>
                                <p className={styles.confirm_contents__value}>山田　太郎</p>
                            </div>
                            <div className={styles.confirm_contents__item}>
                                <p className={styles.confirm_contents__label}>部署名</p>
                                <p className={styles.confirm_contents__value}>やまだ　たろう</p>
                            </div>
                            <div className={styles.confirm_contents__item}>
                                <p className={styles.confirm_contents__label}>氏名</p>
                                <p className={styles.confirm_contents__value}>ローカルブリッジ</p>
                            </div>
                            <div className={styles.confirm_contents__item}>
                                <p className={styles.confirm_contents__label}>電話番号</p>
                                <p className={styles.confirm_contents__value}>000-1234-5678</p>
                            </div>
                            <div className={styles.confirm_contents__item}>
                                <p className={styles.confirm_contents__label}>メールアドレス</p>
                                <p className={styles.confirm_contents__value}>abc@sample.com</p>
                            </div>
                            <div className={styles.confirm_contents__item}>
                                <p className={styles.confirm_contents__label}>メールアドレス確認</p>
                                <p className={styles.confirm_contents__value}>abc@sample.com</p>
                            </div>
                            <div className={styles.confirm_contents__item}>
                                <p className={styles.confirm_contents__label}>問合せカテゴリー</p>
                                <p className={styles.confirm_contents__value}>選択してください</p>
                            </div>
                            <div className={styles.confirm_contents__item}>
                                <p className={styles.confirm_contents__label}>件名</p>
                                <p className={styles.confirm_contents__value}>選択してください</p>
                            </div>
                            <div className={styles.confirm_contents__item}>
                                <p className={styles.confirm_contents__label}>お問い合わせ内容</p>
                                <p className={styles.confirm_contents__value}>見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです</p>
                            </div>

                        </div>
                    </div>
                    <p className={styles.confirm__note}>
                        ※いただいた内容を元に、3営業日以内に担当者からご返信いたします。
                    </p>
                    <p className={styles.confirm__note}>
                        ※本お問い合わせフォームにご記入いただいた個人情報については、プライバシーポリシー（リンク）に沿って取り扱いますので、ご確認いただきますようお願い申し上げます。
                    </p>
                    <div className={styles.confirm_contents__btns}>
                        <input className={`${styles.confirm_contents__btn} ${styles['confirm_contents__btn--submit']}`} type='submit' value="送信" />
                        <a href="/application" className={`${styles.confirm_contents__btn} ${styles[`confirm_contents__btn--back`]}`}>戻る</a>
                    </div>
                </div>
            </SubpageContents>
        </>
    );
};

export default ContactConfirm;
