import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopLayout from './components/layout';
import AdminLayout from './components/admin/layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

// Importing components or containers
import Home from './pages/index';
import LoginHome from './pages/login';
//イベントページ
import Event from './pages/event';
import EventDetail from './pages/event/[id]';
//魅力発見ページ
import Attractive from './pages/attractive';
import AttractiveDetail from './pages/attractive/[id]';
//体験ページ
import Experience from './pages/experience';
import ExperienceDetail from './pages/experience/[id]';
//お知らせページ
import NewsList from './pages/news';
import NewsDetail from './pages/news/[id]';
//イベント申し込みページ
import ApplicationSignUp from './pages/application/sign_up';
import ApplicationConfirm from './pages/application/confirm';
import ApplicationComplete from './pages/application/complete';
//Aboutページ
import AboutFirst from './pages/about/first';
import AboutPassport from './pages/about/passport';
import AboutArticle from './pages/about/article';
import AboutCommunity from './pages/about/community';
import AboutContribute from './pages/about/contribute';

//お問い合わせページ
import Contact from './pages/contact';
import ContactConfirm from './pages/contact/confirm';
import ContactComplete from './pages/contact/complete';
//学生ページ
import StudentSignUp from './pages/student/sign_up/sign_up';
import StudentConfirm from './pages/student/sign_up/confirm';
import StudentComplete from './pages/student/sign_up/complete';
import StudentPasswordReset from './pages/student/password_reset/password_reset';
import StudentLogin from './pages/student/login/login';
import StudentEventList from './pages/student/mypage/event/event';
import StudentEventDetail from './pages/student/mypage/event/[id]';
import StudentSetting from './pages/student/mypage/setting/setting';
import StudentSettingConfirm from './pages/student/mypage/setting/confirm';
import StudentLocalProfile from './pages/student/mypage/profile/local';
import StudentPublicProfile from './pages/student/mypage/profile/public';
import StudentExperience from './pages/student/mypage/experience/experience';
import StudentExperienceDetail from './pages/student/mypage/experience/[id]';
import StudentAttractive from './pages/student/mypage/attractive/attractive';
import StudentAttractiveDetail from './pages/student/mypage/attractive/[id]';
import StudentWritingList from './pages/student/mypage/writing/list';
import StudentWritingDetail from './pages/student/mypage/writing/detail';
import StudentWritingEvent from './pages/student/mypage/writing/event';
//自治体・企業ページ
import GroupSignUp from './pages/group/sign_up/sign_up';
import GroupConfirm from './pages/group/sign_up/confirm';
import GroupComplete from './pages/group/sign_up/complete';
import GroupPasswordReset from './pages/group/password_reset/password_reset';
import GroupLogin from './pages/group/login/login';
import GroupLocalProfile from './pages/group/mypage/profile/local';
import GroupPublicProfile from './pages/group/mypage/profile/public';
import GroupProfileSetting from './pages/group/mypage/setting/setting';
import GroupProfileConfirm from './pages/group/mypage/setting/confirm';
import GroupEventList from './pages/group/mypage/event/event';
import GroupEventDetail from './pages/group/mypage/event/[id]';
import GroupEventConfirm from './pages/group/mypage/event/confirm';
//管理者ページ
import Admin from './pages/admin';
import AdminOrganizationList from './pages/admin/organization';
import AdminOrganizationDetail from './pages/admin/organization/[id]';
import AdminUser from './pages/admin/user';
import AdminPassport from './pages/admin/passport';
import AdminPassportDetail from './pages/admin/passport/[id]';
import AdminEvent from './pages/admin/event';
import AdminEventDetail from './pages/admin/event/[id]';
import AdminExperienceList from './pages/admin/experience';
import AdminExperienceDetail from './pages/admin/experience/[id]';
import AdminAttractiveDetail from './pages/admin/attractive/[id]';
import AdminNewsList from './pages/admin/news';
import AdminNewsDetail from './pages/admin/news/[id]';
import AdminBannerDetail from './pages/admin/banner/[id]';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {/* /TOPページ */}
        <Route
          path="/"
          element={
            <TopLayout>
              <Home />
            </TopLayout>
          }
        />
        {/* /TOPページ */}
        <Route
          path="/login"
          element={
            <TopLayout>
              <LoginHome />
            </TopLayout>
          }
        />
        {/* /イベント ルート */}
        <Route
          path="/event/*"
          element={
            <TopLayout>
              <Routes>
                <Route index element={<Event />} />
                <Route path=":id" element={<EventDetail />} />
              </Routes>
            </TopLayout>
          }
        />
        {/* /魅力発見記事 ルート */}
        <Route
          path="/attractive/*"
          element={
            <TopLayout>
              <Routes>
                <Route index element={<Attractive />} />
                <Route path=":id" element={<AttractiveDetail />} />
              </Routes>
            </TopLayout>
          }
        />
        {/* /魅力発見記事 ルート */}
        <Route
          path="/experience/*"
          element={
            <TopLayout>
              <Routes>
                <Route index element={<Experience />} />
                <Route path=":id" element={<ExperienceDetail />} />
              </Routes>
            </TopLayout>
          }
        />
        {/* /お知らせ ルート */}
        <Route
          path="/news/*"
          element={
            <TopLayout>
              <Routes>
                <Route index element={<NewsList />} />
                <Route path=":id" element={<NewsDetail />} />
              </Routes>
            </TopLayout>
          }
        />
        {/* /About ルート */}
        <Route
          path="/about/*"
          element={
            <TopLayout>
              <Routes>
                <Route path="first" element={<AboutFirst />} />
                <Route path="passport" element={<AboutPassport />} />
                <Route path="article" element={<AboutArticle />} />
                <Route path="community" element={<AboutCommunity />} />
                <Route path="contribute" element={<AboutContribute />} />
              </Routes>
            </TopLayout>
          }
        />
        {/* /イベント申し込み ルート */}
        <Route
          path="/application/*"
          element={
            <TopLayout>
              <Routes>
                <Route index element={<ApplicationSignUp />} />
                <Route path="confirm" element={<ApplicationConfirm />} />
                <Route path="complete" element={<ApplicationComplete />} />
              </Routes>
            </TopLayout>
          }
        />
        {/* /お問い合わせ ルート */}
        <Route
          path="/contact/*"
          element={
            <TopLayout>
              <Routes>
                <Route index element={<Contact />} />
                <Route path="confirm" element={<ContactConfirm />} />
                <Route path="complete" element={<ContactComplete />} />
              </Routes>
            </TopLayout>
          }
        />
        {/* /新規会員登録 ルート */}
        <Route
          path="/student/*"
          element={
            <TopLayout>
              <Routes>
                <Route index element={<StudentSignUp />} />
                <Route path="confirm" element={<StudentConfirm />} />
                <Route path="complete" element={<StudentComplete />} />
                <Route path="password_reset" element={<StudentPasswordReset />} />
                <Route path="login" element={<StudentLogin />} />
              </Routes>
            </TopLayout>
          }
        />
        {/* /学生マイページ ルート */}
        <Route
          path="/student/mypage/*"
          element={
            <TopLayout>
              <Routes>
                <Route path="profile/local" element={<StudentLocalProfile />} />
                <Route path="profile/public" element={<StudentPublicProfile />} />
                <Route path="event" element={<StudentEventList />} />
                <Route path="event/:id" element={<StudentEventDetail />} />
                <Route path="setting" element={<StudentSetting />} />
                <Route path="confirm" element={<StudentSettingConfirm />} />
                <Route path="experience" element={<StudentExperience />} />
                <Route path="experience/:id" element={<StudentExperienceDetail />} />
                <Route path="attractive" element={<StudentAttractive />} />
                <Route path="attractive/:id" element={<StudentAttractiveDetail />} />
                <Route path="writing" element={<StudentWritingList />} />
                <Route path="writing/:id" element={<StudentWritingDetail />} />
                <Route path="writing/event/:id" element={<StudentWritingEvent />} />
              </Routes>
            </TopLayout>
          }
        />
        {/* /自治体・企業マイページ ルート */}
        <Route
          path="/group/mypage/*"
          element={
            <TopLayout>
              <Routes>
                <Route path="profile/local" element={<GroupLocalProfile />} />
                <Route path="profile/public" element={<GroupPublicProfile />} />
                <Route path="setting" element={<GroupProfileSetting />} />
                <Route path="confirm" element={<GroupProfileConfirm />} />
                <Route path="event" element={<GroupEventList />} />
                <Route path="event/:id" element={<GroupEventDetail />} />
                <Route path="event/:id/confirm" element={<GroupEventConfirm />} />
              </Routes>
            </TopLayout>
          }
        />
        {/* /組織・団体会員登録 ルート */}
        <Route
          path="/group/*"
          element={
            <TopLayout>
              <Routes>
                <Route index element={<GroupSignUp />} />
                <Route path="confirm" element={<GroupConfirm />} />
                <Route path="complete" element={<GroupComplete />} />
                <Route path="password_reset" element={<GroupPasswordReset />} />
                <Route path="login" element={<GroupLogin />} />
              </Routes>
            </TopLayout>
          }
        />
        {/* /管理者 ルート */}
        <Route path="/admin" element={<Admin />} />
        <Route
          path="/admin/*"
          element={
            <AdminLayout>
              <Routes>
                <Route path="organization" element={<AdminOrganizationList />} />
                <Route path="organization/:id" element={<AdminOrganizationDetail />} />
                <Route path="user" element={<AdminUser />} />
                <Route path="passport" element={<AdminPassport />} />
                <Route path="passport/:id" element={<AdminPassportDetail />} />
                <Route path="event" element={<AdminEvent />} />
                <Route path="event/:id" element={<AdminEventDetail />} />
                <Route path="experience" element={<AdminExperienceList />} />
                <Route path="experience/:id" element={<AdminExperienceDetail />} />
                <Route path="news" element={<AdminNewsList />} />
                <Route path="news/:id" element={<AdminNewsDetail />} />
                <Route path="attractive/:id" element={<AdminAttractiveDetail />} />
                <Route path="banner/:id" element={<AdminBannerDetail />} />
              </Routes>
            </AdminLayout>
          }
        />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
