import React from 'react';
import { useParams } from 'react-router-dom'; // React RouterからuseParamsをインポート
import styles from './detail.module.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const AdminPassportDetail = () => {
  const { id } = useParams();
  const mockListdata = [
    {
      id: 1,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.12',
    },
    {
      id: 2,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.13',
    },
    {
      id: 3,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.14',
    },
    {
      id: 4,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.15',
    },
    {
      id: 5,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.12',
    },
    {
      id: 6,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.13',
    },
    {
      id: 7,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.14',
    },
    {
      id: 8,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.15',
    },
    {
      id: 9,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.12',
    },
    {
      id: 10,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.13',
    },
    {
      id: 11,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.14',
    },
    {
      id: 12,
      name: 'ABCDE組織・団体',
      passportId: 'ROIICQWEWQ##$%WEFDQ@WE',
      date: '2023.11.15',
    },
  ];

  const organization = mockListdata.find((org) => org.id === parseInt(id, 10));
  return (
    <div className={styles.passport_detail}>
      <Form className={styles.passport_detail__form}>
        <div className={styles.passport_detail__head}>
          <h1 className={styles.passport_detail__title}>ふるさとパスポート（編集）</h1>
        </div>
        <div className={styles.passport_detail__contents}>
          <div className={styles.passport_detail__input}>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>ふるさとパスポートID</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
            <section className={styles.detail_section}>
              <Form.Group>
                <h2 className={styles.detail_section__title}>日付</h2>
                <div className={styles.detail_section__input}>
                  <Form.Control required type="text" placeholder="placeholder" />
                </div>
              </Form.Group>
            </section>
          </div>
          <div className={styles.detail_side}>
            <section className={styles.detail_section}>
              <h2 className={styles.detail_section__title}>公開</h2>
              <div className={styles.detail_side__inputs}>
                <Button className={`${styles.detail_side__input} ${styles['detail_side__input--submit']}`} type="submit">
                  公開
                </Button>
                <Button className={`${styles.detail_side__input} ${styles['detail_side__input--delete']}`} type="submit">
                  削除
                </Button>
              </div>
            </section>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AdminPassportDetail;
