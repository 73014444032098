import React from 'react';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';
import styles from './first.module.scss';

const AboutFirst = () => {
  const AboutNav = () => {
    return (
      <ul className={styles.nav}>
        <li className={`${styles.nav__item} ${styles['active']}`}>
          <a href="/about/first">はじめての方へ</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/passport">ふるさとパスポートとは？</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/contribute">貢献度バッジとは？</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/article">イベント体験記事・魅力発見記事について</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/community">コミュニティについて</a>
        </li>
      </ul>
    );
  };
  return (
    <>
      <SubpageMainvisual title="はじめての方へ" enTitle="For first time users" />
      <SubpageContents bgColor={'#fdfaf1'}>
        <AboutNav />
        <div className={styles.first}>
          <section className={styles.first__head}>
            <h2 className={styles.first__title}>はじめての方へ</h2>
            <p className={styles.first_section__en_title}>For first time users</p>
            <p className={styles.first__lead}>Local Bridgeは、デジタルの力を活用し、持続できる形で地方との繋がりや関わり度の総量を増やし、 「地方創生」を目指す新しい形のデジタルプラットフォームです。</p>
            <div className={styles.first__img}>
              <picture>
                <source media="(max-width: 769px)" srcset="/assets/img/about/first/pic_caption01_sp.svg" />
                <img src="/assets/img/about/first/pic_caption01.svg" alt="" />
              </picture>
            </div>
            <p className={styles.first__text}>
              地域の特性を活かした自律的で持続的な社会づくりである「地方創生」を、オンライン・オフライン問わず、私たち一人一人が持続できる形で実現していきます。 居住地、出身地など自分と関わりのある地域の魅力を発信し、地域外の方とも交流が生まれることで、自分の街がもっと好きになる。自分が旅した土地の「ふるさとパスポート」を持つことで、第2のふるさととしてその地域との繋がりを深められる。
              <br />
              そうした繋がりを、NFTという体験を可視化・証明・活用できる技術を用いた「ふるさとパスポート」によって拡げていきます。 これによって、楽しみながら地域の魅力を見つけ、体験することでいつの間にか地域に貢献できる未来を実現できます。
            </p>
            <div className={styles.first_catch}>
              <p className={styles.first_catch__title}>まずは「ふるさとパスポート」を取得できるイベントに参加してみませんか？</p>
              <div className={styles.first_catch__btn}>
                <a href="#">
                  <img src="/assets/img/about/first/ico_schedule.svg" />
                  イベント一覧へ
                </a>
              </div>
            </div>
          </section>
          <section className={styles.first_section}>
            <div className={styles.first_section__inner}>
              <h2 className={styles.first_section__title}>ふるさとパスポートとは？</h2>
              <p className={styles.first_section__en_title}>About Furusato Passport</p>
              <p className={styles.first_section__lead}>ふるさとパスポートは、あなたがその地域に繋がりを持ち、「地方創生」に関わる活動へ参加したことを証明するデジタルパスポートです。</p>
              <p className={styles.first_section__text}>Local Bridge内のイベントへの参加や体験した内容や街の魅力を記事として執筆することで、その地域の「ふるさとパスポート」を取得することができます。また、ふるさとパスポートに紐づく貢献度バッジを集めることで、ふるさとパスポートが進化し、特別なイベントに参加できるようになったり、あなたの力を必要とする人からオファーが届くなど、地域とのつながりを深めていくことができます。 あなたが日本各地で取り組んだ地域活性化の活動への貢献度がひと目でわかる勲章として、生涯に渡って証明し続けられる。これが「ふるさとパスポート」です。</p>
              <div className={styles.first_section__img01}>
                <picture>
                  <source media="(max-width: 769px)" srcset="/assets/img/about/first/pic_caption02_sp.png" />
                  <img src="/assets/img/about/first/pic_caption02.png" alt="" />
                </picture>
              </div>
              <div className={styles.first_section__btn}>
                <a href="/about/passport">詳しく見る</a>
              </div>
              <p className={styles.first_section__link}>
                <a href="#">自治体の皆様へ</a>
              </p>
            </div>
          </section>
          <section className={styles.first_section}>
            <div className={styles.first_section__inner}>
              <h2 className={styles.first_section__title}>貢献度バッジとは？</h2>
              <p className={styles.first_section__en_title}>About Contribution Badges</p>
              <p className={styles.first_section__text}>貢献度バッジは、あなたがどんな分野の活動に参加したかを表すバッジです。１つのふるさとパスポートに「地方創生」の活動分野を表す８種類の貢献度バッジが紐づいていて、関係人口づくりや地域の魅力発信など参加イベント、アクションごとに設定された貢献バッジが付与されます。この貢献バッジを一定枚数以上集めると、それに応じてふるさとパスポートが進化していきます。</p>
              <p className={styles.first_section__sub_title}>ふるさとパスポートのランクが上がる仕組み</p>
              <p className={styles.first_section__sub_text}>ふるさとパスポートは、8種類の貢献度バッジを全部で何枚持っているかに応じてランクが上がっていきます。貢献度バッジの種類や、何枚目を手に入れた時にランクが上がるかについては、下の「詳しく見る」ボタンからご覧ください。</p>
              <div className={styles.first_section_group}>
                <div className={styles.first_section_group__texts}>
                  <p className={styles.first_section_group__title}>ランクが上がる一例</p>
                  <p className={styles.first_section_group__text}>A町の8種類の貢献度バッジを種類問わず合計9枚持っている状態だと、A町のふるさとパスポートのランクはブロンズですが、その状態でさらに1枚A町の貢献度バッジを手に入れると、A町のふるさとパスポートのランクはシルバーに上がります。</p>
                </div>
                <div className={styles.first_section__img02}>
                  <picture>
                    <source media="(max-width: 769px)" srcset="/assets/img/about/first/pic_caption03.png" />
                    <img src="/assets/img/about/first/pic_caption03.png" alt="" />
                  </picture>
                </div>
              </div>
              <div className={styles.first_section__btn}>
                <a href="#">詳しく見る</a>
              </div>
            </div>
          </section>
          <section className={styles.first_flow}>
            <h2 className={styles.first_flow__title}>
              イベント申し込み〜
              <br className="is-sp" />
              ご体験後までの流れ
            </h2>
            <p className={styles.first_flow__en_title}>Flow from event application to after experience</p>
            <div className={styles.first_flow__contents}>
              <div className={styles.first_flow_item}>
                <p className={styles.first_flow_item__label}>01</p>
                <img className={styles.first_flow_item__img} src="/assets/img/about/first/ico_flow01.svg" alt="" />
                <p className={styles.first_flow_item__text}>
                  イベント一覧から
                  <br />
                  興味のあるイベントを探す
                </p>
              </div>
              <div className={styles.first_flow_item}>
                <p className={styles.first_flow_item__label}>02</p>
                <img className={styles.first_flow_item__img} src="/assets/img/about/first/ico_flow02.svg" alt="" />
                <p className={styles.first_flow_item__text}>
                  イベントに申し込み、
                  <br />
                  参加する
                </p>
              </div>
              <div className={styles.first_flow_item}>
                <p className={styles.first_flow_item__label}>03</p>
                <img className={styles.first_flow_item__img} src="/assets/img/about/first/ico_flow03.svg" alt="" />
                <p className={styles.first_flow_item__text}>
                  参加したイベントを選び、
                  <br />
                  イベント体験記事を執筆する
                </p>
              </div>
              <div className={styles.first_flow_item}>
                <p className={styles.first_flow_item__label}>04</p>
                <img className={styles.first_flow_item__img} src="/assets/img/about/first/ico_flow04.svg" alt="" />
                <p className={styles.first_flow_item__text}>貢献度バッジを獲得する</p>
              </div>
            </div>
          </section>
          <section className={`${styles.first_section} ${styles['first_section--article']}`}>
            <h2 className={styles.first_section__title}>イベント体験記事・魅力発見記事について</h2>
            <p className={styles.first_section__en_title}>About Event experience articles / Attractive discovery articles</p>
            <div className={styles.first_section__contents}>
              <p className={styles.first_section__text}>Local Bridgeでは、あなたが感じた地域の魅力や地域活性化の取り組みを対外的に発信し、それに賛同したり魅力を感じた仲間を増やすことを「地方創生」の一つと捉え、Local Bridge上でユーザー参加型の記事の発信を行っていきます。 イベント体験記事ではあなたが体験したイベントの内容や感想を発信でき、魅力発見記事ではその地域でぜひ訪れてほしい場所や、その地域に関わる是非会ってみてほしい人など、あなたが発掘した場所に限らない様々な確度での地域の魅力ついて発信できます。 「ふるさとパスポート」を持っている方ならだれでもライターになることができ、活動に応じて貢献バッジを取得することができます。 ぜひ、あなたも地域の一員としてライターになってみませんか？</p>
              <div className={styles.first_section__img04}>
                <picture>
                  <source media="(max-width: 769px)" srcset="/assets/img/about/first/pic_caption04.png" />
                  <img src="/assets/img/about/first/pic_caption04.png" alt="" />
                </picture>
              </div>
            </div>
            <div className={styles.first_section__btn}>
              <a href="#">詳しく見る</a>
            </div>
          </section>
          <section className={`${styles.first_section} ${styles['first_section--community']}`}>
            <h2 className={styles.first_section__title}>Local Bridgeコミュニティについて</h2>
            <p className={styles.first_section__en_title}>About Local Bridge Community</p>
            <div className={styles.first_section__contents}>
              <p className={styles.first_section__text}>
                Local Bridgeコミュニティは、「地方創生」に興味のある方なら誰でも参加 できるオンラインコミュニティです。
                <br />
                Local Bridge内の記事をみて、興味がわいたな！行ってみたいな！やってみたいな！の情報取得として、自分と関係のある地域、また域外の地域、どちらでもオンラインでの「地方創生」の関わりの第1歩として覗いていただくこともできます。
                <br />
                また、「ふるさとパスポート」を持っている方だけが参加できる限定のコミュニティの開設もあり、コミュニティ内にはあなたの持っている「ふるさとパスポート」に合ったチャンネルがあり、そこで同じ地域や同じ分野の活動に魅力を感じている仲間と楽しく交流ができます。
                <br />
                オンラインでのイベントアイデアの持ち込みも行っていただけます。
              </p>
              <div className={styles.first_section__img05}>
                <picture>
                  <source media="(max-width: 769px)" srcset="/assets/img/about/first/pic_caption05.png" />
                  <img src="/assets/img/about/first/pic_caption05.png" alt="" />
                </picture>
              </div>
            </div>
            <div className={styles.first_section__btn}>
              <a href="#">詳しく見る</a>
            </div>
          </section>
          <section className={styles.first_community}>
            <h2 className={styles.first_community__title}>Local Bridgeコミュニティ</h2>
            <p className={styles.first_community__title_en}>Local Bridge Community</p>
            <div className={styles.first_community__btn}>
              <a href="#">コミュニティサイトへ行く！</a>
              <img className={styles.first_community__catch} src="/assets/img/top/pic_community05.png" alt="Community" />
            </div>
          </section>
        </div>
      </SubpageContents>
    </>
  );
};

export default AboutFirst;
