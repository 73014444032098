import { React, useState } from 'react';
import styles from './experience.module.scss';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';
import Search from '../../components/search';
import ArticleCard from '../../components/card/article_card';
import Pagination from '../../components/pagination';

const Experience = () => {
  const mockCardData = [
    {
      id: 1,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '01記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 2,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '02記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 3,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '03記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 4,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '04記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 5,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '05記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 6,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '06記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 7,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '07記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 8,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '08記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 9,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '09記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
  ];

  // 検索キーワードの状態変数
  const [searchKeyword, setSearchKeyword] = useState('');
  const [tempSearchKeyword, setTempSearchKeyword] = useState('');
  const [showResultCount, setShowResultCount] = useState(false);

  const filteredmockCardData = mockCardData.filter((data) => data.text.toLowerCase().includes(searchKeyword.toLowerCase()));

  const handleTempSearch = (keyword) => {
    setTempSearchKeyword(keyword);
  };

  const handleSearchSubmit = () => {
    setSearchKeyword(tempSearchKeyword);
    setShowResultCount(true);
  };

  return (
    <>
      <SubpageMainvisual title="イベント体験記事一覧" enTitle="Event experience articles" />
      <SubpageContents bgColor={'#ffecda'}>
        <Search onTempSearch={handleTempSearch} onSearchSubmit={handleSearchSubmit} resultCount={filteredmockCardData.length} showResultCount={showResultCount} />
        <div className={styles.experience_list}>
          {filteredmockCardData.map((data, index) => (
            <ArticleCard key={index} cardData={data} pathname={'/experience'} />
          ))}
        </div>
        <div className={styles.experience_list__pagination}>
          <Pagination />
        </div>
      </SubpageContents>
    </>
  );
};

export default Experience;
