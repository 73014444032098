import React, { useState } from 'react';
import styles from './confirm.module.scss';
import MypageContents from '../../../../components/layout/mypage';

const GroupMypageConfirm = () => {
  return (
    <MypageContents title="イベント入稿・編集" title_en="Post/Edit" currentPage="event">
      <div className={styles.confirm}>
        <form className={styles.confirm__form}>
          <div className={styles.confirm__contents}>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>イベント名</p>
              <p className={styles.confirm_item__value}>イベント名が入ります</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>開催日</p>
              <p className={styles.confirm_item__value}>2023.12.01 〜 2023.12.11</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>イベント開催時刻</p>
              <p className={styles.confirm_item__value}>10:00 〜11:00</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>定員</p>
              <p className={styles.confirm_item__value}>100人</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>申込期間</p>
              <p className={styles.confirm_item__value}>10:00 〜11:00</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>参加費</p>
              <p className={styles.confirm_item__value}>¥4,000-</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>イベント概要</p>
              <p className={styles.confirm_item__value}>見出しのダミーテキストです見出しのダミーテキストです</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>イベント詳細</p>
              <p className={styles.confirm_item__value}>見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>注意事項</p>
              <p className={styles.confirm_item__value}>見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです見出しのダミーテキストです</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>イベント・ワークショプ表記</p>
              <p className={styles.confirm_item__value}>見出しのダミーテキストです見出しのダミーテキストです</p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>応募する・申込むボタン切替え</p>
              <p className={styles.confirm_item__value}>
                応募する or 申込み
                <br />
                URL :https://wwww
                <br />
                デフォルト設定
              </p>
            </div>
            <div className={styles.confirm_item}>
              <p className={styles.confirm_item__label}>画像</p>
              <p className={styles.confirm_item__value}>サムネイルに利用する画像 :</p>
              <img className={styles.confirm_item__thumbnail} src="/assets/img/group/mypage/event/pic_thumbnail01.jpg" />
              <div className={styles.confirm_item__thumbnails}>
                <img className={styles.confirm_item__thumbnail} src="/assets/img/group/mypage/event/pic_thumbnail02.jpg" />
                <img className={styles.confirm_item__thumbnail} src="/assets/img/group/mypage/event/pic_thumbnail03.jpg" />
                <img className={styles.confirm_item__thumbnail} src="/assets/img/group/mypage/event/pic_thumbnail04.jpg" />
                <img className={styles.confirm_item__thumbnail} src="/assets/img/group/mypage/event/pic_thumbnail05.jpg" />
                <img className={styles.confirm_item__thumbnail} src="/assets/img/group/mypage/event/pic_thumbnail06.jpg" />
              </div>
            </div>
          </div>
          <div className={styles.confirm_item__btns}>
            <input className={`${styles.confirm_item__btn} ${styles['confirm_item__btn--submit']}`} type="submit" value="イベント内容を入稿する" />
            <a href="/application" className={`${styles.confirm_item__btn} ${styles[`confirm_item__btn--back`]}`}>
              イベント内容を編集する
            </a>
          </div>
        </form>
      </div>
    </MypageContents>
  );
};

export default GroupMypageConfirm;
