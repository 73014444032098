import React, { useState } from 'react';
import styles from './sign_up.module.scss';
import SubpageContents from '../../../components/layout/subpage_contents';

const GroupSignUp = () => {
  const [logoPreview, setLogoPreview] = useState(null);
  const [topImagePreview, setTopImagePreview] = useState(null);
  const [prImagePreview, setPrImagePreview] = useState(null);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleTopImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setTopImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handlePrImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPrImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <SubpageContents bgColor={'#fdfaf1'} width={'narrow'}>
      <div className={styles.sign_up}>
        <h1 className={styles.sign_up__title}>組織・団体会員登録</h1>
        <p className={styles.sign_up__title_en}>Sign up</p>
        <form className={styles.sign_up__form}>
          <div className={styles.sign_up__contents}>
            <p className={styles.sign_up__note}>※は必須項目です</p>
            <div className={styles.sign_up_item}>
              <label className={styles.sign_up_item__label} htmlFor="organization_type">
                組織・団体区分<span className={styles['required']}>※</span>
              </label>
              <div className={`${styles.sign_up_item__input} ${styles['sign_up_item__input--select']}`}>
                <select name="organization_type">
                  <option value="" disabled selected>
                    選択してください
                  </option>
                  <option value="test01">test01</option>
                  <option value="test02">test02</option>
                  <option value="test03">test03</option>
                </select>
              </div>
            </div>
            <div className={`${styles.sign_up_item} ${styles['is-error']}`}>
              <label className={styles.sign_up_item__label} htmlFor="organization_name">
                組織・団体名<span className={styles['required']}>※</span>
                <span className={styles['note']}>この情報は公開されます</span>
              </label>
              <input className={styles.sign_up_item__input} type="text" id="organization_name" name="organization_name" placeholder="ローカルブリッジ" />
            </div>
            <div className={`${styles.sign_up_item} ${styles['sign_up_item--logo']}`}>
              <p className={styles.sign_up_item__label} htmlFor="organization_logo">
                組織・団体アイコンロゴ登録<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <label className={styles.sign_up_item__logo}>
                <input type="file" name="organization_logo" onChange={handleLogoChange} />
                <img src={logoPreview || '/assets/img/sign_up/pic_image01.png'} alt="" />
              </label>
            </div>
            <div className={styles.sign_up_item}>
              <label className={styles.sign_up_item__label} htmlFor="organization_website">
                組織・団体HPリンク<span className={styles['note']}>この情報は公開されます</span>
              </label>
              <input className={styles.sign_up_item__input} type="text" id="organization_website" name="organization_website" placeholder="https://wwww" />
            </div>
            <div className={styles.sign_up_item}>
              <label className={styles.sign_up_item__label} htmlFor="organization_email">
                組織・団体メールアドレス
              </label>
              <input className={styles.sign_up_item__input} type="text" id="organization_email" name="organization_email" placeholder="abc@sample.com" />
            </div>
            <div className={styles.sign_up_item}>
              <label className={styles.sign_up_item__label} htmlFor="organization_phone">
                組織・団体電話番号
              </label>
              <input className={styles.sign_up_item__input} type="text" id="organization_phone" name="organization_phone" placeholder="000-0000-0000" />
            </div>
            <div className={styles.sign_up_item}>
              <label className={styles.sign_up_item__label} htmlFor="password">
                パスワード<span className={styles['required']}>※</span>
              </label>
              <input className={styles.sign_up_item__input} type="text" id="password" name="password" placeholder="xxxxxxxxxxxxxxxxx" />
            </div>
            <div className={styles.sign_up_item}>
              <label className={styles.sign_up_item__label} htmlFor="confirm_password">
                確認用パスワード<span className={styles['required']}>※</span>
              </label>
              <input className={styles.sign_up_item__input} type="text" id="confirm_password" name="confirm_password" placeholder="xxxxxxxxxxxxxxxxx" />
            </div>
            <div className={styles.sign_up_item}>
              <label className={styles.sign_up_item__label} htmlFor="representative_name">
                代表者名<span className={styles['required']}>※</span>
              </label>
              <input className={styles.sign_up_item__input} type="text" id="representative_name" name="representative_name" placeholder="田中二郎" />
            </div>
            <div className={styles.sign_up_item}>
              <label className={styles.sign_up_item__label} htmlFor="representative_furigana">
                ふりがな<span className={styles['required']}>※</span>
              </label>
              <input className={styles.sign_up_item__input} type="text" id="representative_furigana" name="representative_furigana" placeholder="たなか　じろう" />
            </div>
            <div className={`${styles.sign_up_item} ${styles['sign_up_item--upload']}`}>
              <p className={styles.sign_up_item__label} htmlFor="organization_logo">
                TOP画像<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <label className={`${styles.sign_up_upload01} ${topImagePreview ? styles['is-preview'] : ''}`}>
                <input type="file" name="top_image" onChange={handleTopImageChange} />
                {topImagePreview ? (
                  <img className={styles.sign_up_upload01__preview} src={topImagePreview} alt="Preview" />
                ) : (
                  <>
                    <img className={styles.sign_up_upload01__img} src="/assets/img/common/ico_camera.svg" alt="" />
                    <p className={styles.sign_up_upload01__text}>
                      ファイルをアップロード
                      <img src="/assets/img/common/ico_upload_white.svg" />
                    </p>
                  </>
                )}
              </label>
            </div>
            <div className={`${styles.sign_up_item} ${styles['sign_up_item--upload']}`}>
              <p className={styles.sign_up_item__label} htmlFor="pr_article">
                PR記事<span className={styles['note']}>この情報は公開されます</span>
              </p>
              <textarea className={`${styles.sign_up_item__input} ${styles['sign_up_item__input--textarea']}`} placeholder="入力してください" type="text" name="pr_article" />
              {prImagePreview && <img className={styles.sign_up_item__preview} src={prImagePreview} alt="PR Image Preview" />}
              <label className={styles.sign_up_upload02}>
                <input type="file" name="pr_image" onChange={handlePrImageChange} />
                <p className={styles.sign_up_upload02__text}>
                  ファイルをアップロード
                  <img src="/assets/img/common/ico_upload_black.svg" />
                </p>
              </label>
            </div>
            <div className={styles.sign_up_item}>
              <label className={styles.sign_up_item__label} htmlFor="contact_name">
                担当者名<span className={styles['required']}>※</span>
              </label>
              <input className={styles.sign_up_item__input} type="text" id="contact_name" name="contact_name" placeholder="たなか　じろう" />
            </div>
            <div className={styles.sign_up_item}>
              <label className={styles.sign_up_item__label} htmlFor="contact_email">
                担当者メールアドレス<span className={styles['required']}>※</span>
              </label>
              <input className={styles.sign_up_item__input} type="text" id="contact_email" name="contact_email" placeholder="たなか　じろう" />
            </div>
            <div className={styles.sign_up_item}>
              <label className={styles.sign_up_item__label} htmlFor="contact_phone">
                担当者電話番号<span className={styles['required']}>※</span>
              </label>
              <input className={styles.sign_up_item__input} type="text" id="contact_phone" name="contact_phone" placeholder="たなか　じろう" />
            </div>
            <div className={`${styles.sign_up_item} ${styles['sign_up_item--width-xs']}`}>
              <label className={styles.sign_up_item__label} htmlFor="postal_code">
                郵便番号
              </label>
              <input className={styles.sign_up_item__input} type="text" name="postal_code" minlength="7" maxlength="8" pattern="\d*" autocomplete="shipping postal-code" placeholder="123-4567" />
            </div>
            <div className={`${styles.sign_up_item} ${styles['sign_up_item--width-xs']}`}>
              <label className={styles.sign_up_item__label} htmlFor="prefecture">
                都道府県
              </label>
              <div className={`${styles.sign_up_item__input} ${styles['sign_up_item__input--select']}`}>
                <select name="prefecture" autocomplete="shipping address-level1">
                  <option value="" disabled selected>
                    選択してください
                  </option>
                  <option value="北海道">北海道</option>
                  <option value="東京">東京</option>
                  <option value="京都">京都</option>
                  <option value="沖縄">沖縄</option>
                </select>
              </div>
            </div>
            <div className={`${styles.sign_up_item} ${styles['sign_up_item--width-md']}`}>
              <label className={styles.sign_up_item__label} htmlFor="city">
                市区町村
              </label>
              <div className={`${styles.sign_up_item__input} ${styles['sign_up_item__input--select']}`}>
                <select name="prefecture" autocomplete="shipping address-level1">
                  <option value="" disabled selected>
                    選択してください
                  </option>
                  <option value="北海道">北海道</option>
                  <option value="東京">東京</option>
                  <option value="京都">京都</option>
                  <option value="沖縄">沖縄</option>
                </select>
              </div>
            </div>
            <div className={`${styles.sign_up_item} ${styles['sign_up_item--width-md']}`}>
              <label className={styles.sign_up_item__label} htmlFor="address">
                番地
              </label>
              <input className={styles.sign_up_item__input} placeholder="1-2-3" type="text" name="address" autocomplete="shipping address-line1" />
            </div>
            <div className={`${styles.sign_up_item} ${styles['sign_up_item--width-md']}`}>
              <label className={styles.sign_up_item__label} htmlFor="building">
                建物名・部屋番号
              </label>
              <input className={styles.sign_up_item__input} placeholder="JMFビル北浜 01" type="text" name="building" autocomplete="shipping address-line2" />
            </div>
            <input className={styles.sign_up__submit} type="submit" value="確認画面へ" />
          </div>
        </form>
      </div>
    </SubpageContents>
  );
};

export default GroupSignUp;
