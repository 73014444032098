import React from 'react';
import styles from './article_card.module.scss';
import { useLocation } from 'react-router-dom';

const ArticleCard = ({ cardData, pathname }) => {
  const origin = window.location.origin; // スキームとドメイン名を取得
  const fullUrl = `${origin}${pathname}/${cardData.id}`; // 完全なURLを生成

  const { thumbnail, date, author, label, text, reactions, tag } = cardData; // 分割代入
  return (
    <div className={styles.article_card}>
      <a href={fullUrl} className={styles.news_item__link}>
        <img src={thumbnail} className={styles.article_card__img} alt="" />
        <div className={styles.article_card__contents}>
          <p className={styles.article_card__info}>
            掲載日: {cardData.date} / 投稿者: {cardData.author} / {cardData.abel}
          </p>
          <p className={styles.article_card__text}>{cardData.text}</p>
          <div className={styles.article_card_reaction}>
            {reactions.map((reaction, index) => {
              return (
                <div key={index} className={styles.article_card_reaction__item}>
                  <img src={reaction.icon} className={styles.article_card_reaction__icon} />
                  <p className={styles.article_card_reaction__text}>{reaction.count}</p>
                </div>
              );
            })}
          </div>
          <p className={styles.article_card_tag}>
            <img className={styles.article_card_tag__icon} src="/assets/img/common/ico_tag.svg" />
            <p className={styles.article_card_tag__text}>{cardData.tag}</p>
          </p>
        </div>
      </a>
    </div>
  );
};

export default ArticleCard;
