import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Swiper from 'swiper/bundle';
import EventCard from '../components/card/event_card';
import ArticleCard from '../components/card/article_card';
import 'swiper/swiper-bundle.min.css';

const Home = () => {
  const mockEventData = [
    {
      id: 1,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '01これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 2,
      thumbnail: '/assets/img/card/pic_thumbnail02.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '02これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 3,
      thumbnail: '/assets/img/card/pic_thumbnail03.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '03これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 4,
      thumbnail: '/assets/img/card/pic_thumbnail04.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '04これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 5,
      thumbnail: '/assets/img/card/pic_thumbnail05.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '05これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 6,
      thumbnail: '/assets/img/card/pic_thumbnail06.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '06これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 7,
      thumbnail: '/assets/img/card/pic_thumbnail07.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '07これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 8,
      thumbnail: '/assets/img/card/pic_thumbnail08.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '08これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 9,
      thumbnail: '/assets/img/card/pic_thumbnail09.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '09これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
  ];
  const mockExperienceData = [
    {
      id: 1,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '01記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 2,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '02記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 3,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '03記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 4,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '04記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 5,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '05記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 6,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '06記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 7,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '07記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 8,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '08記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 9,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '09記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
  ];
  const mockAttractiveData = [
    {
      id: 1,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '01記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 2,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '02記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 3,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '03記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 4,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '04記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 5,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '05記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 6,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '06記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 7,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '07記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 8,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '08記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 9,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '09記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
  ];
  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const scrollY = window.scrollY;
    const targetElement = document.querySelector(`.${styles.index_page__contact}`);
    const targetPosition = targetElement ? targetElement.getBoundingClientRect().top + scrollY : 0;

    if (0 < targetPosition - window.innerHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const swiper = new Swiper('.swiper-container', {
      spaceBetween: 12,
      slidesPerView: 1.13,
      spaceBetween: 12,
      loop: true,
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination', // ページネーション要素のセレクタ
        clickable: true, // クリック可能にする
      },
      breakpoints: {
        768: {
          slidesPerView: 1.64,
          centeredSlides: true,
          spaceBetween: 20,
        },
      },
    });
    // スクロールイベントのリスナーを追加
    window.addEventListener('scroll', handleScroll);

    // コンポーネントがアンマウントされるときにリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles.index_page}>
      <div className={`${styles.index_page__contact} ${isVisible ? `${styles['visible']}` : ''}`}>
        <a href="#">
          <picture>
            <source media="(max-width: 769px)" srcset="/assets/img/top/pic_contact_sp.svg" />
            <img src="/assets/img/top/pic_contact.svg" alt="" />
          </picture>
        </a>
      </div>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <div className="swiper-slide swiper-slide--01">
            <picture>
              <source media="(max-width: 769px)" srcset="/assets/img/top/pic_slide01_sp.png" />
              <img src="/assets/img/top/pic_slide01.png" alt="" />
            </picture>
            <img className="swiper-slide__text" src="/assets/img/top/txt_slide01.svg" alt="" />
          </div>
          <div className="swiper-slide">
            <picture>
              <source media="(max-width: 769px)" srcset="/assets/img/top/pic_slide01_sp.png" />
              <img src="/assets/img/top/pic_slide02.jpg" alt="" />
            </picture>
          </div>
          <div className="swiper-slide">
            <picture>
              <source media="(max-width: 769px)" srcset="/assets/img/top/pic_slide01_sp.png" />
              <img src="/assets/img/top/pic_slide03.jpg" alt="" />
            </picture>
          </div>
        </div>
      </div>
      <div className={styles.index_page__head}>
        <div className={`swiper-pagination ${styles.index_page__pagination}`}></div>
        <section className={styles.news}>
          <div className={styles.news__head}>
            <h2 className={styles.news__title}>お知らせ</h2>
            <p className={styles.news__title_en}>News</p>
            <div className={styles.news__btn}>
              <a href="/news">お知らせの一覧へ</a>
            </div>
          </div>
          <ul className={styles.news__list}>
            {[...Array(5)].map((_, i) => (
              <li className={styles.news__item} key={i}>
                <a href="#">
                  <p className={styles.news__date}>2021.01.01</p>
                  <p className={styles.news__text}>お知らせのタイトルが入ります。お知らせのタイトルが入ります。お知らせのタイトルが入ります。</p>
                </a>
              </li>
            ))}
          </ul>
        </section>
        <section className={styles.about}>
          <h2 className={styles.about__title}>はじめての方へ</h2>
          <p className={styles.about__title_en}>About</p>
          <p className={styles.about__lead}>
            Local Bridgeは、デジタルの力を活用し、持続できる形で地方との繋がりや関わり度の総量を増やし、
            <br />
            「地方創生」を目指す新しい形のデジタルプラットフォームです。
          </p>
          <picture>
            <source media="(max-width: 769px)" srcset="/assets/img/top/pic_about01_sp.png" />
            <img className={styles.about__caption} src="/assets/img/top/pic_about01.png" alt="" />
          </picture>
          <div className={styles.about__btn}>
            <a href="#">ローカルブリッジとは</a>
          </div>
        </section>
      </div>
      <section className={styles.event}>
        <div className={styles.event__inner}>
          <div className={styles.event__head}>
            <img className={styles.event__img} src="/assets/img/top/ico_event01.svg" alt="Event" />
            <div>
              <h2 className={styles.event__title}>イベント</h2>
              <p className={styles.event__title_en}>Events</p>
            </div>
            <p className={styles.event__lead}>
              これはタイトルのダミーです。見出しのダミーテキストイメージです。
              <br />
              この文章はダミーです。これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。
            </p>
          </div>
          <div className={styles.event__list}>
            {mockEventData.slice(0, 9).map((cardData, index) => (
              <EventCard key={index} cardData={cardData} pathname={'/event'} />
            ))}
          </div>
          <div className={styles.event__btn}>
            <a href="/event">イベント一覧へ</a>
          </div>
        </div>
      </section>
      <div className={styles.article}>
        <div className={styles.article__inner}>
          <section className={styles.article_experience}>
            <div className={styles.article_experience__head}>
              <img className={styles.article_experience__img} src="/assets/img/top/ico_experience01.svg" alt="Experience" />
              <div>
                <h2 className={styles.article_experience__title}>イベント体験記事</h2>
                <p className={styles.article_experience__title_en}>Event experience articles</p>
              </div>
              {/* <a className={styles.article_experience__link}>入稿済イベント体験記事一覧を見る</a> */}
            </div>
            <div className={styles.article_experience__list}>
              {mockExperienceData.slice(0, 6).map((data, index) => (
                <ArticleCard key={index} cardData={data} pathname={'/experience'} />
              ))}
            </div>
            <div className={styles.article_experience__btn}>
              <a href="/event">イベント体験記事一覧へ</a>
            </div>
          </section>
          <section className={styles.article_attractive}>
            <div className={styles.article_attractive__head}>
              <div className={styles.article_attractive__box}>
                <img className={styles.article_attractive__img} src="/assets/img/top/ico_attractive01.svg" alt="Attractive" />
                <div>
                  <h2 className={styles.article_attractive__title}>魅力発見記事</h2>
                  <p className={styles.article_attractive__title_en}>Attractive discovery articles</p>
                </div>
              </div>
              {/* <a className={styles.article_attractive__link}>入稿済魅力発見記事一覧を見る</a> */}
            </div>
            <div className={styles.article_attractive__list}>
              {mockAttractiveData.slice(0, 5).map((data, index) => (
                <div className={styles.article_attractive_item} key={index}>
                  <img className={styles.article_attractive_item__thumbnail} src={data.thumbnail} alt="Thumbnail" />
                  <div className={styles.article_attractive_item__body}>
                    <p className={styles.article_attractive_item__text}>{data.text}</p>
                    <p className={styles.article_attractive_item__date}>{data.date}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.article_attractive__btn}>
              <a href="/attractive">魅力発見記事一覧を見る</a>
            </div>
          </section>
        </div>
      </div>
      <section className={styles.community}>
        <div className={styles.community__inner}>
          <div className={styles.community__head}>
            <a href="#" className={styles.community__item}>
              <p className={styles.community__lead}>
                バナータイトルが入ります
                <br />
                バナータイトルが入ります
              </p>
              <img className={styles.community__img} src="/assets/img/top/pic_community01.jpg" alt="Community" />
            </a>
            <a href="#" className={styles.community__item}>
              <p className={styles.community__lead}>
                バナータイトルが入ります
                <br />
                バナータイトルが入ります
              </p>
              <img className={styles.community__img} src="/assets/img/top/pic_community02.jpg" alt="Community" />
            </a>
            <a href="#" className={styles.community__item}>
              <p className={styles.community__lead}>
                バナータイトルが入ります
                <br />
                バナータイトルが入ります
              </p>
              <img className={styles.community__img} src="/assets/img/top/pic_community03.jpg" alt="Community" />
            </a>
            <a href="#" className={styles.community__item}>
              <p className={styles.community__lead}>
                バナータイトルが入ります
                <br />
                バナータイトルが入ります
              </p>
              <img className={styles.community__img} src="/assets/img/top/pic_community04.jpg" alt="Community" />
            </a>
          </div>
          <h2 className={styles.community__title}>Local Bridgeコミュニティ</h2>
          <p className={styles.community__title_en}>Local Bridge Community</p>
          <div className={styles.community__btn}>
            <a href="#">コミュニティサイトへ行く！</a>
            <img className={styles.community__catch} src="/assets/img/top/pic_community05.png" alt="Community" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
