import React from 'react';
import styles from './complete.module.scss';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';

const ApplicationComplete = () => {
  return (
    <>
      <SubpageMainvisual title="イベント申し込み" enTitle="Application" />
      <SubpageContents bgColor={'#fdfaf1'}>
        <section className={styles.complete}>
          <div className={styles.complete__inner}>
            <h2 className={styles.complete__title}>
              イベントへのお申し込みが
              <br className={styles['is-sp']} />
              完了しました
            </h2>
            <p className={styles.complete__text}>
              お申し込みいただきありがとうございます。
              <br />
              確認メールをお送りしましたので、予約内容をご確認ください。
              <br />
              お申し込み内容を変更する場合は、一度キャンセルの上再申し込みをするか、お問い合わせ窓口までご連絡ください。
            </p>
            <a href="/" className={styles.complete__cancel}>
              イベントのキャンセルはこちら
            </a>
            <div className={styles.complete__back}>
              <a href="/">TOPページに戻る</a>
            </div>
          </div>
        </section>
      </SubpageContents>
    </>
  );
};

export default ApplicationComplete;
