import React from 'react';
import PropTypes from 'prop-types'; // PropTypesをインポート
import styles from './list.module.scss';
import { Link } from 'react-router-dom';

const AdminItem = (
  { mockListdata, index, page, garbage } // AdminItemを別の関数コンポーネントとして外出し
) => (
  <li className={styles.admin_item} key={mockListdata.id}>
    <Link to={`${page}/${mockListdata.id}`}>
      <p className={styles.admin_item__index}>{index + 1}</p>
      {Object.entries(mockListdata).map(([key, value]) => {
        if (key !== 'id') {
          return <p className={styles.admin_item__text}>{value}</p>;
        }
        return null;
      })}
    </Link>
    {garbage && <i class="bi bi-trash"></i>}
  </li>
);

const AdminList = ({ mockListdata, listHeader, startIndex, endIndex, page, garbage }) => {
  const displayedItems = mockListdata.slice(startIndex, endIndex);
  return (
    <div className={styles.admin_list}>
      <ul className={styles.admin_list__inner}>
        <div className={`${styles.admin_item} ${styles['admin_item--head']}`}>
          <p className={styles.admin_item__index}>#</p>
          {listHeader.map((header) => (
            <p className={styles.admin_item__text}>{header}</p>
          ))}
        </div>
        {displayedItems.map((org, index) => (
          <AdminItem key={org.id} mockListdata={org} page={page} index={startIndex + index} garbage={garbage} />
        ))}
      </ul>
    </div>
  );
};

AdminItem.propTypes = {
  // PropTypesで型チェック
  mockListdata: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired,
};

AdminList.propTypes = {
  mockListdata: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AdminList;
