import React from 'react';
import styles from './subpage_mainvisual.module.scss';

const SubpageMainvisual = ({ title, enTitle }) => {
  return (
    <div className={styles.subpage_mainvisual}>
      <h1 className={styles.subpage_mainvisual__title}>{title}</h1>
      <p className={styles.subpage_mainvisual__en_title}>{enTitle}</p>
    </div>
  );
};

export default SubpageMainvisual;
