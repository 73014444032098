import React from 'react';
import styles from './pagination.module.scss';

const Pagination = ({ style }) => {
  return (
    <div className={styles.pagination} style={style}>
      <div className={styles.pagination__prev}>前へ</div>
      <div className={styles.pagination__numbers}>
        <span className={styles.pagination__number}>1</span>
        <span className={styles.pagination__number}>2</span>
        <span className={`${styles.pagination__number} ${styles['is-current']}`}>3</span>
        <span className={styles.pagination__number}>4</span>
        <span className={styles.pagination__number}>5</span>
        <span className={styles.pagination__number}>6</span>
        <span className={styles.pagination__number}>7</span>
        <span className={styles.pagination__number}>...</span>
      </div>
      <div className={styles.pagination__next}>次へ</div>
    </div>
  );
};

export default Pagination;
