import React, { useEffect } from 'react';
import AppContext from '../../context/AppContext';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';
import styles from './contribute.module.scss';

const AboutContribute = () => {
  const { isLogin, setIsLogin } = React.useContext(AppContext); // 未使用の変数を削除
  useEffect(() => {
    setIsLogin(true);
  }, []);

  const AboutNav = () => {
    return (
      <ul className={styles.nav}>
        <li className={styles.nav__item}>
          <a href="/about/first">はじめての方へ</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/passport">ふるさとパスポートとは？</a>
        </li>
        <li className={`${styles.nav__item} ${styles['active']}`}>
          <a href="/about/contribute">貢献度バッジとは？</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/article">イベント体験記事・魅力発見記事について</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/community">コミュニティについて</a>
        </li>
      </ul>
    );
  };
  return (
    <>
      <SubpageMainvisual title="貢献度バッジとは？" enTitle="About Contribution Badges" />
      <SubpageContents bgColor={'#fdfaf1'}>
        <AboutNav />
        <div className={styles.contribute}>
          <div className={styles.contribute__inner}>
            <p className={styles.contribute__text}>
              貢献度バッジは、あなたがどんな分野の活動に参加したかを表す特別なデジタルバッジです。１つのふるさとパスポートに「地方創生」の活動分野を表す8種類の貢献度バッジが紐づいており、イベント参加や記事執筆によって取得できます。この貢献バッジを一定枚数以上集めると、それに応じてふるさとパスポートが進化していきます。
              <br />
              進化のカタチはランクとして表現され、ランクアップにより、より難易度の高いイベント・ワークショップへの参加の招待や、あなたの力を必要とする人からオファーを受けられたりします。
            </p>
            <section className={styles.contribute_content}>
              <div className={styles.contribute_content__texts}>
                <h2 className={styles.contribute_content__title}>貢献度バッジの種類</h2>
                <p className={styles.contribute_content__text}>貢献度バッジは、右の図の通り、デジタル田園都市国家構想(※)の4つの課題から、さらに2つに分類した８種類のカテゴリがあります。</p>
                <p className={styles.contribute_content__text}>
                  イベントに参加すると、各イベントの開催目的に対応した貢献度バッジが手に入れられます。自分がどの貢献度バッジを何枚手に入れているかはマイページで確認することができるだけでなく、Local Bridgeのユーザーや「ふるさとパスポート」を発行する自治体の方も取得情報を見ることができます。
                  <br />
                  獲得している分野、興味・関心が近しい人と交流したり、あるいは自身の活動に不足している分野の貢献バッジを持っている人とタッグを組むことでさらに発展的な活動をすることもできます。
                </p>
                <p className={styles.contribute_content__note}>
                  ※内閣官房が発表した「デジタル実装を通じて地方が抱える課題を解決し、誰一人取り残されずすべての人がデジタル化のメリットを享受できる心豊かな暮らしを実現する」という構想のこと。
                  <br />
                  URL：https://www.cas.go.jp/jp/seisaku/digitaldenen/about/social-issues.html
                </p>
              </div>
              <div className={styles.contribute_content__img}>
                <img src="/assets/img/about/contribute/pic_img01.jpg" />
              </div>
            </section>
            <section className={`${styles.contribute_content} ${styles['contribute_content--reverse']}`}>
              <div className={styles.contribute_content__texts}>
                <h2 className={styles.contribute_content__title}>獲得枚数とふるさとパスポートのランク</h2>
                <p className={styles.contribute_content__text}>
                  貢献度バッジを何枚獲得するとふるさとパスポートがランクアップするかを、左の図にまとめました。
                  <br />
                  その地域のふるさとパスポートを進化させたい場合は、その地域の貢献度バッジを左の図の枚数だけ集めてください。獲得した貢献度バッジを他のふるさとパスポートの進化に使うことはできません。
                  <br />
                  ふるさとパスポートの進化に関しては、貢献度バッジの種類は関係なく、その地域の貢献度バッジを何枚持っているかで決まります。もしあなたが今貢献度バッジを1枚しか持っていない地域があれば、新たな活動に参加して、ふるさとパスポートのランクをブロンズに進化させてみましょう。
                </p>
              </div>
              <div className={styles.contribute_content__img}>
                <img src="/assets/img/about/contribute/pic_img02.jpg" />
              </div>
            </section>
            <div className={styles.contribute__btn}>{isLogin ? <a href="/student/mypage/profile/public">貢献度バッジの獲得状況を見る</a> : <a href="/student/login">貢献度バッジの獲得状況を見る</a>}</div>
          </div>
        </div>
      </SubpageContents>
    </>
  );
};

export default AboutContribute;
