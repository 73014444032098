import React from 'react';
import styles from './pagination.module.scss'; // 適切なCSSモジュールをインポート

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className={styles.pagination_block}>
      {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
        <span key={page} className={`${styles.pagination_block__pagination} ${currentPage === page ? styles.active : ''}`} onClick={() => onPageChange(page)}>
          {page}
        </span>
      ))}
    </div>
  );
};

export default Pagination;
