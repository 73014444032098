import React from 'react';
import styles from './password_reset.module.scss';
import SubpageContents from '../../../components/layout/subpage_contents';

const StudentPasswordReset = () => {
  return (
    <>
      <SubpageContents bgColor={'#fdfaf1'}>
        <section className={styles.pass_reset}>
          <h1 className={styles.pass_reset__title}>パスワード再設定</h1>
          <p className={styles.pass_reset__title_en}>Password reset</p>
          <p className={styles.pass_reset__lead}>新しいパスワードを設定します</p>
          <form className={styles.pass_reset_contents}>
            <div className={styles.pass_reset_contents__item}>
              <p className={styles.pass_reset_contents__label}>新しいパスワード入力してください</p>
              <input className={styles.pass_reset_contents__input} type="test" name="password" id="password" />
              <p className={styles.pass_reset_contents__note}>※8〜16文字で、半角数字、半角大文字の英字、半角小文字の英字をすべて含めてください。</p>
            </div>
            <div className={styles.pass_reset_contents__item}>
              <p className={styles.pass_reset_contents__label}>パスワードを再入力（確認）</p>
              <input className={styles.pass_reset_contents__input} type="test" name="password" id="password" />
            </div>
            <div className={styles.pass_reset_contents__back}>
              <input type="submit" value="パスワードを再設定する" />
            </div>
          </form>
        </section>
      </SubpageContents>
    </>
  );
};

export default StudentPasswordReset;
