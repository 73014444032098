import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './detail.module.scss';
import SubpageContents from '../../components/layout/subpage_contents';

const ExperienceDetail = () => {
  const mockCardData = [
    {
      id: 1,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '01記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 2,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '02記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 3,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '03記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 4,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '04記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 5,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '05記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 6,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '06記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 7,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '07記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 8,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '08記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
    {
      id: 9,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15',
      author: 'T.S',
      label: '組織・団体',
      text: '09記事のダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      reactions: [
        {
          icon: '/assets/img/card/ico_good.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_smile.svg',
          count: 33,
        },
        {
          icon: '/assets/img/card/ico_read.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_walk.svg',
          count: 12,
        },
        {
          icon: '/assets/img/card/ico_check.svg',
          count: 8,
        },
      ],
      tag: 'タグタグ',
    },
  ];
  const { id } = useParams();
  const article = mockCardData.find((item) => item.id === parseInt(id));
  // 通常はこのIDを使ってAPIからデータを取得します
  return (
    <div>
      <SubpageContents bgColor="#ffecda">
        <div className={styles.attractive_detail}>
          <div className={styles.attractive_main}>
            <div className={styles.attractive_main__inner}>
              <p className={styles.attractive_main__label}>イベント体験記事</p>
              <h1 className={styles.attractive_main__title}>{article.text}</h1>
              <div className={styles.attractive_main__info}>
                <p className={styles.attractive_main__date}>{article.date}</p>
                <img src="/assets/img/attractive/ico_info.jpg" className={styles.attractive_main__ico} />
                <p className={styles.attractive_main__city}>ODK県ODK市</p>
              </div>
              <img src={article.thumbnail} className={styles.attractive_main__thumbnail} />
              <p className={styles.attractive_main__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
              <div className={styles.table_of_contents}>
                <p className={styles.table_of_contents__title}>目次</p>
                <ul className={styles.table_of_contents__list}>
                  <li className={styles.table_of_contents__item}>・この文章はダミーです文字の大きさや書体のサンプルです</li>
                  <li className={styles.table_of_contents__item}>・この文章はダミーです文字の大きさや書体のサンプルです</li>
                  <li className={styles.table_of_contents__item}>・この文章はダミーです文字の大きさや書体のサンプルです</li>
                  <li className={styles.table_of_contents__item}>・この文章はダミーです文字の大きさや書体のサンプルです</li>
                  <li className={styles.table_of_contents__item}>・この文章はダミーです文字の大きさや書体のサンプルです</li>
                </ul>
              </div>
              <section>
                <h2 className={styles.attractive_main__subtitle}>【プログラム①】地域おこしに関する講座を受講</h2>
                <img src={article.thumbnail} className={styles.attractive_main__thumbnail} />
                <p className={styles.attractive_main__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                <p className={styles.attractive_main__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
              </section>
              <section>
                <h2 className={styles.attractive_main__subtitle}>【プログラム①】地域おこしに関する講座を受講</h2>
                <p className={styles.attractive_main__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                <p className={styles.attractive_main__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
              </section>
            </div>
          </div>
          <div className={styles.attractive_info}>
            <div className={styles.attractive_tag}>
              <p className={styles.attractive_tag__item}>
                <img src="/assets/img/common/ico_tag.svg" />
                地方創生活動記事
              </p>
              <p className={styles.attractive_tag__item}>
                <img src="/assets/img/common/ico_tag.svg" />
                PR
              </p>
              <p className={styles.attractive_tag__item}>
                <img src="/assets/img/common/ico_tag.svg" />
                北海道
              </p>
              <p className={styles.attractive_tag__item}>
                <img src="/assets/img/common/ico_tag.svg" />
                北海道
              </p>
              <p className={styles.attractive_tag__item}>
                <img src="/assets/img/common/ico_tag.svg" />
                北海道
              </p>
              <p className={styles.attractive_tag__item}>
                <img src="/assets/img/common/ico_tag.svg" />
                タグタグ
              </p>
              <p className={styles.attractive_tag__item}>
                <img src="/assets/img/common/ico_tag.svg" />
                タグ
              </p>
              <p className={styles.attractive_tag__item}>
                <img src="/assets/img/common/ico_tag.svg" />
                タグタグ
              </p>
            </div>
            <div className={styles.attractive_reactions}>
              {article.reactions.map((reaction, index) => {
                let text;
                switch (index) {
                  case 0:
                    text = 'いいね！';
                    break;
                  case 1:
                    text = '気になる';
                    break;
                  case 2:
                    text = '詳細を知りたい';
                    break;
                  case 3:
                    text = '行ってみたい';
                    break;
                  case 4:
                    text = '行ったことある';
                    break;
                  default:
                    text = 'その他';
                }
                return (
                  <div className={styles.attractive_reactions__item}>
                    <p key={index} className={styles.attractive_reactions__count}>
                      {reaction.count}
                    </p>
                    <img key={index} src={reaction.icon} className={styles.attractive_reactions__icon} />
                    <p className={styles.attractive_reactions__text}>{text}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <section className={styles.attractive_author}>
            <h2 className={styles.attractive_author__title}>この記事を書いた人</h2>
            <div className={styles.attractive_author_contents}>
              <div className={styles.attractive_author_contents__profile}>
                <img src="/assets/img/attractive/pic_icon.jpg" className={styles.attractive_author_contents__ico} />
                <a href="#" className={styles.attractive_author_contents__btn}>
                  マイページを見る
                </a>
              </div>
              <div className={styles.attractive_author_contents__texts}>
                <p className={styles.attractive_author_contents__name}>
                  ニックネーム<span>○◯組織・団体所属</span>
                </p>
                <p className={styles.attractive_author_contents__text}>この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。この文章はダミーです文字の大きさや書体のサンプルです。</p>
                <p className={styles.attractive_author_contents__passport}>所有しているふるさとパスポート：</p>
              </div>
            </div>
          </section>
          <section className={styles.attractive_related}>
            {/* <h2 className={styles.attractive_related__title}>
              <img src="/assets/img/attractive/ico_group.svg" />
              関連する組織・団体
            </h2>
            <div className={styles.attractive_related__list}>
              <div className={styles.attractive_related__item}></div>
              <div className={styles.attractive_related__item}></div>
              <div className={styles.attractive_related__item}></div>
              <div className={styles.attractive_related__item}></div>
              <div className={styles.attractive_related__item}></div>
            </div> */}
            <div className={styles.attractive_related__btn}>
              <a href="#">イベント体験記事一覧へ</a>
            </div>
          </section>
        </div>
      </SubpageContents>
    </div>
  );
};

export default ExperienceDetail;
