import React, { useState } from 'react';
import styles from './user.module.scss';
import AdminList from '../../../components/admin/list';
import Pagination from '../../../components/admin/pagination';

const User = () => {
  const page = window.location.href;
  const mockListdata = [
    {
      id: 1,
      name: '名ABCDE',
      date: '2023.11.12',
    },
    {
      id: 2,
      name: '名ABCDE',
      date: '2023.11.13',
    },
    {
      id: 3,
      name: '名ABCDE',
      date: '2023.11.14',
    },
    {
      id: 4,
      name: '名ABCDE',
      date: '2023.11.15',
    },
    {
      id: 5,
      name: '名ABCDE',
      date: '2023.11.12',
    },
    {
      id: 6,
      name: '名ABCDE',
      date: '2023.11.13',
    },
    {
      id: 7,
      name: '名ABCDE',
      date: '2023.11.14',
    },
    {
      id: 8,
      name: '名ABCDE',
      date: '2023.11.15',
    },
    {
      id: 9,
      name: '名ABCDE',
      date: '2023.11.12',
    },
    {
      id: 10,
      name: '名ABCDE',
      date: '2023.11.13',
    },
    {
      id: 11,
      name: '名ABCDE',
      date: '2023.11.14',
    },
    {
      id: 12,
      name: '名ABCDE',
      date: '2023.11.15',
    },
  ];
  const listHeader = ['ユーザー名', 'Date'];

  const [currentPage, setCurrentPage] = useState(1);
  //表示件数を設定
  const itemsPerPage = 8;
  const totalPages = Math.ceil(mockListdata.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div className={styles.user_block}>
      <div className={styles.user_block__head}>
        <h1 className={styles.user_block__title}>ユーザー一覧</h1>
      </div>

      <div className={styles.user_block__list}>
        <AdminList page={page} mockListdata={mockListdata} listHeader={listHeader} startIndex={startIndex} endIndex={endIndex} />
      </div>
      <div className={styles.user_block__pagination}>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={(page) => setCurrentPage(page)} />
      </div>
    </div>
  );
};

export default User;
