import { React, useState } from 'react';
import styles from './event.module.scss';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';
import Search from '../../components/search';
import EventCard from '../../components/card/event_card';
import Pagination from '../../components/pagination';

const Event = () => {
  const mockCardData = [
    {
      id: 1,
      thumbnail: '/assets/img/card/pic_thumbnail01.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '01これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 2,
      thumbnail: '/assets/img/card/pic_thumbnail02.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '02これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 3,
      thumbnail: '/assets/img/card/pic_thumbnail03.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '03これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 4,
      thumbnail: '/assets/img/card/pic_thumbnail04.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '04これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 5,
      thumbnail: '/assets/img/card/pic_thumbnail05.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '05これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 6,
      thumbnail: '/assets/img/card/pic_thumbnail06.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '06これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 7,
      thumbnail: '/assets/img/card/pic_thumbnail07.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '07これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 8,
      thumbnail: '/assets/img/card/pic_thumbnail08.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '08これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
    {
      id: 9,
      thumbnail: '/assets/img/card/pic_thumbnail09.jpg',
      date: '2023.11.15～2023.11.15',
      label: '組織・団体',
      text: '09これはタイトルのダミーです。見出しのダミーテキストイメージです。この文章はダミーです。',
      capacity: '100',
      period: '2023.11.15～2023.11.15',
    },
  ];

  // 検索キーワードの状態変数
  const [searchKeyword, setSearchKeyword] = useState('');
  const [tempSearchKeyword, setTempSearchKeyword] = useState('');
  const [showResultCount, setShowResultCount] = useState(false);

  const filteredMockCardData = mockCardData.filter((data) => data.text.toLowerCase().includes(searchKeyword.toLowerCase()));

  const handleTempSearch = (keyword) => {
    setTempSearchKeyword(keyword);
  };

  const handleSearchSubmit = () => {
    setSearchKeyword(tempSearchKeyword);
    setShowResultCount(true);
  };

  return (
    <>
      <SubpageMainvisual title="イベント一覧" enTitle="Events" />
      <SubpageContents bgColor={'#dbf1db'}>
        <Search onTempSearch={handleTempSearch} onSearchSubmit={handleSearchSubmit} resultCount={filteredMockCardData.length} showResultCount={showResultCount} />
        <div className={styles.event_list}>
          {filteredMockCardData.map((cardData, index) => (
            <EventCard key={index} cardData={cardData} pathname={'/event'} />
          ))}
        </div>
        <div className={styles.event_list__pagination}>
          <Pagination />
        </div>
      </SubpageContents>
    </>
  );
};

export default Event;
