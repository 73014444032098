import React from 'react';
import SubpageMainvisual from '../../components/subpage_mainvisual';
import SubpageContents from '../../components/layout/subpage_contents';
import styles from './article.module.scss';

const AboutArticle = () => {
  const AboutNav = () => {
    return (
      <ul className={styles.nav}>
        <li className={styles.nav__item}>
          <a href="/about/first">はじめての方へ</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/passport">ふるさとパスポートとは？</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/contribute">貢献度バッジとは？</a>
        </li>
        <li className={`${styles.nav__item} ${styles['active']}`}>
          <a href="/about/article">イベント体験記事・魅力発見記事について</a>
        </li>
        <li className={styles.nav__item}>
          <a href="/about/community">コミュニティについて</a>
        </li>
      </ul>
    );
  };
  return (
    <>
      <SubpageMainvisual title="イベント体験記事・魅力発見記事について" enTitle="About Event experience articles / Attractive discovery articles" />
      <SubpageContents bgColor={'#fdfaf1'}>
        <AboutNav />
        <div className={styles.article}>
          <div className={styles.article__contents}>
            <p className={styles.article__text}>
              Local Bridgeでは、あなたが感じた地域の魅力や地域活性化の取り組みを対外的に発信し、それに賛同したり魅力を感じた仲間を増やすことを「地方創生」活動の一つと捉え、Local Bridge上でユーザー参加型の記事の発信を行い、その活動に対し貢献度バッジを発行します。
              <br />
              発信できる記事の種類は「イベント体験記事」「魅力発見記事」の2種類があり、イベント体験記事では、あなたが体験したイベント・ワークショップの内容や感想を発信し、魅力発見記事ではその地域の魅力的な場所や人物について情報を共有できます。イベントに参加し、ふるさとパスポートを持っているとライターになれます。
            </p>
            <section className={styles.article_content}>
              <div className={styles.article_content__texts}>
                <h2 className={styles.article_content__title}>イベント体験記事について</h2>
                <p className={styles.article_content__text}>
                  イベント体験記事は、あなたが参加したイベントについての内容や感想を発信する場所です。
                  <br />
                  例えば、あなたが古民家改修のワークショップに参加した場合、どんな状態からどのような改修作業をしたかだけでなく、参加したからこそわかる地域ならではの環境・気象情報や当日役に立ったアイテム、こんな知恵やスキルを持った方の参加があればもっとよりよい活動につながりそうといった情報をまとめることで、次の参加者の活動を助けることができます。記事は、Local Bridgeを通して参加したイベントについて執筆できます。イベント体験記事を執筆できるイベントはライター入稿ページから確認できます。
                </p>
              </div>
              <div className={styles.article_content__img}>
                <img src="/assets/img/about/article/pic_img01.jpg" alt="イベント体験記事について" />
              </div>
            </section>
            <section className={`${styles.article_content} ${styles['article_content--reverse']}`}>
              <div className={styles.article_content__texts}>
                <h2 className={styles.article_content__title}>魅力発見記事について</h2>
                <p className={styles.article_content__text}>
                  魅力発見記事では、あなたが持っているふるさとパスポートの地域について、その地域やその地域に関わる人の魅力を発信できます。
                  <br />
                  地域の観光地やおすすめの観光コースを紹介するだけでなく、あなたしか知らない穴場スポットや、街を訪れた際に出会った人などを紹介し、他の人にももっとその地域を好きになってもらいましょう。
                  <br />
                  魅力発見記事もライター入稿ページから作成できます。ライター入稿ページから記事を書きたい地域を選択し、執筆を始めてみましょう。
                </p>
              </div>
              <div className={styles.article_content__img}>
                <img src="/assets/img/about/article/pic_img02.jpg" alt="イベント体験記事について" />
              </div>
            </section>
            <section className={styles.article_content}>
              <div className={styles.article_content__texts}>
                <h2 className={styles.article_content__title}>記事からできること</h2>
                <p className={styles.article_content__text}>
                  各記事には「参加したい！」などのリアクションボタンを設置しています。ボタンはイベント体験記事は「いいね！」「気になる」「詳細を知りたい」「参加したい」「参加した」の5種類。魅力発見記事は「いいね！」「気になる」「詳細を知りたい」「行ってみたい」「行ったことある」の5種類です。ボタンが押されると執筆者のモチベーションになるだけでなく、その地域がイベントを開催する際の参考にもしていますので、共感した活動、自分も行ってみたい、やってみたいと感じた記事に対しては積極的にボタンを押していきましょう。
                  <br />
                  また、各記事には、「この記事を書いた人」としてニックネームや自己紹介文など、執筆者の情報が記載されています。
                  <br />
                  今後、記事を見たあなたが記事について質問したい、一緒に活動したいなどと感じた場合に活動できる、Local Bridge 事務局を通じたマッチング機能等の搭載も予定しています。
                </p>
              </div>
              <div className={styles.article_content__img}>
                <img src="/assets/img/about/article/pic_img03.jpg" alt="イベント体験記事について" />
              </div>
            </section>
          </div>
          <div className={styles.article_group}>
            <p className={styles.article_group__text}>まずはイベントに参加してみる！</p>
            <div className={styles.article_group__btn}>
              <a href="#">
                <img src="/assets/img/about/article/ico_schedule.svg" />
                イベント一覧へ
              </a>
            </div>
          </div>
        </div>
      </SubpageContents>
    </>
  );
};

export default AboutArticle;
