import React, { useState, useEffect } from 'react';
import styles from './public.module.scss';
import AppContext from '../../../../context/AppContext';
import SubpageMainvisual from '../../../../components/subpage_mainvisual';
import SubpageContents from '../../../../components/layout/subpage_contents';
import { useLocation } from 'react-router-dom';

const StudentPublicProfile = () => {
  const { setIsMypage, setIsLogin } = React.useContext(AppContext); // 未使用の変数を削除
  //検証用にパラメーターを取得
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const paramBoolean = query.get('alert');

  useEffect(() => {
    setIsMypage(true);
    setIsLogin(true);
  }, []);

  return (
    <>
      <SubpageMainvisual title="〇〇さんのプロフィール" enTitle="Profile" />
      <SubpageContents bgColor={'#fdfaf1'}>
        <div className={styles.profile}>
          <div className={styles.profile__inner}>
            <div className={styles.profile_info}>
              <img className={styles.profile_info__img} src="/assets/img/student/mypage/profile/pic_icon.jpg" />
              <div className={styles.profile_info__badge}>
                <p className={styles.profile_info__text}>貢献度バッジ保有数</p>
                <p className={styles.profile_info__num}>
                  <img src="/assets/img/student/mypage/profile/ico_badge.svg" />
                  30
                </p>
              </div>
            </div>
            <div className={styles.profile__contents}>
              <p className={styles.profile__name}>タロー</p>
              <p className={styles.profile__label}>高校2年生</p>
              <section className={styles.profile_section}>
                <h2 className={styles.profile_section__title}>自己紹介文</h2>
                <p className={styles.profile_section__text}>この文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーです</p>
              </section>
              <section className={styles.profile_section}>
                <h2 className={styles.profile_section__title}>興味・関心</h2>
                <p className={styles.profile_section__text}>この文章はダミーです</p>
              </section>
              <section className={styles.profile_section}>
                <h2 className={styles.profile_section__title}>私の気になる第2のふるさと</h2>
                <p className={styles.profile_section__text}>この文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーですこの文章はダミーです</p>
              </section>
              <section className={styles.profile_section}>
                <h2 className={styles.profile_section__title}>気になる地方創生カテゴリ</h2>
                <p className={styles.profile_section__text}>地域の応援になる</p>
              </section>
              <section className={styles.profile_section}>
                <h2 className={styles.profile_section__title}>保有パスポート一覧</h2>
                <div className={styles.profile_passport}>
                  <div className={styles.profile_passport__item}>
                    <img className={styles.profile_passport__img} src="/assets/img/student/mypage/profile/pic_passport02.jpg" />
                    <p className={styles.profile_passport__text}>パスポート名が入ります</p>
                  </div>
                  <div className={styles.profile_passport__item}>
                    <img className={styles.profile_passport__img} src="/assets/img/student/mypage/profile/pic_passport01.jpg" />
                    <p className={styles.profile_passport__text}>パスポート名が入ります</p>
                  </div>
                  <div className={styles.profile_passport__item}>
                    <img className={styles.profile_passport__img} src="/assets/img/student/mypage/profile/pic_passport02.jpg" />
                    <p className={styles.profile_passport__text}>パスポート名が入ります</p>
                  </div>
                  <div className={styles.profile_passport__item}>
                    <img className={styles.profile_passport__img} src="/assets/img/student/mypage/profile/pic_passport01.jpg" />
                    <p className={styles.profile_passport__text}>パスポート名が入ります</p>
                  </div>
                </div>
                <div className={styles.profile_section__btn}>
                  <a href="#">さらにみる</a>
                </div>
              </section>
              <section className={styles.profile_section}>
                <h2 className={styles.profile_section__title}>活動履歴</h2>
                <ul className={styles.profile_list}>
                  <li className={styles.profile_list__item}>
                    <a href="#">
                      <p className={styles.profile_list__date}>2023.11.1</p>
                      <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                  <li className={styles.profile_list__item}>
                    <a href="#">
                      <p className={styles.profile_list__date}>2023.11.1</p>
                      <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                  <li className={styles.profile_list__item}>
                    <a href="#">
                      <p className={styles.profile_list__date}>2023.11.1</p>
                      <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                  <li className={styles.profile_list__item}>
                    <a href="#">
                      <p className={styles.profile_list__date}>2023.11.1</p>
                      <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                </ul>

                <div className={styles.profile_section__btn}>
                  <a href="#">さらにみる</a>
                </div>
              </section>
              <section className={styles.profile_section}>
                <h2 className={styles.profile_section__title}>執筆記事一覧</h2>
                <ul className={styles.profile_list}>
                  <li className={styles.profile_list__item}>
                    <a href="#">
                      <p className={styles.profile_list__date}>2023.11.1</p>
                      <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                  <li className={styles.profile_list__item}>
                    <a href="#">
                      <p className={styles.profile_list__date}>2023.11.1</p>
                      <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                  <li className={styles.profile_list__item}>
                    <a href="#">
                      <p className={styles.profile_list__date}>2023.11.1</p>
                      <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                  <li className={styles.profile_list__item}>
                    <a href="#">
                      <p className={styles.profile_list__date}>2023.11.1</p>
                      <p className={styles.profile_list__text}>この文章はダミーですこのこの文章はダミーです文章はダミーですこの文章はダミーですこの文章はダミーです</p>
                    </a>
                  </li>
                </ul>

                <div className={styles.profile_section__btn}>
                  <a href="#">さらにみる</a>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className={styles.profile__btn}>
          <a href="#">TOPページに戻る</a>
        </div>
      </SubpageContents>
    </>
  );
};

export default StudentPublicProfile;
